import React, {useState} from "react";
import {
    IDSButtonGroup,
    IDSCard,
    IDSCol,
    IDSContainer,
    IDSInput,
    IDSLink,
    IDSRadio,
    IDSRadioGroup,
    IDSRow,
    IDSSelect
} from "@inera/ids-react";
import {grayBorder} from "../../../styles/TTStyles";
import {codesystem, conceptmap, valueset} from "../../../model/defaultvalues/Constant";
import {publicationStatusOptions} from "./SearchFilterCommons";
import useCollapse from "react-collapsed";
import {sectorOptions} from "../../../services/SectorUtil";


let renderCount = 0;


export default function SearchArtifactChoiceAndFilter(props: any) {
    const [isExpanded, setExpanded] = useState(false);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded})


    const clearFilter = () => {
        props.clearFilters();
    };


    const getEnabledArtifactChoice = (artifactType: string) => {
        if (props.artifactTypeChoice !== undefined) {

            if (props.artifactTypeChoice.includes(artifactType) && artifactType == codesystem) {

                return (
                    <IDSCol cols="2">
                        <IDSRadio>
                            <input type="radio" value={codesystem} defaultChecked
                                   onClick={props.onChangeArtifactType} onChange={props.onChangeArtifactType}></input>
                            <label>Kodverk</label>
                        </IDSRadio>
                    </IDSCol>
                );
            } else if (props.artifactTypeChoice.includes(artifactType) && artifactType == valueset) {

                return (
                    <IDSCol cols="2">
                        <IDSRadio>
                            <input type="radio" value={valueset}
                                   onChange={props.onChangeArtifactType} onClick={props.onChangeArtifactType}></input>
                            <label>Urval</label>
                        </IDSRadio>
                    </IDSCol>
                );
            }

            else if (props.artifactTypeChoice.includes(artifactType) && artifactType == conceptmap) {

                return (
                    <IDSCol cols="2">
                       {/* TODO activate radio button conceptMap for conceptMap when in realseplan */}
    {/*                    <IDSRadio>
                            <input type="radio" value={conceptmap}
                                   onChange={props.onChangeArtifactType} onClick={props.onChangeArtifactType}></input>
                            <label>Mappningsspecifikation</label>
                        </IDSRadio>*/}
                    </IDSCol>
                );
            }
        }
    };

    const displayFilterLink = () => {

        if (!props.hideFilter) {
            return (
                <IDSLink underlined {...getToggleProps({
                    style: {display: "block"},
                    onClick: () => setExpanded((x) => !x)
                })}>
                    {isExpanded ? <a>GÖM FILTER</a> : <a>VISA FILTER</a>}
                </IDSLink>

            );
        }
    };


    renderCount++;

    return (

        <IDSRow>
            <IDSCol>
                {/*<div className={'container'}>*/}
                <IDSCard style={grayBorder}>

                    <IDSContainer gutterless={true}>
                        <IDSRow>
                            <IDSCol cols="9">
                                <IDSRadioGroup name="artifactType" required={true}>
                                    <IDSContainer gutterless={true}>
                                        <IDSRow>
                                            {getEnabledArtifactChoice(codesystem)}
                                            {getEnabledArtifactChoice(valueset)}
                                            {getEnabledArtifactChoice(conceptmap)}
                                        </IDSRow>
                                    </IDSContainer>
                                </IDSRadioGroup>

                            </IDSCol>
                            <IDSCol cols="3">
                                <IDSButtonGroup>
                                    {displayFilterLink()}

                                    <IDSLink underlined>
                                        <a href="#" onClick={() => {
                                            clearFilter();
                                        }}>RENSA FILTER </a>
                                    </IDSLink>
                                </IDSButtonGroup>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                    <div {...getCollapseProps({})}>
                        <IDSContainer gutterless={true}>
                            <IDSRow>
                                <IDSCol cols="6">
                                    <div className={'container'}>
                                        <IDSSelect>
                                            <label>Sektor</label>
                                            <select onChange={props.onChangeSector} value={props.sector}>
                                                <option value="" disabled={false} selected={true}></option>
                                                {sectorOptions.map((item) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </IDSSelect>
                                    </div>
                                </IDSCol>
                                <IDSCol cols="6">
                                    <div className={'container'}>
                                        <IDSInput> <label>Förvaltare</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={props.publisher}
                                                onChange={props.onChangePublisher}
                                            />
                                        </IDSInput>
                                    </div>
                                </IDSCol>
                            </IDSRow>
                            <IDSRow>
                                <IDSCol cols="4">
                                    <div className={'container'}>
                                        <IDSInput> <label>Version</label> <input
                                            type="text"
                                            className="form-control"
                                            value={props.version}
                                            onChange={props.onChangeVersion}
                                        /></IDSInput>
                                    </div>
                                </IDSCol>
                                <IDSCol cols="4">
                                    <div className={'container'}>
                                        <IDSSelect>
                                            <label>Versionsstatus</label>
                                            <select onChange={props.onChangeStatus} value={props.status}>
                                                <option value="" disabled={false} selected={true}></option>
                                                {publicationStatusOptions.map((item) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </IDSSelect>
                                    </div>
                                </IDSCol>
                                <IDSCol cols="4">
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </div>
                </IDSCard>
                {/*   </div>*/}
            </IDSCol>
        </IDSRow>

    );
}
