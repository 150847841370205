import React from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {permissionToSeeSaveButton} from "../../../../user/PermissionUtil";
import Spinner from "react-bootstrap/Spinner";
import {IDSSpinner} from "@inera/ids-react";

const SaveButton = (props) => {
    return (
        <div>
            <br/><br/>
            {permissionToSeeSaveButton() &&
                <button type="submit" color="primary">
                    Spara
                </button>
            }
            <br/>
            {props.isRequesting &&
                <Spinner animation="border" role="status">
                    <IDSSpinner live="polite" srtext="Sparar..."/>
                </Spinner>
            }
            <br/><br/>
        </div>
    );
};
export default SaveButton;
