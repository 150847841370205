import {collectValidationMessage} from "../../../../common/validation/MetaValidationRules";
import {metaDataTabName, publishTabName} from "../../../../common/page/TabName";
import {
    emptyPublisher, emptyVersion,
} from "../../../meta/MetaWarnInfoConstant";


export const validatePublisherForPublish = (methods, handleWarnMessageList) => {
   return validateFormField("publisher", methods, handleWarnMessageList,
       "förvaltare", emptyPublisher, metaDataTabName)
}

export const validateVersionForPublish = (methods, handleWarnMessageList) => {
    return validateFormField("version", methods, handleWarnMessageList,
        "version", emptyVersion, publishTabName)
}


const validateFormField = (fieldName, methods, handleWarnMessageList, swedishFieldName , errorCode, tabName) => {

    var hasEmptyVersionDescription = false;
    let messageEmptyPublisher = "Fyll i " + swedishFieldName + " för att kunna aktivera";
    const publisher = methods.getValues(fieldName);
    hasEmptyVersionDescription = publisher === undefined || publisher.trim() === "";

    if (hasEmptyVersionDescription) {
        methods.setError(fieldName, {
            type: "WARNING",
            // @ts-ignore
            code: errorCode,
            message: messageEmptyPublisher,
        });
        collectValidationMessage(messageEmptyPublisher, fieldName + errorCode, true, handleWarnMessageList, tabName);
    } else {
        collectValidationMessage(messageEmptyPublisher, fieldName + errorCode, false, handleWarnMessageList, tabName);
    }
    return hasEmptyVersionDescription;
}