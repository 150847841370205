import React from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {
    IDSFooter, IDSLink, IDSIconExternal, IDSMobileMenu, IDSMobileMenuItem
} from "@inera/ids-react";

export const Footer = () => {
    return (
        <IDSFooter servicename="[Terminologitjänsten]" type="inera-admin">

            <p style={{
                position: 'relative'
            }} slot="sub-footer-right">
                <IDSLink underlined={true} color={"white"}>
                    <a href="https://inera.atlassian.net/wiki/x/jYB82">Tillgänglighetsredogörelse</a>
                </IDSLink>
            </p>
            <p style={{
                position: 'relative'
            }} slot="sub-footer-left">Terminologitjänsten drivs av&nbsp;
                <IDSLink underlined={true} color={"white"}>
                    <a href="https://www.inera.se" target="_blank">Inera AB</a>
                </IDSLink>&nbsp;
                <IDSIconExternal style={{
                    position: 'relative',
                    bottom: '-2px'
                }} width="14" height="14" color="white" inline={true}></IDSIconExternal>
            </p>

            {/* mobile*/}
            <p style={{
                position: 'relative'
            }} slot="sub-footer-mobile">Terminologitjänsten drivs av <a style={{
                color: 'white',
                outline: 'white'
            }} href="https://www.inera.se" target="_blank">Inera AB</a> &nbsp;
                <IDSIconExternal style={{
                    position: 'relative',
                    bottom: '-2px'
                }} width="14" height="14" color="white" inline={true}></IDSIconExternal>
            </p>
            <IDSLink slot="sub-footer-links" color="white">
                <a href="https://inera.atlassian.net/wiki/x/jYB82" alt="Tillgänglighetsredogörelse">Tillgänglighetsredogörelse</a>
            </IDSLink>
        </IDSFooter>
    );
};
