import React, {useState} from 'react';

import GroupBase from "./GroupBase";
import {IConceptMapGroupElement} from "fhir-typescript-models/dist/src/models/fhir/interfaces/IConceptMapGroupElement";


let renderCount = 0;


const GroupCreate = (props: any) => {

    const [targetTableData, setTargetTableData] = useState<IConceptMapGroupElement[]>( []);
    renderCount++;

    return (
        <GroupBase
            initUpdate={props.initUpdate}
            artifactArray={props.artifactArray}
            addConcept={props.addConcept}
            removeConceptToValueSet={props.removeConceptToValueSet}
            removeCodeSystemFromValueSet={props.removeCodeSystemFromValueSet}
            sourceTableData={props.sourceTableData}
            setSourceTableData={props.setSourceTableData}
            setSourceUrl={props.setSourceUrl}
            setTargetUrl={props.setTargetUrl}
            targetTableData={targetTableData}
            setTargetTableData={setTargetTableData}
            sourceTableInstanceRef={props.sourceTableInstanceRef}
            methods={props.methods}
        />
    );
};

export default GroupCreate;
