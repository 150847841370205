import React, {useEffect, useMemo, useRef, useState} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef,
    type MRT_RowSelectionState,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {addOrRemoveAllCodeSystemConceptsToValueSet} from "./update/VSCrudFunctions";


let renderCount = 0;

const VSSelectableCodeSystemTable = (props: any) => {

    renderCount++;


    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    useEffect(() => {

       if (props.conceptsToAdd.length === 0) {
            setRowSelection({});
        } else if (props.data !== undefined) {
            for (let i = 0; i < props.data.length; i++) {

                if ((props.data[i].selected === undefined || props.data[i].selected === false) &&
                    rowSelection[i] !== undefined) {
                    delete rowSelection[i];

                } else if (props.data[i].selected === true && rowSelection[i.toString()] === undefined) {
                    rowSelection[i] = true;
                }
            }
            setRowSelection({...rowSelection});
        }

    }, [props.data, props.conceptsToAdd]);

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
        {
            accessorKey: 'definition',
            header: 'Beskrivning',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };


    return (
        <div>
            <h6 className="ids-heading-6">Koder i kodverket</h6>
            <div>
                <MaterialReactTable
                    {...commonTableSourceProps}
                    autoResetPageIndex={false}
                    columns={columns}
                    data={(props.data === undefined || props.data.length === 0) ? [] : props.data}
                    enableRowOrdering={false}
                    enableColumnFilters={true}
                    enableSorting={true}
                    localization={MRT_Localization_SV}
                    enableTopToolbar={false}
                    enableEditing={true}
                    enableHiding={false}
                    editingMode="table"
                    positionActionsColumn="last"
                    enableExpanding
                    enableExpandAll //default
                    tableInstanceRef={tableInstanceRef}
                    initialState={{pagination: {pageSize: 5, pageIndex: 0}}}
                    enableSelectAll={true}
                    enableRowSelection
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}


         /*           muiSelectCheckboxProps={({row, table}) => ({
                        defaultChecked: true,
                        onChange: (event) => {
                            // @ts-ignore
                            props.removeConceptToValueSet(row.original, row.original.system, row.original.version);
                        },
                        // color: 'secondary',
                    })}*/

                    muiSelectCheckboxProps={({row, table}) => ({
                        // @ts-ignore
                        defaultChecked: row.original.selected,
                        onClick: (event) => {
                            props.handleChange(event, row.original, props.codesystem.url, props.codesystem.version, row.index, props.tabKey, props.codesystem.id);
                        },
                        // color: 'secondary',
                    })}

                    muiSelectAllCheckboxProps={({table}) => ({
                        onClick: (event) => {
                            // @ts-ignore
                            addOrRemoveAllCodeSystemConceptsToValueSet(props.data, props.codesystem.url, props.codesystem.version, props.tabKey, event.target.checked, props.conceptsToAdd, props.setConceptsToAdd, props.codesystem.id)
                        },
                        // color: 'secondary',
                    })}


                    muiTableHeadRowProps={{
                        sx: muiTableHeadRowPropsStyle
                    }}

                    muiTableProps={{
                        sx: muiTablePropsFiveColumnStyle
                    }}

                    muiTableBodyProps={{
                        sx: muiTableBodyPropsStyle
                    }}


                    muiTablePaperProps={{
                        elevation: 0, //change the mui box shadow
                    }}

                    icons={{
                        MoreVertIcon: () => <ImportExport/>,
                        MoreHorizIcon: () => <MoreVertIcon/>,
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            size: 10,
                        },
                        'mrt-row-drag': {
                            header: '',
                            size: 10,
                        },
                    }}

                    getSubRows={(originalRow) => {
                        return (
                            originalRow.concept
                        )
                    }}

                    muiTableHeadCellColumnActionsButtonProps={{
                        children: <ExpandMore/>
                    }}

                />
            </div>
        </div>
    );
};

export default VSSelectableCodeSystemTable;
