import React from "react";

import {handleIfValidationNotifications} from "../components/common/validation/external/ValidationExternalNotificationService";


export const updateResponseErrorHandling = (error, setIsRequesting, methods) => {
    setIsRequesting(false);
    if (error.response !== undefined && error.response.status == 400) {
        handleIfValidationNotifications("ERROR", error.response.data, methods);
    }else {
        console.error("FhirClient Create Error Response" + JSON.stringify(error));
    }
};