import React, {FC, useCallback, useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {errorStyle, statusActiveStyle, statusDraftStyle, textFieldStyle} from "../../../styles/TTStyles";
import {
    getFieldNameInArrayHierarchy, getNameInArrayHierarchyAsDotsOnly,
    hasRowCellsAllEmptyValues
} from "./RHookFormArrayHierarchyFunction";
import {IDSErrorMessage} from "@inera/ids-react";
import {remountConceptArrayWithNewValue} from "./RHookTextFieldForTableUtil";
import TextareaAutosize from 'react-textarea-autosize';
import {removeEmojiFromString} from "../CommonFunctions";

interface RHookFormTextAreaForTableControllerProps {
    arrayname: string;
    fieldname: string;
    index: string;
    methods: UseFormReturn;
    editDisabled: boolean,
    warn?: any,
    info?: any,
    error?: any,
    label?: string,
    arrayUpdateMethod?: any,
    arrayAppendMethod?: any,
    handleErrorMessageList?: any
}

const RHookFormTextAreaForTableController: FC<RHookFormTextAreaForTableControllerProps> = ({
                                                                                               arrayname,
                                                                                               fieldname,
                                                                                               index,
                                                                                               methods,
                                                                                               editDisabled,
                                                                                               warn,
                                                                                               info,
                                                                                               error,
                                                                                               label,
                                                                                               arrayUpdateMethod,
                                                                                               arrayAppendMethod,
                                                                                               handleErrorMessageList
                                                                                           }: RHookFormTextAreaForTableControllerProps) => {

    const [isTouched, setIsTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [warnMessage, setWarnMessage] = useState(undefined);
    const [infoMessage, setInfoMessage] = useState(undefined);

    const name = getFieldNameInArrayHierarchy(index, arrayname, fieldname);
    const nameInArrayHierarchyAsDots = getNameInArrayHierarchyAsDotsOnly(name);
    const rowInArrayHierarchy = name.substring(0, name.lastIndexOf("."));
    const columnName = name.substring(name.lastIndexOf(".") + 1);
    const [previousYupValidation, setPreviousYupValidation] = useState<string>();

    const getHierarchyForConcept = useCallback(async () => {
    }, []);


    useEffect(() => {
        if (!editDisabled) {
            handleChange02(methods.getValues(name))
        }
    }, [methods.getValues(name), arrayUpdateMethod]);


    const {onChange, onBlur, ref} = methods.register(name);

    // @ts-ignore
    const handleChange = event => {
        if (!hasRowCellsAllEmptyValues(name, methods)) {
            //remove white space
            setIsTouched(true);
            handleChange02(event.target.value);
        } else {
            let validationErrorResult = undefined;
            if (error !== undefined) {
                validationErrorResult = error(event.target.value, index, true);
                setErrorMessage(undefined)
            }
            let validationWarnResult = undefined;
            if ((validationErrorResult === undefined || validationErrorResult == false) && warn !== undefined) {
                validationWarnResult = warn(event.target.value, index, true);
                setWarnMessage(undefined)
            }
            if ((validationErrorResult === undefined || validationErrorResult == false) &&
                (validationWarnResult === undefined || validationWarnResult == false) && info !== undefined) {
                let validationInfoResult = info(event.target.value, index, true);
                setInfoMessage(undefined)
            }
        }
    };

    const handleChange02 = (value: string) => {
        let valueWithoutEmojis = removeEmojiFromString(value);
        methods.setValue(name, valueWithoutEmojis)
        remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod)
        let hasErrors = false;
        if (error !== undefined) {
            hasErrors = handleError(valueWithoutEmojis);
        }
        let hasWarn = false;
        if (hasErrors === false && warn !== undefined) {
            hasWarn = handleWarn(valueWithoutEmojis);
        }
        if ((hasErrors === false && hasWarn === false) && info !== undefined) {
            handleInfo(valueWithoutEmojis);
        }
    };

    const handleError = (value: string) => {
        let validationErrorResult = error(value, index);

        if (validationErrorResult !== false) {
            // @ts-ignore
            setErrorMessage(validationErrorResult)

        } else {
            // @ts-ignore
            setErrorMessage(undefined)
        }
        remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod)
        return validationErrorResult;
    };

    const handleWarn = (value: string) => {
        let validationWarnResult = warn(value, index);

        if (validationWarnResult !== false) {

            // @ts-ignore
            setWarnMessage(validationWarnResult)
            remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod)
        } else {
            // @ts-ignore
            setWarnMessage(undefined)
            //   setValue(event);
            remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod)
        }
        return validationWarnResult;
    };


    const handleInfo = (value: string) => {
        let validationInfoResult = info(value, index);

        if (validationInfoResult !== false) {

            // @ts-ignore
            setInfoMessage(validationInfoResult)
        } else {
            // @ts-ignore
            setInfoMessage(undefined)
        }
        return validationInfoResult;
    };


    const setValue = (event: any) => {
        if (event.target.value === "true") {
            methods.setValue(name, true);
        } else if (event.target.value == "false") {
            methods.setValue(name, false);
        } else {
            //remove whitespace in the beginning of the value before setting value
            methods.setValue(name, event.target.value.replace(/^[ ]+/g, ""));
        }
    };


    return (
        <div>

            <div onBlur={handleChange}>
        {/*        onKeyUp={e => handleKeyUp(e, handleChange)}*/}
                <label></label>
                <TextareaAutosize
                    minRows={1}
                    maxRows={6}
                    {...methods.register(name)} defaultValue={""}
                    key={Math.random()}
                    style={textFieldStyle}
                />

            </div>
            <div>
                {(errorMessage !== undefined) &&
                    <IDSErrorMessage><span style={errorStyle}>{errorMessage}</span></IDSErrorMessage>}
                {(errorMessage === undefined && warnMessage !== undefined) &&
                    <IDSErrorMessage><span style={statusDraftStyle}>{warnMessage}</span></IDSErrorMessage>}
                {(errorMessage === undefined && warnMessage === undefined && infoMessage !== undefined) &&
                    <IDSErrorMessage><span style={statusActiveStyle}>{infoMessage}</span></IDSErrorMessage>}
            </div>
        </div>
    );
};

export default RHookFormTextAreaForTableController;