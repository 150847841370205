import React, {useEffect, useMemo, useRef, useState} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef, MRT_ExpandedState,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyCellPropsStyle,
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';

import {ICodeSystemConcept, Parameters} from "fhir-typescript-models/dist/src/models/fhir/internal";
import Client from "fhir-kit-client";
import {fhirUrl} from "../../../../model/defaultvalues/Constant";
import {getSwedishValueFromDesignation} from "../../../../codesystem/snomed/SNOMEDUtil";
import {setValue} from "../../../common/CommonFunctions";


let renderCount = 0;
const fhirClient = new Client({baseUrl: fhirUrl});

const ConceptSnomedHierarchy = (props: any) => {


    const arrayname = "concept"
    const tableRef = useRef();
    const [conceptCollection, setConceptCollection] = useState<any[]>([]);
    const [loadingRows, setLoadingRows] = useState(false);

    const lookupCodeSystemCode = async (code: string, conceptArray: []) => {
        await fhirClient.request("CodeSystem/$lookup?code=" + code +
            "&system=" + props.url + "&version=" + props.version + "&property=*"
        )
            .then(response => {

                let responseParameters: Parameters = response as unknown as Parameters;

                let responseCode = undefined;
                let responseDisplay = undefined;
                let definition = undefined;
                let swedishDisplay = undefined;
                let childCodes = [];
                let includes = [];
                let excludes = [];
                let notes = [];
                for (const element of responseParameters.parameter!) {
                    // @ts-ignore
                    if (element.name !== undefined && element.name === "code") {
                        // @ts-ignore
                        responseCode = element.valueCode;
                    }
                    // @ts-ignore
                    else if (element.name !== undefined && element.name === "display") {
                        // @ts-ignore
                        responseDisplay = element.valueString;
                    }
                    // @ts-ignore
                    else if (element.name !== undefined && element.name === "designation" && element.part[0].name === "language" &&
                        // @ts-ignore
                        element.part[0].valueCode === "sv-x-sctlang-46011000-052107") {
                        // @ts-ignore
                        swedishDisplay = element.part[2].valueString;
                    }
                    // @ts-ignore
                    else if (element.name === "property") {
                        // @ts-ignore
                        if (element.part[0].valueCode === "child" && element.part[0].name === "code" && element.part[1].name === "value"
                        ) {
                            // @ts-ignore
                            childCodes.push({code: element.part[1].valueCode, display: "", definition: ""});
                        }

                        // @ts-ignore
                        else if (element.part[0].valueCode === "definition" && element.part[0].name === "code"
                        ) {
                            // @ts-ignore
                            definition = element.part[1].valueCode;
                        }
                    }
                }


                const display = (swedishDisplay === undefined ? responseDisplay : swedishDisplay);

                // @ts-ignore
                const concept = {
                    // @ts-ignore
                    code: responseCode,
                    // @ts-ignore
                    display: display,
                    // @ts-ignore
                    definition: definition,
                    // @ts-ignore
                    concept: childCodes
                }

                if (conceptArray !== undefined) {
                    // @ts-ignore
                    conceptArray.push(concept);
                }


            })
            .catch(e => {
                console.error("FhirClient ICD handleRowClickShowConceptData Error Response" + JSON.stringify(e));
            });

    };

    useEffect(() => {
        getData();
    }, [props.parentChildDirectionData]);


    const getConcepts = async (codes: string[]) => {
        setLoadingRows(true);
        // @ts-ignore
        let concepts = [];
        for (let code of codes) {
            // @ts-ignore
            await lookupCodeSystemCode(code, concepts);
        }
        setLoadingRows(false);
        // @ts-ignore
        return concepts;

    }


    const getData = async () => {


        let concepts = [];

        if (props.parentChildDirectionData !== undefined && props.parentChildDirectionData.expansion !== undefined) {

            for (let contain of props.parentChildDirectionData.expansion.contains) {

                const code = contain.code;
                const swedishDisplay = getSwedishValueFromDesignation(contain.designation);
                let parentCodes = [];

                for (let extension of contain.extension) {
                    let parameter = extension.extension[0].valueCode;
                    if (parameter === "parent") {
                        parentCodes.push(extension.extension[1].valueCode);
                    }
                }
                concepts.push({code: code, display: swedishDisplay, parentCodes: parentCodes})


            }

            let codes: any[] = concepts.map(a => a.code);
            let conceptsWithChildren = await getConcepts(codes);
            setConceptCollection(conceptsWithChildren);
        }

    }


    // @ts-ignore
    const getSubConcepts = async (conceptsWithCodes: [], code: string, index: string) => {

        const indexStringArray = index.split(".");
        const indexNumberArray = indexStringArray.map((str) => Number(str));

        // @ts-ignore
        let codes: [] = conceptsWithCodes.map(a => a.code);
        let subRowConcepts = await getConcepts(codes);

        var array = [...conceptCollection]; // make a separate copy of the array
        if (indexNumberArray.length == 1) {
            array[indexNumberArray[0]].concept = subRowConcepts;
        } else {
            let conceptHierarchyPath = getConceptHierarchyPath(indexNumberArray.slice(1) // all except first obj in array
            );
            setValue(array[indexNumberArray[0]], conceptHierarchyPath, subRowConcepts)
        }

        setConceptCollection(array)
    }


    const getConceptHierarchyPath = (indexNumberArray: number[]) => {

        let conceptHierarchyPath: string = "";

        for (let i = 0; i < indexNumberArray.length; i++) {
            const arrayNumber = indexNumberArray[i];
            if (i == 0) {
                conceptHierarchyPath = "concept." + arrayNumber
            } else if (i != 0) {
                conceptHierarchyPath = conceptHierarchyPath + ".concept." + arrayNumber;
            }
        }

        conceptHierarchyPath = conceptHierarchyPath + ".concept";
        return conceptHierarchyPath;

    }

    renderCount++;

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true
        }
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };


    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);
    let count = 0;
    const initialExpandedRootRows = useMemo<MRT_ExpandedState>(
        () =>
            conceptCollection
                .map(concept => {
                    count = count + 1;
                    (count - 1).toString()
                    return "0";
                }) //get all the root row ids, use recursion for additional levels
                .reduce((a, v) => ({...a, [v]: true}), {}), //convert to an object with all the ids as keys and `true` as values
        [conceptCollection],
    );


    renderCount++;

    return (
        <div>
            {/*            {"conceptCollection: " + JSON.stringify(conceptCollection)}
            {"parentChildDirectionData: " + JSON.stringify(props.parentChildDirectionData)}*/}
            {/*         {initialExpandedRootRows + JSON.stringify(initialExpandedRootRows)}*/}
            <div>
                <>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        data={conceptCollection}
                        enableRowOrdering={false}
                        enableColumnFilters={true}
                        enableSorting={false}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableSelectAll={true}
                        enableEditing={false}
                        enableHiding={false}
                        editingMode="table"
                        enableRowActions
                        positionActionsColumn="last"
                        enableExpanding
                        enableExpandAll={false}
                        paginateExpandedRows={false}
                        tableInstanceRef={tableInstanceRef}
                        state={{isLoading: loadingRows}}
                        // @ts-ignore
                        /*         getRowId={(originalRow) => originalRow.code}*/
                        initialState={{pagination: {pageSize: 50, pageIndex: 0}, expanded: initialExpandedRootRows}}
                        muiExpandButtonProps={({row, table}) => ({
                            onClick: () => {
                                if (row.original.code !== "138875005") {
                                    let rowrow = row;
                                    let tabletable = table;
                                    //id
                                    // :
                                    // "0.0"
                                    // @ts-ignore
                                    getSubConcepts(row.original.concept, row.original.code, row.id)
                                }
                            }
                        })}


                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsFiveColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}

                        // @ts-ignore
                        getSubRows={(originalRow, index) => {
                            return (
                                originalRow.concept
                            )
                        }}

                        muiTableHeadCellColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}

                        muiTableBodyCellProps={{
                            sx: muiTableBodyCellPropsStyle
                        }}

                    />
                </>
            </div>
        </div>
    );
};

export default ConceptSnomedHierarchy;
