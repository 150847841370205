import {permissionToEdit} from "../user/PermissionUtil";
import Client from "fhir-kit-client";
import {codesystem, fhirUrl} from "../model/defaultvalues/Constant";
import {ACCESS_TOKEN} from "../constants";
import {
    handleIfValidationExternalNotificationsAllTypes
} from "../components/common/validation/external/ValidationExternalNotificationService";
import {translateIdentifierFromUrn, translateIdentifierToUrn} from "../components/artifact/meta/IdentifierTranslator";
import {perFormValidationInternalMetaDataWarnings_hasWarnings} from "../components/common/validation/ValidationInternalMetaDataWarningRulesUtil";


export const externallyValidateMetaData = (body, infoMessageList, handleInfoMessageList,
                                           warnMessageList, handleWarnMessageList,
                                           errorMessageList, handleErrorMessageList, methods, setIsRequesting, editDisabled, resourceType) => {
    if ((permissionToEdit(methods) && editDisabled === false) && (methods.getValues("status") === "draft")) {

        translateIdentifierToUrn(body);
        const fhirClientValidation = new Client({baseUrl: fhirUrl + "/validation"});
        fhirClientValidation.create({
            resourceType: resourceType,
            // @ts-ignore
            body: body,
            options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}},
        }).then(response => {
            setIsRequesting(false);
            translateIdentifierFromUrn(body, editDisabled);

            if (response !== undefined &&
                response.validationNotifications !== undefined) {
                if (setConceptValuesToEmptyStringIfEmptyForFieldValidationToReactOn(body)) {
                    methods.reset(body);
                }
                handleIfValidationExternalNotificationsAllTypes(
                    // @ts-ignore
                    response, methods,
                    infoMessageList, handleInfoMessageList,
                    warnMessageList, handleWarnMessageList,
                    errorMessageList, handleErrorMessageList
                )
            } else if (response !== undefined) {
                if (setConceptValuesToEmptyStringIfEmptyForFieldValidationToReactOn(body)) {
                    methods.reset(body);
                }
                perFormValidationInternalMetaDataWarnings_hasWarnings(methods, handleWarnMessageList);
            }
        })
            .catch(e => {
                setIsRequesting(false);
                translateIdentifierFromUrn(body, editDisabled);
                console.error("FhirClient Validate Error Response" + JSON.stringify(e));
            });
    }
};

export const externallyValidateCreatedInfoNotifications = (body, infoMessageList, handleInfoMessageList,
                                                           methods, editDisabled, resourceType) => {
    if ((permissionToEdit(methods) && editDisabled === false) && (methods.getValues("status") === "draft")) {

        const fhirClientValidation = new Client({baseUrl: fhirUrl + "/validationCreateInfoNotification"});
        fhirClientValidation.create({
            resourceType: resourceType,
            // @ts-ignore
            body: body,
            options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}},
        }).then(response => {
            if (response !== undefined &&
                response.validationNotifications !== undefined) {

                handleIfValidationExternalNotificationsAllTypes(
                    // @ts-ignore
                    response, methods,
                    infoMessageList, handleInfoMessageList
                )
            }
        })
            .catch(e => {
                translateIdentifierFromUrn(body, editDisabled);
                console.error("FhirClient Validate Error Response" + JSON.stringify(e));
            });

    }
};


export const setConceptValuesToEmptyStringIfEmptyForFieldValidationToReactOn = (body) => {

    let edited = false;
    if (body.resourceType === codesystem && body.concept !== undefined) {
        for (let c of body.concept) {

            if (c.display === undefined) {
                c.display = "";
                edited = true;
            }
            if (c.definition === undefined) {
                c.definition = "";
                edited = true;
            }
        }
    }
    return edited;
}