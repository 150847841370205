import {useFormContext} from "react-hook-form";
import {getRenderCountText} from '../../../common/RenderCount';
import React, {FC} from "react";
import TelecomArray from "./TelecomArray";
import {IDSContainer, IDSCol, IDSRow} from "@inera/ids-react";
import RHookFormTextFieldController from "../../../common/rhookform/RHookFormTextFieldController";
import {metaDataTabName} from "../../../common/page/TabName";

let renderCount = 0;

interface EmailForPublisherProps {
    editDisabled: boolean,
    publisherNameValidation: any,
    handleWarnMessageList: any,
    handleErrorMessageList: any,
    removeValidationMessagesOnPath: any
}


const Publisher: FC<EmailForPublisherProps> = ({
                                                   editDisabled,
                                                   publisherNameValidation,
                                                   handleWarnMessageList,
                                                   handleErrorMessageList,
                                                   removeValidationMessagesOnPath
                                               }) => {

    renderCount++;

    const methods = useFormContext();

    return (
        <IDSContainer gutterless={true}>
            <IDSRow justify="space-between">
                <IDSCol cols="12">
                    <br/>
                    <h3 className="ids-heading-3">Förvaltare</h3>
                </IDSCol>
            </IDSRow>
            <IDSRow justify="space-between">
                <IDSCol cols="6">
                    <RHookFormTextFieldController methods={methods} label="Namn på förvaltare (obligatorisk)"
                                                  name="publisher"
                                                  editDisabled={editDisabled}
                                                  warn={publisherNameValidation}
                                                  tabName={metaDataTabName}
                                                  handleWarnMessageList={handleWarnMessageList}/>
                </IDSCol>
                <IDSCol cols="6">
                </IDSCol>
            </IDSRow>
            <IDSRow justify="space-between">
                <IDSCol cols="12">
                    <div>
                        <TelecomArray parentParameters={"contact.0"}
                                      methods={methods}
                                      editDisabled={editDisabled}
                                      handleWarnMessageList={handleWarnMessageList}
                                      handleErrorMessageList={handleErrorMessageList}
                                      removeValidationMessagesOnPath={removeValidationMessagesOnPath}/>
                    </div>
                </IDSCol>
            </IDSRow>
        </IDSContainer>
    );
}
export default Publisher;