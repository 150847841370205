import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {definitionMinInfo, displayMinWarn
} from "../meta/MetaWarnInfoConstant";
import {contentTabName} from "../../common/page/TabName";


let renderCount = 0;
const arrayname = "concept"

export const validateInfoDefinition = (value: string, rowindex: string, infoMessageList: [], handleInfoMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {
    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        handleEmptyRowWarningMessages(rowindex, definitionMinInfo, handleInfoMessageList, methods);
    } else {

        let validationResults = []

        let minValidationResult = definitionMinErrorValidation(value, rowindex, infoMessageList, handleInfoMessageList, methods);
        let minValidation = {"label": definitionMinInfo, "message": minValidationResult}
        validationResults.push(minValidation);


        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }

        return finalInfoValidationResultHandling(validationMessage, rowindex, methods);
    }
}

const definitionMinErrorValidation = (value: string, rowindex: string, errorMessageList: [], handleErrorMessageList: any, methods: any) => {

    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
        let message = "Beskrivning ofullständig. Lägg till beskrivning."
        let validationResult = value !== undefined && ((value.trim().length != 0 && value.trim().length < 2) && message);
        let validationmessage = collectValidationMessage(message, displayMinWarn, validationResult, handleErrorMessageList, contentTabName, rowindex);
        return validationmessage;
    } else {
        return false;
    }
}


const finalInfoValidationResultHandling = (validationMessage: string, rowindex: string, methods: any) => {
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (validationMessage !== "") {
        rowConceptToValidate.definitionInformation = validationMessage;
        return validationMessage;
    }else {
        rowConceptToValidate.definitionInformation = undefined;
        return false;
    }
}

const handleEmptyRowWarningMessages = (rowindex: string, infoType: string, handleInfoMessageList: any, methods: any) => {
    handleInfoMessageList(null, infoType, rowindex);
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    rowConceptToValidate.definitionInformation = undefined;
}

