import React from 'react';
import {getErrorField} from "../rhookform/RHookFormMessageUtil";
import {collectValidationMessage} from "./MetaValidationRules";
import ITabName from "../page/ITabName";
import {YUPErrorLevelValidation} from "./ValidationInternalMetaDataYupErrorRules";


export const createLabel = (name: string, errorType: string) => {
    return name + errorType;
}

export const handlePerformedValidationAfterNewContent = (event: any, name: string, tabName: ITabName, methods: any,
                                                         handleErrorMessageList?: any, handleWarnMessageList?: any, handleInfoMessageList?: any) => {
    //remove error until next submit
    const errorField = getErrorField(name, methods)

    //need to add error in message list with label field+errortype
    if (errorField !== undefined && (handleErrorMessageList !== undefined || handleInfoMessageList !== undefined || handleWarnMessageList !== undefined)) {

        let fieldIdentifier = undefined;
        if (name.startsWith("identifier")) {
            fieldIdentifier = name;
        }

        // @ts-ignore
        const code = errorField?.code;
        if (code !== undefined && (errorField?.type === "ERROR")) {
            if (tabName !== undefined) {
                collectValidationMessage("", code.toString(), false, handleErrorMessageList, tabName, fieldIdentifier);
                methods.clearErrors(name)
            }
        } else if (errorField !== undefined && errorField?.type !== undefined && (errorField?.type.startsWith(YUPErrorLevelValidation))) {
            if (tabName !== undefined) {
                collectValidationMessage("", createLabel(name, errorField.type), false, handleInfoMessageList, tabName);
                methods.clearErrors(name)
            }
        } else if (code !== undefined && errorField?.type === "INFO") {
            if (tabName !== undefined) {
                collectValidationMessage("", code.toString(), false, handleInfoMessageList, tabName, fieldIdentifier);
                methods.clearErrors(name)
            }
        } else if (code !== undefined && errorField?.type === "WARNING") {
            if (tabName !== undefined) {
                collectValidationMessage("", code.toString(), false, handleWarnMessageList, tabName, fieldIdentifier);
                methods.clearErrors(name)
            }
        }

    }
}
