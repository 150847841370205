import {IValueSet} from "fhir-typescript-models";
import {
    IValueSetCompose, IValueSetComposeInclude
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    removeContactFormDefault, removeEmptyMetaDataFields, removeIdentifierFormDefault,
} from "./MetaDataDefaultFormValue";
import {removeEmojiFromJson} from "../../components/common/CommonFunctions";
import {removeComposeFormExtraProperties} from "../../components/artifact/valueset/IncludeUtil";
import {removeExtensionFormDefault} from "./MetaDataExtensionDefaultFormValue";


export const defaultCodeSystemForValueSet = {
    identifier: [{system: '', value: ''}],
    concept: [{code: '', display: '', definition: '', selected: false, codeSystemConceptIndex: 0}],
    resourceType: "CodeSystem",
};

export const defaultCodeSystemForValueSetArray = [
    defaultCodeSystemForValueSet
];

export const compose: IValueSetCompose = {
    include: [{system: "", version: "", concept: [{code: "", display: ""}]}]
};


export function removeDefaultValueSetFormValues(data: IValueSet) {

    data = removeEmojiFromJson(data);

    removeExtensionFormDefault(data);

    removeContactFormDefault(data);

    removeIdentifierFormDefault(data);

    removeComposeFormDefault(data);

    removeEmptyMetaDataFields(data);


    return data;
};

export function removeComposeFormDefault(data: IValueSet) {
    if (data.compose === compose) {
        data.compose = undefined;
    } else if (data.compose && data.compose.include) {
        removeComposeFormExtraProperties(data.compose.include);
    }
}





