import React, {FC, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {handleNameChange} from "./UrlFunctions";
import RHookFormTextFieldSpan from "../../common/rhookform/RHookFormTextFieldSpan";
import {getPreviousVersion} from "./PreviousVersionTagUtil";
import {metaDataTabName} from "../../common/page/TabName";
import {replaceSwedishCharacters} from "../../../components/common/CommonFunctions";

let renderCount = 0;

interface NameProps {
    artifactType: string,
    handleErrorMessageList?: any
    handleInfoMessageList?: any
    disabled: boolean
    enableValidationWhenFieldDisabled?: boolean
}

const Name: FC<NameProps> = ({
                                 artifactType,
                                 handleErrorMessageList,
                                 handleInfoMessageList,
                                 disabled,
                                 enableValidationWhenFieldDisabled
                             }) => {

    const name = "name";
    const methods = useFormContext();
    const [nameDisabled, setNameDisabled] = useState(disabled);

    useEffect(() => {
        if (disabled == false) {
            const previousVersionExist = hasPreviousVersion();
            if (previousVersionExist) {
                setNameDisabled(true);
            } else {
                setNameDisabled(false);
            }
        }
    }, [methods.getValues("name"), disabled])

    const hasPreviousVersion = () => {
        let tag = methods.getValues("meta.tag");
        if (tag !== undefined) {
            const previousVersion = getPreviousVersion(methods.getValues("meta.tag"));
            if (previousVersion !== undefined) {
                return true;
            }
        }
        return false;
    }

    const handleChangeEvent = (event: any) => {
        const nameWithoutSwedishCharacters = replaceSwedishCharacters(event.target.value);
        methods.setValue("name", nameWithoutSwedishCharacters)
        handleNameChange(nameWithoutSwedishCharacters, methods, artifactType)
    }

    renderCount++;
    return (
        <div>
            <RHookFormTextFieldSpan label="Systemnamn (obligatorisk)" name="name"
                                    handleErrorMessageList={handleErrorMessageList}
                                    handleInfoMessageList={handleInfoMessageList}
                                    handleChangeEvent={handleChangeEvent}
                                    disabled={nameDisabled}
                                    enableValidationWhenFieldDisabled={enableValidationWhenFieldDisabled}
                                    tabName={metaDataTabName}/>
        </div>
    );
};

export default Name;
