import React from "react";
import {SNOMED_CT_URL} from "../../model/defaultvalues/Constant";


const snomedSwedishLanguageCode = "sv-x-sctlang-46011000-052107";
export const getSwedishValueFromDesignation = (designationArray: any[]) => {
    let swedishValue = undefined;
    //  @ts-ignore
    for (let designation of designationArray) {
        //  @ts-ignore
        if (designation.language === snomedSwedishLanguageCode) {
            //  @ts-ignore
            swedishValue = designation.value;
            break;
        }
    }
    return swedishValue;
}

export const isSnomed = (url: string) => {
    if (url === SNOMED_CT_URL) {
        return true;
    } else {
        return false;
    }
}
