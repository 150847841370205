import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {errorStyle, warnStyle, infoStyle, textFieldStyle} from "../../../styles/TTStyles";
import {
    getFieldNameInArrayHierarchy,
    getHierarchyAsString,
    hasRowCellsAllEmptyValues
} from "./RHookFormArrayHierarchyFunction";
import {IDSErrorMessage} from "@inera/ids-react";
import {remountConceptArrayWithNewValue} from "./RHookTextFieldForTableUtil";
import {removeEmojiFromString} from "../CommonFunctions";

interface ReactHookFormTextFieldIneraControllerProps {
    arrayname: string;
    fieldname: string;
    index: string;
    methods: UseFormReturn;
    editDisabled: boolean,
    warn?: any,
    info?: any,
    error?: any,
    label?: string,
    arrayUpdateMethod?: any,
    arrayAppendMethod?: any
}

// @ts-ignore
const usePreviousValue = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const RHookFormTextFieldForTableController: FC<ReactHookFormTextFieldIneraControllerProps> = ({
                                                                                                  arrayname,
                                                                                                  fieldname,
                                                                                                  index,
                                                                                                  methods,
                                                                                                  editDisabled,
                                                                                                  warn,
                                                                                                  info,
                                                                                                  error,
                                                                                                  label,
                                                                                                  arrayUpdateMethod,
                                                                                                  arrayAppendMethod
                                                                                              }: ReactHookFormTextFieldIneraControllerProps) => {

    const [isTouched, setIsTouched] = useState(false);
    /*    const [errorMessage, setErrorMessage] = useState(undefined);*/
    /*    const [warnMessage, setWarnMessage] = useState(undefined);*/


    const getHierarchyForConcept = useCallback(async () => {
    }, []);


    const name = getFieldNameInArrayHierarchy(index, arrayname, fieldname);

    const prevValue = usePreviousValue(methods.getValues(name))

    useEffect(() => {
        //   console.log("useEffect methods.getValues(getHierarchyAsString");
        if (!editDisabled) {
            handleChange02(methods.getValues(name))
        }
        //   }
    }, [methods.getValues(name)]);


    useEffect(() => {
     //   console.log("useEffect methods.getValues(name)");
        if (fieldname === "code" && methods.getValues(name) === "") {
            handleError(methods.getValues(name));
        }else if(fieldname === "display" && (methods.getValues(name) === undefined || methods.getValues(name) === "")){
            handleWarn(methods.getValues(name));
        }
    }, [methods.getValues(getHierarchyAsString(index, arrayname) + ".display"), methods.getValues(getHierarchyAsString(index, arrayname) + ".definition")]);


    const {onChange, onBlur, ref} = methods.register(name);

    //TODO can we remove this method and rely on useEffect handleChange2 ?
    // @ts-ignore
    const handleChange = event => {
        remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod)
        setIsTouched(true);
    };


    const handleChange02 = (value: string) => {
        let valueWithoutEmojis = removeEmojiFromString(value);
        if (valueWithoutEmojis !== undefined) {
            methods.setValue(name, valueWithoutEmojis);
        }
        let validationErrorResult = false;
        let validationWarnResult = false;
        if (error !== undefined) {
            validationErrorResult = handleError(valueWithoutEmojis);
        }

        validationErrorResult = validationErrorResult === undefined || validationErrorResult == true;
        if (!validationErrorResult && warn !== undefined) {
            validationWarnResult = handleWarn(valueWithoutEmojis);
        }

        validationWarnResult = validationWarnResult === undefined || validationWarnResult == true;
        if ((!validationErrorResult && !validationWarnResult) && info !== undefined) {
            handleInfo(valueWithoutEmojis);
        }
        remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod)
    };

    const handleError = (value: string) => {
        let validationErrorResult = error(value, prevValue, index, hasRowCellsAllEmptyValues(name, methods));
        return validationErrorResult;
    };

    const handleWarn = (value: string) => {
        let validationWarnResult = warn(value, prevValue, index, hasRowCellsAllEmptyValues(name, methods));
        return validationWarnResult;
    };

    const handleInfo = (value: string) => {
        let validationInfoResult = info(value, prevValue, index, hasRowCellsAllEmptyValues(name, methods));
    };

    const setValue = (event: any) => {
        if (event.target.value === "true") {
            methods.setValue(name, true);
        } else if (event.target.value == "false") {
            methods.setValue(name, false);
        } else {
            //remove whitespace in the beginning of the value before setting value
            methods.setValue(name, event.target.value.replace(/^[ ]+/g, ""));
        }
    };

    const getCodeErrorCodeMessage = () => {
        const concept = methods.getValues(getHierarchyAsString(index, arrayname));
        if (concept === undefined) {
            return undefined;
        } else {
            return methods.getValues(getHierarchyAsString(index, arrayname)).codeError;
        }
    };

    const getCodeWarnMessage = () => {
        const concept = methods.getValues(getHierarchyAsString(index, arrayname));
        if (concept === undefined) {
            return undefined;
        } else {
            return methods.getValues(getHierarchyAsString(index, arrayname)).codeWarn;
        }
    };

    const getCodeInfoMessage = () => {
        const concept = methods.getValues(getHierarchyAsString(index, arrayname));
        if (concept === undefined) {
            return undefined;
        } else {
            return methods.getValues(getHierarchyAsString(index, arrayname)).codeInfo;
        }
    };

    const getDisplayErrorMessage = () => {
        const concept = methods.getValues(getHierarchyAsString(index, arrayname));
        if (concept === undefined) {
            return undefined;
        } else {
            return methods.getValues(getHierarchyAsString(index, arrayname)).displayError;
        }
    };

    const getDisplayWarnMessage = () => {
        const concept = methods.getValues(getHierarchyAsString(index, arrayname));
        if (concept === undefined) {
            return undefined;
        } else {
            return methods.getValues(getHierarchyAsString(index, arrayname)).displayWarning;
        }
    };

    return (
        <div>
            <div onBlur={handleChange}
                //  onKeyUp={e => handleKeyUp(e, handleChange)}
            >
{/*                {"Concept : " + index + ", " + JSON.stringify(methods.getValues(arrayname + "." + index))}*/}

                <label></label><input type="text" {...methods.register(name)} defaultValue={""}
                                      key={Math.random()}
                                      style={textFieldStyle}/></div>
            <div>
                {((fieldname == "code" && (getCodeErrorCodeMessage() !== undefined))) &&
                    <IDSErrorMessage><span style={errorStyle}>{getCodeErrorCodeMessage()}</span></IDSErrorMessage>}
                {((fieldname == "code" && (getCodeWarnMessage() !== undefined))) &&
                    <IDSErrorMessage><span style={warnStyle}>{getCodeWarnMessage()}</span></IDSErrorMessage>}
                {((fieldname == "code" && (getCodeInfoMessage() !== undefined))) &&
                    <IDSErrorMessage><span style={infoStyle}>{getCodeInfoMessage()}</span></IDSErrorMessage>}
                {((fieldname == "display" && (getDisplayErrorMessage() !== undefined))) &&
                    <IDSErrorMessage><span style={errorStyle}>{getDisplayErrorMessage()}</span></IDSErrorMessage>}
                {((fieldname == "display" && (getDisplayWarnMessage() !== undefined))) &&
                    <IDSErrorMessage><span style={warnStyle}>{getDisplayWarnMessage()}</span></IDSErrorMessage>}
            </div>
        </div>
    );
};

export default RHookFormTextFieldForTableController;