import React, {useEffect} from "react";
import {IDSSelect} from "@inera/ids-react";
import {UseFormReturn, Controller} from 'react-hook-form';
import {FC, useState} from "react";
import {IRHookSelectOption} from "./IRHookSelectOption";
import RHookFormValidationMessage from "../validation/RHookFormValidationMessage";
import ITabName from "../page/ITabName";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../validation/ValidationInternalMetaDataWarningRulesUtil";
import {collectValidationInternalMetaDataYupErrorRules} from "../validation/ValidationInternalMetaDataYupErrorRulesUtil";

let renderCount = 0;

interface RHookSelectProps {
    label: string;
    name: string;
    tabName?: ITabName;
    methods: UseFormReturn;
    options: IRHookSelectOption[];
    disabled: boolean;
    customHandleChange?: any;
    handleWarnMessageList?: any;
    handleErrorMessageList?: any;
    rowindex?: string;
}

const RHookSelect: FC<RHookSelectProps> = ({
                                               label,
                                               methods,
                                               tabName,
                                               options,
                                               name,
                                               disabled,
                                               customHandleChange,
                                               handleWarnMessageList,
                                               handleErrorMessageList,
                                               rowindex,
                                           }: RHookSelectProps) => {
    renderCount++;

    const [previousYupValidation, setPreviousYupValidation] = useState<string>();
    const arrayName = rowindex === undefined ? "" : name.substring(0, name.indexOf("."));

    useEffect(() => {
        if (tabName !== undefined) {
            collectValidationInternalMetaDataYupErrorRules(name, previousYupValidation, setPreviousYupValidation, handleErrorMessageList, methods, tabName, rowindex);
           removeValidationInternalMetaDataWarningFromValidationCollectionList(name, handleWarnMessageList, methods, tabName, undefined)
        }
        //methods.formState.errors?.['identifier']?.[0]?.['value']?.['message']
    }, [methods.formState.errors[name], methods.formState.errors[arrayName]]);


    // @ts-ignore
    const handleChange = event => {
        if (customHandleChange !== undefined) {
            let value: string = event.target.value;
            rowindex === undefined ? customHandleChange(event) : customHandleChange(event, rowindex)
            if (tabName !== undefined) {
                collectValidationInternalMetaDataYupErrorRules(name, previousYupValidation, setPreviousYupValidation, handleErrorMessageList, methods, tabName, rowindex);
            }
        } else if (event.target.value === "true") {
            methods.setValue(name, true);
        } else if (event.target.value == "false") {
            methods.setValue(name, false);
        } else if (event.target.value === "") {
            methods.setValue(name, undefined);
        }
    };

    const getDisabled = () => {
        if (disabled !== undefined && disabled) {
            return disabled;
        } else {
            return false;
        }
    }

    const showDisplaySelectFromTheListLabel = () => {
        if (options.length == 1) {
            return false;
        } else {
            return true;
        }
    }


    return (
        <div className={'container'} key={"select_" + name}>
            <Controller
                name={name}
                control={methods.control}
                defaultValue=""
                render={({field: {onChange, value}}) => (
                    <IDSSelect>
                        <label>{label}</label>
                        <select
                            defaultValue="1"
                            className="input-container"
                            id={"select-" + name}
                            disabled={getDisabled()}
                            value={value}
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e);
                            }}
                        >
                            {showDisplaySelectFromTheListLabel() &&
                                <option value={""} disabled={true} key={Math.random()}>Välj i listan</option>
                            }
                            {options.map((item) => (
                                <option value={item.value} key={Math.random()}>{item.label}</option>
                            ))}
                        </select>
                    </IDSSelect>
                )
                }
            />
            <RHookFormValidationMessage name={name} editDisabled={disabled}/>
        </div>
    );
}

export default RHookSelect;
