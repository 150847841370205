import React, {useEffect, useMemo, useRef} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTableHeadRowErrorPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import RowActionsMenu from "../../concept/table/RowActionsMenu";


let renderCount = 0;

const VSConceptTable = (props: any) => {

    renderCount++;

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        }
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    return (

        <div>
            <div>
                <MaterialReactTable
                    {...commonTableSourceProps}
                    autoResetPageIndex={false}
                    columns={columns}
                    data={(props.data === undefined || props.data.length == 0 || props.data[0].code === "") ? [] : props.data}
                    enableRowOrdering={false}
                    enableColumnFilters={true}
                    enableSorting={true}
                    enableRowActions
                    localization={MRT_Localization_SV}
                    enableTopToolbar={false}
                    enableSelectAll={true}
                    enableEditing={true}
                    enableHiding={false}
                    editingMode="table"
                    positionActionsColumn="last"
                    enableExpanding
                    enableExpandAll //default
                    tableInstanceRef={tableInstanceRef}
                    initialState={{pagination: {pageSize: 5, pageIndex: 0}}}

                    muiTableHeadRowProps={{
                        sx: (props.isErrorTable === undefined || props.isErrorTable == false) ? muiTableHeadRowPropsStyle : muiTableHeadRowErrorPropsStyle
                    }}

                    muiTableProps={{
                        sx: muiTablePropsFiveColumnStyle
                    }}

                    muiTableBodyProps={{
                        sx: muiTableBodyPropsStyle
                    }}


                    muiTablePaperProps={{
                        elevation: 0, //change the mui box shadow
                    }}

                    icons={{
                        MoreVertIcon: () => <ImportExport/>,
                        MoreHorizIcon: () => <MoreVertIcon/>,
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            size: 10,
                        },
                        'mrt-row-drag': {
                            header: '',
                            size: 10,
                        },
                    }}

                    getSubRows={(originalRow) => {
                        return (
                            originalRow.concept
                        )
                    }}

                    muiTableHeadCellColumnActionsButtonProps={{
                        children: <ExpandMore/>
                    }}


                    renderRowActions={({row, table}) => (
                        <div>

                                {(!props.editDisabled && props.remove !== undefined) &&
                                <RowActionsMenu rowid={row.index} table={table} remove={props.remove}
                                                append={props.append}/>
                            }

                        </div>
                    )}

                />
            </div>
        </div>
    );
};

export default VSConceptTable;
