import React, {FC, useEffect, useState} from "react";
import {UseFormReturn} from "react-hook-form";
import {IDSCol, IDSContainer, IDSRow, IDSButton} from "@inera/ids-react";
import RHookSelect from "../../../common/rhookform/RHookSelect";
import {IRHookSelectOption} from "../../../common/rhookform/IRHookSelectOption";
import Bin from "../../../../img/bin";
import {metaDataTabName} from "../../../common/page/TabName";
import RHookFormTextFieldForExtension from "../../../common/rhookform/RHookFormTextFieldForExtension";
import {emailValidation, phoneValidation, urlValidation} from "./TelecomValidation";
import {
    authorEmailWarn, authorPhoneWarn,
    authorUrlWarn,
    publisherEmailWarn,
    publisherPhoneWarn,
    publisherUrlWarn
} from "../MetaWarnInfoConstant";
import ITelecom from "./ITelecom";
import {remountArrayWithNewValue} from "../../../common/rhookform/RHookTextFieldForTableUtil";
import {extension, extensionAuthorCodeSystemUrl} from "../../../../model/defaultvalues/Constant";
import {collectValidationMessage} from "../../../common/validation/MetaValidationRules";
import {telecomEmpty} from "../../../../model/defaultvalues/MetaDataDefaultFormValue";
import {calculateShowBin} from "./TelecomBinFunction";

let renderCount = 0;


interface TelecomProps {
    arrayname: string,
    index: number,
    methods: UseFormReturn,
    editDisabled: boolean,
    handleWarnMessageList: any,
    handleErrorMessageList: any,
    removeValidationMessagesOnPath: any,
    append: any,
    update: any,
    remove: any
    onChangeTelecomHandleArrayView: any,
}

const email = "email";
const phone = "phone";
const url = "url";

const contactPointSystemOptions: IRHookSelectOption[] = [
    {label: email, value: email},
    {label: "telefon", value: phone},
    {label: url, value: url}
];


const Telecom: FC<TelecomProps> = ({
                                       arrayname,
                                       index,
                                       methods,
                                       editDisabled,
                                       handleWarnMessageList,
                                       handleErrorMessageList,
                                       removeValidationMessagesOnPath,
                                       append,
                                       update,
                                       remove,
                                       onChangeTelecomHandleArrayView
                                   }: TelecomProps) => {

    renderCount++;


    const [warnValidation, setWarnValidation] = useState<string>();
    const [showBin, setShowBin] = useState<boolean>(calculateShowBin(index, arrayname, editDisabled, methods));
    const systemFieldName: string = arrayname + `.${index}.system`;
    const valueFieldName: string = arrayname + `.${index}.value`;
    const telecomPath: string = arrayname + `.${index}`;

    const revaluateShowBin = () => {
        const showBinResult = calculateShowBin(index, arrayname, editDisabled, methods);
        setShowBin(showBinResult)
    }

    useEffect(() => {
        revaluateShowBin()
    }, [editDisabled]);

    const onChangeTelecomValue = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value !== undefined && (typeof value == "string")) {
            const fieldValue = methods.getValues(systemFieldName)
            if (fieldValue !== phone) {
                //remove blanc space
                value = value.replace(/\s/g, '');
            }
            methods.setValue(valueFieldName, value);
            if (index > 1) {
                //   debugger;
                remountArrayWithNewValue(index, {
                    system: fieldValue,
                    value: value
                }, update);
            }
        }
        revaluateShowBin();
        onChangeTelecomHandleArrayView();
    };

    const onChangeTelecomSystem = (e: React.ChangeEvent<HTMLInputElement>, rowindex: string) => {
        let system: string = e.target.value;
        const previousValue = methods.getValues(systemFieldName)
        const value = methods.getValues(valueFieldName)
        if (value !== undefined && value !== "") {
            //  run validation;
            remountArrayWithNewValue(rowindex, {
                system: system,
                value: value
            }, update);
        }
        revaluateShowBin();
    }

    const removeTelecom = (index: number) => {
        methods.clearErrors(telecomPath);
        methods.clearErrors(systemFieldName);
        methods.clearErrors(valueFieldName);
        remove(index);
        removeValidationMessagesOnPath(telecomPath);
        if (index == 0) {
            const telecomArray = methods.getValues(arrayname);
            if (telecomArray.length == 0) {
                //Add one empty for form to display
                append(telecomEmpty);
            }
        }
        revaluateShowBin();
    }


    const getValueFieldDisabled = (index: number) => {
        let disabled = true;
        const fieldValue = methods.getValues(systemFieldName)

        if (fieldValue !== undefined && !editDisabled) {
            disabled = false;
        }

        return disabled;
    }

    const getValueFieldWarnValidationByIndex = (index: number) => {
        if (!editDisabled) {
            const system = methods.getValues(systemFieldName)
            return getValueFieldWarnValidationSystem(system);
        }
    }

    const getValueFieldWarnValidationSystem = (system: string) => {
        if (!editDisabled) {
            if (arrayname.startsWith(extension)) {
                return getAuthorValueFieldWarnValidation(system)
            } else {
                return getPublisherValueFieldWarnValidation(system)
            }
        }
    }

    const getPublisherValueFieldWarnValidation = (system: string) => {
        if (system === email) {
            return publisherEmailValidation
        } else if (system === url) {
            return publisherUrlValidation
        } else if (system === phone) {
            return publisherPhoneValidation
        } else {
            return noValidation;
        }
    }

    const getAuthorValueFieldWarnValidation = (fieldValue: string) => {
        if (fieldValue === email) {
            return authorEmailValidation
        } else if (fieldValue === url) {
            return authorUrlValidation
        } else if (fieldValue === phone) {
            return authorPhoneValidation
        } else {
            return noValidation;
        }
    }

    const handleCurrentWarnValidation = (warnValidationName: string | undefined) => {
        if (warnValidation !== undefined && warnValidation !== warnValidationName) {
            collectValidationMessage("", warnValidation, false, handleWarnMessageList, metaDataTabName);
            //   removeRhookFormAddedValidationFromValidationCollectionList(arrayname + `.${index}.value`, handleWarnMessageList, methods, metaDataTabName, undefined);
        }
        setWarnValidation(warnValidationName);
    }

    const publisherEmailValidation = (value: string) => {
        handleCurrentWarnValidation(publisherEmailWarn);
        return emailValidation(value, publisherEmailWarn, telecomPath, handleWarnMessageList)
    }

    const publisherUrlValidation = (value: string) => {
        handleCurrentWarnValidation(publisherUrlWarn);
        return urlValidation(value, publisherUrlWarn, telecomPath, handleWarnMessageList)
    }

    const publisherPhoneValidation = (value: string) => {
        handleCurrentWarnValidation(publisherPhoneWarn);
        return phoneValidation(value, publisherPhoneWarn, telecomPath, handleWarnMessageList)
    }

    const authorEmailValidation = (value: string) => {
        handleCurrentWarnValidation(authorEmailWarn);
        return emailValidation(value, authorEmailWarn, telecomPath, handleWarnMessageList)
    }

    const authorUrlValidation = (value: string) => {
        handleCurrentWarnValidation(authorUrlWarn);
        return urlValidation(value, authorUrlWarn, telecomPath, handleWarnMessageList)
    }

    const authorPhoneValidation = (value: string) => {
        handleCurrentWarnValidation(authorPhoneWarn);
        return phoneValidation(value, authorPhoneWarn, telecomPath, handleWarnMessageList)
    }

    const noValidation = (value: string) => {
        handleCurrentWarnValidation(undefined);
        return false;
    }

    const getContactSystemOptions = (telecomIndex: number) => {
        //`contact[${nestIndex}].telecom.${index}.system`
        let contactSystemsToReturn: IRHookSelectOption[] = [];

        const telecomArray: ITelecom[] = methods.getValues(arrayname);
        if (telecomArray !== undefined) {
            const system: string = methods.getValues(systemFieldName);
            if (telecomIndex == 0 && telecomArray.length == 1) {
                contactSystemsToReturn = contactPointSystemOptions;
            } else if (telecomArray.length == (telecomIndex + 1)) {

                //remove from options the already selected contactTypes in form for the artefact
                let clonedArrayWithRemovedAlreadySelected: IRHookSelectOption[] = [...contactPointSystemOptions];
                for (let i = 0; i < telecomArray.length; i++) {
                    if (i != telecomIndex) {
                        const telecom = telecomArray[i];
                        clonedArrayWithRemovedAlreadySelected = clonedArrayWithRemovedAlreadySelected.filter(
                            item => (
                                item.value !== telecom.system
                            )
                        );
                    }
                }

                //if only one option left in the optionlist, that one option must be set as default selected value:
                if (clonedArrayWithRemovedAlreadySelected.length == 1) {
                    methods.setValue(systemFieldName, clonedArrayWithRemovedAlreadySelected[0].value);
                }

                contactSystemsToReturn = clonedArrayWithRemovedAlreadySelected;
            } else {
                //Only display selected system
                let clonedSystemOptions: IRHookSelectOption[] = [...contactPointSystemOptions];
                clonedSystemOptions = clonedSystemOptions.filter(
                    item => (
                        item.value === system
                    )
                );
                contactSystemsToReturn = clonedSystemOptions;
            }
        }
        return contactSystemsToReturn;
    }


    return (
        <div>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        <RHookSelect label={"Typ av kontaktuppgift"}
                                     methods={methods}
                                     name={systemFieldName}
                                     options={getContactSystemOptions(index)}
                                     rowindex={index.toString()}
                                     tabName={metaDataTabName}
                                     disabled={editDisabled}
                                     customHandleChange={onChangeTelecomSystem}
                                     handleWarnMessageList={handleWarnMessageList}
                                     handleErrorMessageList={handleErrorMessageList}/>
                    </IDSCol>
                    <IDSCol cols="6">
                        <RHookFormTextFieldForExtension methods={methods} label="Kontaktuppgift"
                                                        rowindex={index.toString()}
                                                        tabName={metaDataTabName}
                                                        name={valueFieldName}
                                                        editDisabled={getValueFieldDisabled(index)}
                                                        warnFunction={getValueFieldWarnValidationByIndex(index)}
                                                        onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => onChangeTelecomValue(index, e)}


                        />
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
            {showBin
                &&
                <IDSContainer>

                    <IDSRow align='end'>
                        <IDSCol>
                        </IDSCol>
                        <IDSCol>
                            <Bin remove={removeTelecom} index={index}/>
                        </IDSCol>
                    </IDSRow>

                </IDSContainer>

            }
        </div>
    );
}
export default Telecom;