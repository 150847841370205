import React from "react";
import * as XLSX from 'xlsx';
import {nowDateYearMonthDay} from "../../components/common/CommonFunctions";
import { saveAs } from "file-saver";

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
const fileExtension = ".xlsx";
//".xlsx";

export const downloadExcel = async (exportObjects, systemNameAndVersion_or_lastSearchPhrase) => {

    const ws = XLSX.utils.json_to_sheet(exportObjects);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, systemNameAndVersion_or_lastSearchPhrase + "-" + nowDateYearMonthDay() + fileExtension);
};