import React, {useEffect, useState, useCallback, useRef} from 'react';
import {getRenderCountText} from '../../components/common/RenderCount';
import {useLocation, useParams} from 'react-router-dom';
import {
    compose,
    defaultCodeSystemForValueSet,
    removeComposeFormDefault,
    removeDefaultValueSetFormValues
} from "../../model/defaultvalues/ValueSetDefaultFormValue";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {SubmitHandler, FormProvider, useForm, UseFormProps, UseFormReturn} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DevTool} from "@hookform/devtools";
import {IDSCol, IDSContainer, IDSRow, IDSTab, IDSTabPanel, IDSTabs, IDSButton} from "@inera/ids-react";
import NamePurpose from "../../components/artifact/meta/NamePurpose";
import IdentifierArray from "../../components/artifact/meta/IdentifierArray";
import VersionInformation from "../../components/artifact/versioninfo/VersionInformation";
import Client from 'fhir-kit-client';
import Compose from "../../components/artifact/valueset/read/Compose";
import History from "../../components/artifact/meta/readonly/History";
import RelatedArtifacts from "../../components/artifact/meta/readonly/RelatedArtifacts";
import {codesystem, valueset, fhirUrl} from "../../model/defaultvalues/Constant";
import {retrieveVersionHistory, retrieveVersionHistoryOnSystemName} from "../../services/ArtifactCommonClient";
import {ArtifactHeader} from "../../components/common/page/header/ArtifactHeader";
import VSDefineDataCodeSystem from "../../components/artifact/valueset/VSDefineDataCodeSystem";
import {
    addConceptToValueSet,
    removeCodeSystem,
    removeConceptFromValueSet
} from "../../components/artifact/valueset/update/VSCrudFunctions";
import {
    nowDateYearMonthDay
} from "../../components/common/CommonFunctions";
import {IValueSet, IBundleEntry, ICodeSystem} from "fhir-typescript-models";
import {IValueSetComposeInclude} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {schema} from "../../components/common/validation/ValidationInternalMetaDataYupErrorRules";
import {
    translateIdentifierFromUrn,
    translateIdentifierToUrn
} from "../../components/artifact/meta/IdentifierTranslator";
import {ACCESS_TOKEN} from "../../constants";
import {IRHookFormValidationMessage} from "../../components/common/rhookform/IRHookFormValidationMessage";
import {string} from "yup";
import {handleMessageList, removeValidationMessagesOnObjPath} from "../../components/common/validation/MetaValidationRules";
import {
    contactListEmpty, emptyIndentifierFormValues, emptyIndentifierValues,
    identifierList
} from "../../model/defaultvalues/MetaDataDefaultFormValue";
import {isLocked, permissionToEdit} from "../../user/PermissionUtil";
import {ValidationHeader} from "../../components/common/page/header/ValidationHeader";
import {
    handleIfValidationExternalNotificationsAllTypes
} from "../../components/common/validation/external/ValidationExternalNotificationService";
import CreateNewVersionDialog from "../../components/common/page/create/CreateNewVersionDialog";
import {updateResponseErrorHandling} from "../ArtifactPageCommons";
import {externallyValidateCreatedInfoNotifications, externallyValidateMetaData} from "../../services/ValidationService";
import ITabName from "../../components/common/page/ITabName";
import {collectNotices} from "../../components/common/page/TabName";
import {
    formatIncludeArrayToSaveIfAllCodesSelected,
    getIncludeArrayWithoutEmptyConceptArray,
    isIncludeConceptsInFormSameAsSaved
} from "../../components/artifact/valueset/IncludeUtil";
import {SearchComposeObj} from "../../components/artifact/valueset/SearchComposeObj";
import {composeIncludeObjPath} from "../../components/artifact/valueset/VSConstant";
import {addTTExtensionsToForm, extensionList} from "../../model/defaultvalues/MetaDataExtensionDefaultFormValue";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            minHeight: '100vh',
        },
    })
);

type TParams = { id: string, version: string };
const fhirClient = new Client({baseUrl: fhirUrl});
let renderCount = 0;


function ValueSetReadUpdate() {

    const classes = useStyles();

    const [editDisabled, setEditDisabled] = useState(true);
    const [editModeActivated, setEditModeActivated] = useState(false);

    //Read only
    const [foundHistory, setFoundHistory] = useState([]);
    const [relatedArtifactsArray, setRelatedArtifactsArray] = useState<IBundleEntry[]>([]);

    //Edit mode
    const [includesToAdd, setIncludesToAdd] = useState<IValueSetComposeInclude[]>([]);
    //All codes in codeSystem - selected concepts has variable "selected"
    const [codeSystemsWithAllCodesSelectableCodes, setCodeSystemsWithAllCodesSelectableCodes] = useState([defaultCodeSystemForValueSet]);
    const [isRequesting, setIsRequesting] = useState(false);
    const [initialVersion, setInitialVersion] = useState<string>();
    const [initialStatus, setInitialStatus] = useState<string>();
    const [activateSaveSuccessNotice, setActivateSaveSuccessNotice] = useState(false);
    const [activateSaveErrorNotice, setActivateSaveErrorNotice] = useState(false);
    const [isFromDraftToActiveSave, setIsFromDraftToActiveSave] = useState(false);
    const [isFromActiveToRetiredSave, setIsFromActiveToRetiredSave] = useState(false);

    const [errorMessageList, setErrorMessageList] = useState<IRHookFormValidationMessage[]>([]);
    const [warnMessageList, setWarnMessageList] = useState<IRHookFormValidationMessage[]>([]);
    const [infoMessageList, setInfoMessageList] = useState<IRHookFormValidationMessage[]>([]);

    const createNewVersionButtonRef = useRef("create-new-version-button");
    const [showCreateNewVersionDialog, setShowCreateNewVersionDialog] = useState(false);

    const [metaDataNotices, setMetaDataNotices] = useState(0);
    const [contentDataNotices, setContentDataNotices] = useState(0);
    const [publishDataNotices, setPublishDataNotices] = useState(0);

    const handleErrorMessageList = (message: string, label: string, tabName: ITabName, index?: string, path?: string) => {
        handleMessageList(message, label, errorMessageList, setErrorMessageList, tabName, index, path);
    };


    const handleWarnMessageList = (message: string, label: string, tabName: ITabName, index?: string, path?: string) => {
        handleMessageList(message, label, warnMessageList, setWarnMessageList, tabName, index, path);
    };

    const handleInfoMessageList = (message: string, label: string, tabName: ITabName) => {
        handleMessageList(message, label, infoMessageList, setInfoMessageList, tabName);
    };

    const removeValidationMessagesOnPath = (path: string) => {
        removeValidationMessagesOnObjPath(path, setErrorMessageList);
        removeValidationMessagesOnObjPath(path, setWarnMessageList);
    };

    const params = useParams();
    const search = useLocation().search;
    const editValue = new URLSearchParams(search).get('edit');
    const createdValue = new URLSearchParams(search).get('created');

    const methods: UseFormReturn<IValueSet, UseFormProps> = useForm<IValueSet>({
        defaultValues: {
            id: '',
            identifier: identifierList,
            //   concept: [{code: '', display: '', definition: '', designation: [{use: {system:"exampleSystem"}}]}],
            compose: compose,
            contact: contactListEmpty,
            extension: extensionList,
            resourceType: valueset,
            copyright: "",
            version: "",
            status: ""
        },

        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        mode: "onChange"
    });

    const fetchRelatedArtifactsArray = async (paramsArray: any[], hasEmptyComposeCodeSystemArray: SearchComposeObj[]) => {
        var requestResultPerCodeSystem: any[] = []
        await searchAndCollectCodeSystem(paramsArray, hasEmptyComposeCodeSystemArray, requestResultPerCodeSystem);

        if (relatedArtifactsArray.length == 0) {
            setRelatedArtifactsArray(requestResultPerCodeSystem);
        } else {
            var newRelatedArtifactsArray = [...relatedArtifactsArray]; // make a separate copy of the array
            //only add one at a time after fist get of the whole saved ValueSet
            newRelatedArtifactsArray.push(requestResultPerCodeSystem[0])
            setRelatedArtifactsArray(newRelatedArtifactsArray);
        }

    }

    const searchAndCollectCodeSystem = async (paramsArray: any[], hasEmptyComposeCodeSystemArray: SearchComposeObj[], requestResultPerCodeSystem: any[]) => {
        if (paramsArray.length !== 0) {
            for (let i = 0; i < paramsArray.length; i++) {
                let param = paramsArray[i]
                await fhirClient
                    .search({resourceType: codesystem, searchParams: param})
                    .then((data) => {
                        if (data.entry !== undefined && data.entry !== null) {
                            let result = data.entry[0];
                            hasEmptyComposeCodeSystemArray[i].id = result.resource.id;
                            requestResultPerCodeSystem.push(result)
                        }
                    }).catch(e => {
                        console.error(e);
                    });
            }
        }
    }


    const searchCodeSystemCodesIfComposeIsEmpty = async (hasEmptyComposeCodeSystemArray: SearchComposeObj[], valueSet: any) => {
        var requestResultPerCodeSystem: any[] = []
        if (hasEmptyComposeCodeSystemArray.length !== 0) {

            for (let i = 0; i < hasEmptyComposeCodeSystemArray.length; i++) {
                let searchComposeObj = hasEmptyComposeCodeSystemArray[i]

                if (searchComposeObj.hasEmptyConcept) {
                    await fhirClient.read({
                        resourceType: "CodeSystem",
                        id: searchComposeObj.id
                    })
                        .then((data) => {
                            valueSet.compose.include[i].concept = data.concept;
                        }).catch(e => {
                            console.error(e);
                        });
                }
            }
        }
    }

    const resetAsyncForm = useCallback(async () => {
        var paramsPerCodeSystemArray: any[] = []
        var hasEmptyComposeCodeSystemArray: SearchComposeObj[] = []
        // @ts-ignore
        let result = await fhirClient.read({resourceType: valueset, id: params.id})
            .then((result) => {
                addTTExtensionsToForm(result);
                translateIdentifierFromUrn(result, editDisabled);
                return result;
            })
            .then((result) => {
                setInitialVersion(result.version);
                retrieveVersionHistory(result, valueset, setFoundHistory);
                return result;
            })
            .then((result) => {
                if (result.compose !== undefined && result.compose.include !== undefined) {
                    const includeArray = result.compose.include;
                    collectParamsForRelatedArtefactsSearch(includeArray, paramsPerCodeSystemArray, hasEmptyComposeCodeSystemArray);
                }
                return result;
            })
            .catch(e => {
                // @ts-ignore
                updateResponseErrorHandling(e, setIsRequesting, methods);
            });

        await fetchRelatedArtifactsArray(paramsPerCodeSystemArray, hasEmptyComposeCodeSystemArray);
        if (JSON.stringify(hasEmptyComposeCodeSystemArray).includes("true")) {
            await searchCodeSystemCodesIfComposeIsEmpty(hasEmptyComposeCodeSystemArray, result)
        }

        if (result !== undefined) {

            // @ts-ignore
            if (result.compose !== undefined && result.compose.include !== undefined) {
                // @ts-ignore
                loadIncludesToAdd(result.compose.include);
            }
            // @ts-ignore
            methods.reset(result); // asynchronously reset your form values

            // @ts-ignore
            setInitialStatus(result.status);
            // @ts-ignore
            if (permissionToEdit(methods) == true && editValue === "true" && result.status == "draft") {
                setEditDisabled(false);
                setEditModeActivated(true);
            }
            // @ts-ignore
            if (permissionToEdit(methods) == true && createdValue === "true" && result.status == "draft") {
                externallyValidateCreatedInfoNotifications(methods.getValues(), infoMessageList,
                    handleInfoMessageList, methods, false, valueset);
            }
        }

    }, [methods.reset]);

    const collectParamsForRelatedArtefactsSearch = (includeArray: any[], paramsPerCodeSystemArray: any[], hasEmptyComposeCodeSystemArray: SearchComposeObj[]) => {
        for (let i = 0; i < includeArray.length; i++) {
            let hasEmptyConcept: boolean = includeArray[i].concept == null || includeArray[i].concept === undefined ? true : false;
            var searchComposeObj: SearchComposeObj = {
                id: "",
                hasEmptyConcept: hasEmptyConcept
            }
            hasEmptyComposeCodeSystemArray.push(searchComposeObj);
            collectParamsForOneRelatedArtefactsSearch(includeArray[i].system, includeArray[i].version, paramsPerCodeSystemArray);
        }
    };

    const collectParamsForOneRelatedArtefactsSearch = (system: string, version: string, paramsPerCodeSystemArray: any[]) => {
        var params: any = {};
        params["url"] = system;

        if (version !== undefined) {
            params["version"] = version;
        }

        params["_elements"] = "title,version,status,date,url";
        params["_sort"] = "-version,-_lastUpdated";
        paramsPerCodeSystemArray.push(params);
    };


    useEffect(() => {
        resetAsyncForm()
    }, [resetAsyncForm])

    useEffect(() => {
        collectNotices(infoMessageList, warnMessageList, errorMessageList, setMetaDataNotices, setContentDataNotices, setPublishDataNotices);
    }, [errorMessageList, warnMessageList, infoMessageList])

    const loadIncludesToAdd = (include: IValueSetComposeInclude[]) => {
        for (let i = 0; i < include.length; i++) {
            // @ts-ignore
            include[i].tabindex = i;
        }
        setIncludesToAdd(include);
    };

    const submitValueSet: SubmitHandler<IValueSet> = async (data: IValueSet) => {
        if (data !== undefined) {
            setIsRequesting(true);
            data.date = nowDateYearMonthDay();

            let copyOfOriginalIncludesToAdd: IValueSetComposeInclude[] = [];
            let copyOfOriginalIncludesToSave: IValueSetComposeInclude[] = [];
            if(data.status !== "retired") {

                copyOfOriginalIncludesToAdd = JSON.parse(JSON.stringify(includesToAdd));
                let includesToSave: IValueSetComposeInclude[] = getIncludeArrayWithoutEmptyConceptArray(includesToAdd);
                copyOfOriginalIncludesToSave = JSON.parse(JSON.stringify(includesToSave));
                formatIncludeArrayToSaveIfAllCodesSelected(includesToSave, codeSystemsWithAllCodesSelectableCodes);

                if (includesToSave.length !== 0) {
                    // @ts-ignore
                    data.compose = {include: includesToSave};
                    removeComposeFormDefault(data);
                } else {
                    data.compose = undefined;
                }
            }
            data = removeDefaultValueSetFormValues(data);
            translateIdentifierToUrn(data);

            fhirClient.update({
                resourceType: valueset,
                id: params.id,
                // @ts-ignore
                body: data,
                options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}
            }).then(response => {
                translateIdentifierFromUrn(data, editDisabled);
                addTTExtensionsToForm(data)

                if(data.status === "draft") {
                    //reset includesToSave incase any include has empty concept(means that all the codesystems codes are selected, so reload all the codes)
                    data.compose = {include: copyOfOriginalIncludesToSave};
                }
                removeComposeFormDefault(data);

                methods.reset(data);
                if(data.status === "draft"){
                setIncludesToAdd(copyOfOriginalIncludesToAdd);
                }
                // @ts-ignore
                setInitialVersion(methods.getValues("version"));
                //    methods.reset(response);
                retrieveVersionHistoryOnSystemName(methods.getValues("name"), valueset, setFoundHistory);
                setIsRequesting(false);
                if (initialStatus !== data.status) {
                    if (initialStatus === "draft" && data.status === "active") {
                        setIsFromDraftToActiveSave(true);
                    } else if (initialStatus === "active" && data.status === "retired") {
                        setIsFromActiveToRetiredSave(true);
                    }
                    setInitialStatus(data.status);
                    retrieveVersionHistory(data, codesystem, setFoundHistory);
                }
                setActivateSaveSuccessNotice(true);

                handleIfValidationExternalNotificationsAllTypes(
                    // @ts-ignore
                    response, methods,
                    infoMessageList, handleInfoMessageList,
                    warnMessageList, handleWarnMessageList,
                    errorMessageList, handleErrorMessageList
                )

            })
                .catch(e => {
                    setIsRequesting(false);
                    translateIdentifierFromUrn(data, editDisabled);
                    if (initialStatus !== data.status) {
                        // @ts-ignore
                        methods.setValue("status", initialStatus);
                    }

                    console.error("FhirClient Update Error Response" + JSON.stringify(e));
                    if (e.response !== undefined && e.response.status == 400 && e.response.data !== undefined) {
                        handleIfValidationExternalNotificationsAllTypes(
                            e.response.data, methods,
                            infoMessageList, handleInfoMessageList,
                            warnMessageList, handleWarnMessageList,
                            errorMessageList, handleErrorMessageList
                        )
                    } else {
                        setActivateSaveErrorNotice(true);
                        console.error("FhirClient Update Error Response" + JSON.stringify(e));
                    }
                });
        }
    };

    const addConcept = async (concept: any, system: string, version: string, e: any, tabindex: number, id: string) => {
        await addConceptToValueSet(concept, system, version, tabindex, e, includesToAdd, setIncludesToAdd, id);
        //TODO add/synk realtedArtefacts
        //  await addRelatedArtefactIfNotInCollection(system, version);
    };

    const removeConceptToValueSet = (concept: any, system: string, version: string) => {
        removeConceptFromValueSet(concept, system, version, includesToAdd, setIncludesToAdd);
    }

    const removeCodeSystemFromValueSet = (tabindex: number, id: string, system: string, version: string) => {
        removeCodeSystem(id, system, version, tabindex, includesToAdd, setIncludesToAdd, relatedArtifactsArray, setRelatedArtifactsArray, methods);
    }

    const createNewVersion = () => {
        setShowCreateNewVersionDialog(true);
        setEditDisabled(true);
        // @ts-ignore
        createNewVersionButtonRef.current.click();
    };

    const validateCodes = () => {
        let hasDraftOrRetiredCodeSystem: boolean = false;
        for (let codeSystem of codeSystemsWithAllCodesSelectableCodes) {
            // @ts-ignore
            if (codeSystem.status !== "active") {
                hasDraftOrRetiredCodeSystem = true;
            }
        }

        let hasNoComposeIncludes: boolean = false;
        if (includesToAdd.length == 0) {
            hasNoComposeIncludes = true;
        }

        return hasDraftOrRetiredCodeSystem || hasNoComposeIncludes ? true : false;
    };

    const isIncludesInFormDirty = () => {
        let sameAssaved: boolean = isIncludesInFormSameAsSaved();
        return !sameAssaved;
    }

    const isIncludesInFormSameAsSaved = (): boolean => {

        let data = methods.getValues();
        // @ts-ignore
        let isSavedComposeIncludeEmpty = data.compose === undefined || data.compose.include === undefined;
        let isincludesToAddEmpty = includesToAdd.length == 0;

        if (isSavedComposeIncludeEmpty && isincludesToAddEmpty) {
            return true;
        } else if (isSavedComposeIncludeEmpty != isincludesToAddEmpty) {
            return false;
        } else {
            // @ts-ignore
            return isIncludeConceptsInFormSameAsSaved(data.compose.include, includesToAdd, codeSystemsWithAllCodesSelectableCodes);
        }
    }

    const handleEditDisabled = (editDisableStatus: boolean) => {
        setEditDisabled(editDisableStatus);
        if (editDisableStatus == false) {
            validateArtifact(editDisableStatus);
            // @ts-ignore
            if (JSON.stringify(methods.getValues("identifier")) === JSON.stringify([emptyIndentifierValues])) {
                // @ts-ignore
                methods.setValue("identifier", [emptyIndentifierFormValues])
            }
        }
    };

    const validateArtifact = (editDisableStatus: boolean) => {
        externallyValidateMetaData(methods.getValues(), infoMessageList, handleInfoMessageList,
            warnMessageList, handleWarnMessageList,
            errorMessageList, handleErrorMessageList, methods, setIsRequesting, editDisableStatus, valueset);
    }

    /*    const testMethod = () => {
           // debugger;
            console.log("testMethod");
        }*/

    renderCount++;

    return (
        <div>
            <CreateNewVersionDialog createButtonRef={createNewVersionButtonRef} artifactType={valueset}
                                    showCreateDialog={showCreateNewVersionDialog}
                                    setShowCreateDialog={setShowCreateNewVersionDialog}
                                    data={methods.getValues()}/>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submitValueSet)}>
                    <ArtifactHeader editDisabled={editDisabled} setEditDisabled={handleEditDisabled}
                                    artifactType={valueset} isRequesting={isRequesting}
                                    updateDisabled={editDisabled || errorMessageList.length !== 0}
                                    createNewVersion={createNewVersion}
                                    editModeActivated={editModeActivated}
                                    setEditModeActivated={setEditModeActivated}
                                    isCodesBelongingToFormDirty={isIncludesInFormDirty}
                    />
                    <ValidationHeader editDisabled={editDisabled}
                                      infoMessageList={infoMessageList}
                                      warnMessageList={warnMessageList}
                                      errorMessageList={errorMessageList}
                                      activateSaveSuccessNotice={activateSaveSuccessNotice}
                                      setActivateSaveSuccessNotice={setActivateSaveSuccessNotice}
                                      activateSaveErrorNotice={activateSaveErrorNotice}
                                      setActivateSaveErrorNotice={setActivateSaveErrorNotice}
                                      artefactType={valueset}
                                      isFromDraftToActiveSave={isFromDraftToActiveSave}
                                      setIsFromDraftToActiveSave={setIsFromDraftToActiveSave}
                                      isFromActiveToRetiredSave={isFromActiveToRetiredSave}
                                      setIsFromActiveToRetiredSave={setIsFromActiveToRetiredSave}
                    />

                    <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                {/*                   <IDSButton size='m' secondary={true} onClick={() => {
                                    testMethod();
                                }}>
                                    TESTKNAPP
                                </IDSButton>*/}
                                <IDSTabs>
                                    <IDSTab label="Metadata"
                                            notifications={editDisabled ? 0 : metaDataNotices}></IDSTab>
                                    <IDSTab label="Innehåll"
                                            notifications={editDisabled ? 0 : contentDataNotices}></IDSTab>
                                    <IDSTab label="Versionsinformation"
                                            notifications={editDisabled ? 0 : publishDataNotices}></IDSTab>
                                    <IDSTab label="Versionshistorik"></IDSTab>
                                    <IDSTab label="Kopplade artefakter"></IDSTab>

                                    <IDSTabPanel>
                                        <fieldset disabled={isLocked(editDisabled, methods)}>
                                            <NamePurpose artifactType={valueset} editDisabled={editDisabled}
                                                         handleWarnMessageList={handleWarnMessageList}
                                                         handleInfoMessageList={handleInfoMessageList}
                                                         handleErrorMessageList={handleErrorMessageList}
                                                         removeValidationMessagesOnPath={removeValidationMessagesOnPath}
                                            />
                                            <IdentifierArray editDisabled={editDisabled}
                                                             handleWarnMessageList={handleWarnMessageList}
                                                             handleErrorMessageList={handleErrorMessageList}/>

                                        </fieldset>
                                    </IDSTabPanel>

                                    <IDSTabPanel>
                                        {isLocked(editDisabled, methods) ? (
                                            <Compose artifactArray={relatedArtifactsArray}/>
                                        ) : (
                                            <VSDefineDataCodeSystem artifactArray={relatedArtifactsArray}
                                                                    conceptsToAdd={includesToAdd}
                                                                    setConceptsToAdd={setIncludesToAdd}
                                                                    addConcept={addConcept}
                                                                    removeConceptToValueSet={removeConceptToValueSet}
                                                                    removeCodeSystemFromValueSet={removeCodeSystemFromValueSet}
                                                                    codeSystemsWithAllCodesSelectableCodes={codeSystemsWithAllCodesSelectableCodes}
                                                                    setCodeSystemsWithAllCodesSelectableCodes={setCodeSystemsWithAllCodesSelectableCodes}
                                            />
                                        )}
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <VersionInformation artifactType={valueset}
                                                            isLocked={isLocked(editDisabled, methods)}
                                                            editDisabled={editDisabled}
                                                            errorMessageList={errorMessageList}
                                                            handleErrorMessageList={handleErrorMessageList}
                                                            warnMessageList={warnMessageList}
                                                            handleWarnMessageList={handleWarnMessageList}
                                                            hasError={errorMessageList.length !== 0}
                                                            hasWarning={warnMessageList.length !== 0}
                                                            submitArtefact={submitValueSet}
                                                            validateCodes={validateCodes}/>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <History history={foundHistory}
                                            // @ts-ignore
                                                 id={params.id}
                                                 type={valueset}
                                            // @ts-ignore
                                                 version={initialVersion}/>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <RelatedArtifacts artifactArray={relatedArtifactsArray}
                                                          type={codesystem}
                                                          title={"Urvalet består av koder från:"}/>
                                    </IDSTabPanel>
                                </IDSTabs>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                </form>
                {/*    <DevTool control={methods.control}/>  set up the dev tool */}
            </FormProvider>
        </div>
    );
};

export default ValueSetReadUpdate;
