import React, {FC, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {SubmitHandler, FormProvider, useForm, UseFormProps, UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import {yupResolver} from "@hookform/resolvers/yup";
import SaveButton from "../button/SaveButton";
import Url from "../../../artifact/meta/Url";
import {schema} from "../../validation/ValidationInternalMetaDataYupErrorRules";
import {ITTArtifactBase} from "./ITTArtifactBase";
import {removeDefaultCodeSystemFormValues} from "../../../../model/defaultvalues/CodeSystemDefaultFormValue";
import {ACCESS_TOKEN} from "../../../../constants";
import Client from "fhir-kit-client";
import {extensionVersionDescriptionCodeSystemUrl, fhirUrl} from "../../../../model/defaultvalues/Constant";
import {useNavigate} from "react-router-dom";
import {ICodeSystem} from "fhir-typescript-models";
import {nowDateYearMonthDay} from "../../CommonFunctions";
import {translateIdentifierToUrn} from "../../../artifact/meta/IdentifierTranslator";
import {artifactUrl} from "../../../../model/defaultvalues/MetaDataDefaultFormValue";
import Name from "../../../artifact/meta/Name";
import {handleIfValidationNotifications} from "../../validation/external/ValidationExternalNotificationService";
import RHookFormTextFieldSpan from "../../rhookform/RHookFormTextFieldSpan";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            minHeight: '100vh',
        },
    })
);


let renderCount = 0;

interface CreateDialogFormProps {
    artifactType: string,
    closeDialog: any,
    resetDialog: boolean
}

const fhirClient = new Client({baseUrl: fhirUrl});


export const CreateDialogForm: FC<CreateDialogFormProps> = ({
                                                                artifactType,
                                                                closeDialog,
                                                                resetDialog
                                                            }) => {


    const [isRequesting, setIsRequesting] = useState(false);

    const emptyForm = {
        title: "",
        name: "",
        url: artifactUrl,
        status: 'draft',
        resourceType: artifactType,
    }

    const methods: UseFormReturn<ITTArtifactBase, UseFormProps> = useForm<ITTArtifactBase>({
        defaultValues: emptyForm,
        resolver: yupResolver(schema),
    });

    if (resetDialog) {
        methods.reset(emptyForm)
    }

    const navigate = useNavigate();

    const submitCodeSystem: SubmitHandler<ICodeSystem> = async (data: ICodeSystem) => {

        if (data !== undefined) {
            setIsRequesting(true);

            data.date = nowDateYearMonthDay();
            data = removeDefaultCodeSystemFormValues(data);
            translateIdentifierToUrn(data);

            // @ts-ignore
            /*  data.meta.profile = ["https://example.org/fhir/StructureDefinition/profile/IneraCodeSystem"];*/

            const headers = new Headers({
                'Content-Type': 'application/json',
            })
            if (localStorage.getItem(ACCESS_TOKEN)) {
                headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
            }

            fhirClient.create({
                resourceType: artifactType,
                // @ts-ignore
                body: data,
                options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}},
            }).then(response => {
                closeDialog();
                methods.reset(emptyForm)
                setIsRequesting(false);
                navigate('/' + artifactType + '/' + response.id + "?created=true&edit=true", {replace: true});
                window.location.reload();
            })
                .catch(e => {
                    setIsRequesting(false);
                    console.error("FhirClient Create Error Response" + JSON.stringify(e));
                    if (e.response.status == 400) {
                        handleIfValidationNotifications("ERROR", e.response.data, methods);
                    }
                });
        }
    };

    renderCount++;

    return (
        <div style={{width: "100%"}}>
            <IDSContainer gutterless={true} style={{width: "100%"}}>
                <IDSRow justify="space-between">
                    <IDSCol>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(submitCodeSystem)}>
                                <IDSContainer gutterless={true} style={{width: "100%"}}>

                                    <IDSRow justify="space-between">
                                        <IDSCol cols="6">
                                            <RHookFormTextFieldSpan label="Titel (obligatorisk)" name="title"
                                                                    disabled={false}/>
                                        </IDSCol>
                                    </IDSRow>
                                    <IDSRow justify="space-between">
                                        <IDSCol cols="6">
                                            <Name artifactType={artifactType} disabled={false}/>
                                        </IDSCol>
                                    </IDSRow>
                                    <IDSRow justify="space-between">
                                        <IDSCol cols="6">
                                            <Url/>
                                        </IDSCol>
                                    </IDSRow>
                                </IDSContainer>
                                <SaveButton isRequesting={isRequesting}/>
                            </form>
                            {/*   <DevTool control={methods.control}/>  set up the dev tool */}
                        </FormProvider>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default CreateDialogForm;