import React, {FC, useEffect, useState} from 'react';
import {IDSAlert} from "@inera/ids-react";


interface SearchNotificationProps {
    searchErrorNotice?: boolean,
    setSearchErrorNotice?: any,
    idsAlertType?: string
}


export const SearchNotification: FC<SearchNotificationProps> = ({
                                                                    searchErrorNotice,
                                                                    setSearchErrorNotice,
                                                                    idsAlertType
                                                                }) => {


    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchErrorNotice(false);
        }, 3000);

        // To clear or cancel a timer, you call the clearTimeout(); method,
        // passing in the timer object that you created into clearTimeout().

        return () => clearTimeout(timer);
    }, []);

    const getSricontitle = () => {
        if (idsAlertType === "success") {
            return "success icon";
        } else if (idsAlertType === "error") {
            return "error icon";
        }
    };

    const getNoticeMessage = () => {
        if (idsAlertType === "success") {
            return "Sökningen är utförd";
        } else if (idsAlertType === "error") {
            return "Sökningen påträffade fel";
        }
    };

    return (
        <div>
            {(searchErrorNotice !== undefined && searchErrorNotice == true) &&
                // @ts-ignore
                <IDSAlert sricontitle={getSricontitle()} type={idsAlertType}>
                    {getNoticeMessage()}
                </IDSAlert>
            }
        </div>
    );
};
