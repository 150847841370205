import React from "react";
import {blue} from "@mui/material/colors";
import {createGlobalStyle} from 'styled-components'


export const getSwedishStatusText = (statusInEnglish) => {

    if (statusInEnglish === "active") {
        return <span style={statusActiveStyle}>Aktiv</span>;
    } else if (statusInEnglish === "draft") {
        return <span style={statusDraftStyle}>Utkast</span>;
    } else if (statusInEnglish === "retired") {
        return "Avställd"
        /*    } else if (statusInEnglish === "unknown") {
                return "Okänd"*/
    } else {
        return statusInEnglish;
    }
};


export const sameRowStyle = {
    whiteSpace: 'nowrap',
    display: 'inline',
}

export const errorStyle = {fontStyle: "bold", color: "red"};
export const warnStyle = {fontStyle: "bold", color: "orange"};

export const infoStyle = {fontStyle: "bold", color: "green"};
export const whiteBgColor = {backgroundColor: "white", borderColor: "white"};
export const grayBorder = {borderColor: "coral"};
export const infoItemStyle = {fontStyle: "normal"};


export const statusActiveStyle = {fontStyle: "bold", color: "green"};
export const versionCurrentStyle = {color: "gray"};
export const statusDraftStyle = {fontStyle: "bold", color: "orange"};
export const codeSystemLinkColumnStyle = {alignSelf: "end", justifySelf: "end"};
export const codeSystemBadgeColumnStyle = {justifySelf: "end", alignSelf: "end"};
export const columnWidth5 = {width: "5%"};
export const columnWidth10 = {width: "10%"};
export const columnWidth15 = {width: "15%"};
export const columnWidth20 = {width: "20%"};
export const columnWidth25 = {width: "25%"};
export const columnWidth30 = {width: "30%"};
export const columnWidth35 = {width: "35%"};
export const columnWidth50 = {width: "50%"};
export const truncateText = {whitespace: "nowrap", textoverflow: "ellipsis", overflow: "hidden", maxwidth: "0"};
export const truncateTexts = {textoverflow: "ellipsis", overflow: "100%", maxwidth: "0"};
export const valuesetChoosenCodeTableHeaderRow = {backgroundColor: "#e7f7ea"};
export const conceptMapSourceTableHeaderColumn = {backgroundColor: "#e7f7ea", width: "50%"};
export const conceptMapSourceTableHeaderColumn20Width = {backgroundColor: "#e7f7ea", width: "20%"};
export const conceptMapSourceTableHeaderColumn30Width = {backgroundColor: "#e7f7ea", width: "30%"};

export const muiSelectCheckboxPropsStyle = {
    /*       backgroundColor: pink[800],
          bgcolor: pink[800],*/
    /*        "& .MuiSvgIcon-root": {
                fontSize: '1rem'
            },*/


    /*       backgroundColor: pink[800],
          bgcolor: pink[800],*/
    /*         "& .MuiSvgIcon-root": {
                border: `1px dashed ${blue[500]}`,
  /!*               borderTop:'0.0625rem',
                 borderRight:'0.0625rem',
                 borderBottom:'0.0625rem',*!/
                },*/
    color: '#40775e',
    borderWidth: '1px',
    /*   border: '1px solid',*/
    '&.Mui-checked': {
        color: blue[600],
    },

}
//border: "none",
export const textFieldStyle = {
    borderColor: "#f0f0f0",
    backgroundColor: "transparent",
    width: '100%',
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "3px"
};

export const muiTablePropsTwoColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(2)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}


export const muiTablePropsFourColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(4)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTablePropsSixColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(6)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTablePropsFiveColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(5)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTableRowPropsStyle = {
    verticalAlign: 'top'
}

export const muiTableHeadRowPropsStyle = {
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    /*   borderRadius: "5px",*/
    backgroundColor: '#f6f1e9',
    /*        borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'*/
}

export const muiTableHeadRowErrorPropsStyle = {
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    /*   borderRadius: "5px",*/
    backgroundColor: '#f9dbd8',
    /*        borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'*/
}

export const muiTableBodyPropsStyle = {
    /*        //stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(odd)': {
                backgroundColor: '#f5f5f5',
            },*/
}


export const muiTableBodyCellPropsStyle = {
    verticalAlign: 'top'
}

export const displayLinebreak = {
    whiteSpace: 'pre-line'
}

export const GlobalStyled = createGlobalStyle`
  body:not(.user-is-tabbing) button:focus {
    outline: none;
  }
`;