export const baseurl =  '';
export const fhirUrl =  baseurl + '/fhir';
export const codesystem: string = "CodeSystem";
export const valueset: string = "ValueSet";
export const conceptmap: string = "ConceptMap";

export const notmatched: string = "unmatched";

export const urnOID: string = "urn:oid:";
export const urnUUID: string = "urn:uuid:";

//https://www.ietf.org/rfc/rfc3986.txt
export const uniformResourceIdentifier: string = "urn:ietf:rfc:3986";

export const extension: string = "extension";
export const extensionAuthor: string = "ExtensionAuthor";

const ttTagSystem = "http://terminologitjansten.inera.se/"

export const tagSectorSystemUrl: string = "fhir/CodeSystem/terminologyservice-sector-tags";

export const tagTerminologyServiceBaseSystemUrl: string = ttTagSystem + "fhir/CodeSystem/terminologyservice-base-tags";

export const TAG_SECTOR_SYSTEM_URL = ttTagSystem + tagSectorSystemUrl;
export const tagTerminologyServiceBaseSystemCode: string = "SYSTEM_ADMIN";


//Extension urls
export const extensionAuthorCodeSystemUrlOld: string = "http://example.se/fhir/StructureDefinition/author";
export const extensionAuthorCodeSystemUrl: string = ttTagSystem + "StructureDefinition/author";
export const extensionVersionDescriptionCodeSystemUrlOld: string = "http://example.se/fhir/StructureDefinition/version-description";
export const extensionVersionDescriptionCodeSystemUrl: string = ttTagSystem + "fhir/StructureDefinition/version-description";
export const extensionRetireReasonSystemUrl: string = ttTagSystem + "fhir/StructureDefinition/retire-reason";
export const  swedishICD10_name =  "ICD-10-SE";
export const  swedishICD10_83_name =  "ICD-10-MINI-S83";
export const  swedishICD10_miniexample =  "icd-10-se-miniexample";
export const  SNOMED_CT_URL =  "http://snomed.info/sct";
export const  SNOMED_CT_VERSION =  "http://snomed.info/sct/45991000052106/version/20240531";
export const  SNOMED_CT_ID =  "45991000052106-20240531";


