import {ConceptMapTableRowObj} from "../../model/ConceptMapTableRowObj";
import {ICodeSystem} from "fhir-typescript-models";


export const translateCodeSystemToTargetTableData = (data: ICodeSystem, setTargetUrl: any) => {

    var array: ConceptMapTableRowObj[] = [];

    if (setTargetUrl !== undefined) {
        setTargetUrl(data.url);
    }

    if (data.concept !== undefined) {
        for (let i = 0; i < data.concept.length; i++) {
            var concept = data.concept[i];
            // @ts-ignore
            var obj: ConceptMapTableRowObj = {
                target: concept.code,
                targetdisplay: concept.display
            }
            array.push(obj);
        }
    }
    return array;
}
