import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {removeDefaultConceptMapFormValues} from "../../model/defaultvalues/ConceptMapDefaultFormValue";
import {SubmitHandler, FormProvider, useForm, UseFormProps, UseFormReturn} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DevTool} from "@hookform/devtools";
import {IDSCol, IDSContainer, IDSRow, IDSTab, IDSTabPanel, IDSTabs, IDSButton} from "@inera/ids-react";
import NamePurpose from "../../components/artifact/meta/NamePurpose";
import VersionInformation from "../../components/artifact/versioninfo/VersionInformation";
import Client from 'fhir-kit-client'
import {fhirUrl} from "../../model/defaultvalues/Constant";
import History from "../../components/artifact/meta/readonly/History";
import GroupReadOnly from "../../components/artifact/conceptmap/read/GroupReadOnly";
import RelatedArtifacts from "../../components/artifact/meta/readonly/RelatedArtifacts";
import {codesystem, conceptmap, valueset} from "../../model/defaultvalues/Constant";
import {retrieveVersionHistory, retrieveVersionHistoryOnSystemName} from "../../services/ArtifactCommonClient";
import {schema} from "../../components/common/validation/ValidationInternalMetaDataYupErrorRules";
import {ArtifactHeader} from "../../components/common/page/header/ArtifactHeader";
import {
    nowDateYearMonthDay
} from "../../components/common/CommonFunctions";
import {IConceptMap, IConceptMapGroup, IConceptMapGroupElement} from "fhir-typescript-models";
import {MRT_TableInstance} from "material-react-table";
import {ConceptMapTableRowObj} from "../../components/artifact/conceptmap/model/ConceptMapTableRowObj";
import IdentifierArray from "../../components/artifact/meta/IdentifierArray";
import {
    translateIdentifierFromUrn,
    translateIdentifierToUrn
} from "../../components/artifact/meta/IdentifierTranslator";
import {ACCESS_TOKEN} from "../../constants";
import {
    handleMessageList,
    removeValidationMessagesOnObjPath
} from "../../components/common/validation/MetaValidationRules";
import {IRHookFormValidationMessage} from "../../components/common/rhookform/IRHookFormValidationMessage";
import {string} from "yup";
import {
    contactListEmpty, emptyIndentifierFormValues, emptyIndentifierValues,
    identifier} from "../../model/defaultvalues/MetaDataDefaultFormValue";
import {isLocked, permissionToEdit} from "../../user/PermissionUtil";
import {ValidationHeader} from "../../components/common/page/header/ValidationHeader";
import {
    handleIfValidationExternalNotificationsAllTypes
} from "../../components/common/validation/external/ValidationExternalNotificationService";
import CreateNewVersionDialog from "../../components/common/page/create/CreateNewVersionDialog";
import GroupCreate from "../../components/artifact/conceptmap/create/GroupCreate";
import {updateResponseErrorHandling} from "../ArtifactPageCommons";
import {externallyValidateCreatedInfoNotifications, externallyValidateMetaData} from "../../services/ValidationService";
import ITabName from "../../components/common/page/ITabName";
import {collectNotices} from "../../components/common/page/TabName";
import {addTTExtensionsToForm, extensionList} from "../../model/defaultvalues/MetaDataExtensionDefaultFormValue";

type TParams = { id: string, version: string }


const fhirClient = new Client({baseUrl: fhirUrl});

let renderCount = 0;


function ConceptMapReadUpdateForm() {

    const [editDisabled, setEditDisabled] = useState(true);
    const [editModeActivated, setEditModeActivated] = useState(false);
    const [foundHistory, setFoundHistory] = useState([]);
    const [relatedArtifactsArray, setRelatedArtifactsArray] = useState<any[]>([]);

    const [sourceUrl, setSourceUrl] = useState<string | null>(null);
    const [targetUrl, setTargetUrl] = useState<string | null>(null);
    const [sourceTableData, setSourceTableData] = useState<IConceptMapGroupElement[]>([]);
    const sourceTableInstanceRef = useRef<MRT_TableInstance<ConceptMapTableRowObj>>(null);

    const [isRequesting, setIsRequesting] = useState(false);


    const [initialVersion, setInitialVersion] = useState(undefined);
    const [initialStatus, setInitialStatus] = useState<string>();
    const [activateSaveSuccessNotice, setActivateSaveSuccessNotice] = useState(false);
    const [activateSaveErrorNotice, setActivateSaveErrorNotice] = useState(false);
    const [isFromDraftToActiveSave, setIsFromDraftToActiveSave] = useState(false);
    const [isFromActiveToRetiredSave, setIsFromActiveToRetiredSave] = useState(false);


    const [errorMessageList, setErrorMessageList] = useState<IRHookFormValidationMessage[]>([]);
    const [warnMessageList, setWarnMessageList] = useState<IRHookFormValidationMessage[]>([]);
    const [infoMessageList, setInfoMessageList] = useState<IRHookFormValidationMessage[]>([]);

    const createNewVersionButtonRef = useRef("create-new-version-button");
    const [showCreateNewVersionDialog, setShowCreateNewVersionDialog] = useState(false);

    const [metaDataNotices, setMetaDataNotices] = useState(0);
    const [contentDataNotices, setContentDataNotices] = useState(0);
    const [publishDataNotices, setPublishDataNotices] = useState(0);


    const handleErrorMessageList = (message: string, label: string, tabName: ITabName, index?: string, path?: string) => {
        handleMessageList(message, label, errorMessageList, setErrorMessageList, tabName, index, path);
    };


    const handleWarnMessageList = (message: string, label: string, tabName: ITabName, index?: string, path?: string) => {
        handleMessageList(message, label, warnMessageList, setWarnMessageList, tabName, index, path);
    };

    const handleInfoMessageList = (message: string, label: string, tabName: ITabName) => {
        handleMessageList(message, label, infoMessageList, setInfoMessageList, tabName);
    };

    const removeValidationMessagesOnPath = (path: string) => {
        removeValidationMessagesOnObjPath(path, setErrorMessageList);
        removeValidationMessagesOnObjPath(path, setWarnMessageList);
    };

    const params = useParams();
    const search = useLocation().search;
    const editValue = new URLSearchParams(search).get('edit');
    const createdValue = new URLSearchParams(search).get('created');


    const methods: UseFormReturn<IConceptMap, UseFormProps> = useForm<IConceptMap>({
        defaultValues: {
            // @ts-ignore
            contact: contactListEmpty,
            extension: extensionList,
            identifier: identifier,
            resourceType: conceptmap,
            status: 'draft'
        },
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        mode: "onChange"
    });

    const resetAsyncForm = useCallback(async () => {
        fhirClient
            .read({resourceType: conceptmap, id: params.id as string})
            .then(
                (result) => {
                    setInitialVersion(result.version);
                    setInitialStatus(result.status);
                    retrieveVersionHistory(result, conceptmap, setFoundHistory);
                    return result;
                }
            ).then(
            (result) => {
                addTTExtensionsToForm(result);
                //   await retrieveVersionHistory(result, conceptmap, setFoundHistory);
                handleR4ConceptMapIdentifier(result);
                translateIdentifierFromUrn(result, editDisabled);
                methods.reset(result); // asynchronously reset your form values
                if (permissionToEdit(methods) == true && editValue === "true" && result.status == "draft") {
                    setEditDisabled(false);
                    setEditModeActivated(true);
                }
                if (permissionToEdit(methods) == true && createdValue === "true" && result.status == "draft") {
                    externallyValidateCreatedInfoNotifications(methods.getValues(), infoMessageList,
                        handleInfoMessageList, methods, false, conceptmap);
                }
                return result;
            }
        ).then(
            (result) => {
                if (result.group !== undefined && result.group.length !== 0) {
                    retrieveRelatedArtifacts(result.group[0].source, result.group[0].target);
                }
            }
        )

            /*            .then((result) => {
                        console.log("PARAMS: " + JSON.stringify(params))

                        if (permissionToEdit(methods) && editDisabled == false)  {

                            const fhirClientValidation = new Client({baseUrl: testFhirUrl + "/validation"});
                            fhirClientValidation.create({
                                resourceType: valueset,
                                // @ts-ignore
                                body: response,
                                options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}},
                            }).then(response => {
                                handleIfValidationNotificationsAllTypes(
                                    // @ts-ignore
                                    response, methods,
                                    infoMessageList, handleInfoMessageList,
                                    warnMessageList, handleWarnMessageList,
                                    errorMessageList, handleErrorMessageList
                                )
                            })
                                .catch(e => {
                                    // @ts-ignore
                                    updateResponseErrorHandling(e, setIsRequesting, methods);
                                });
                        }


                        return result;
                    })*/



            .catch(e => {
                setActivateSaveErrorNotice(true);
                // @ts-ignore
                updateResponseErrorHandling(e, setIsRequesting, methods);
            });

    }, [methods.reset]);


    /*
    * OntoServer saves R4 ConceptMap with Identifier object as only ONE object,
    * while saves R4 CodeSystem and ValueSet Identifier as object in a list,
    * Fhir R4 ConceptMap Identifier is in an array: https://hl7.org/fhir/R4/conceptmap.html
    *  therefor this can be considered a bug in Ontoserver......or...:
    * Could it be that it is the Fhir R4 standard that has corrected its web to incorrectly state that the
    * ConceptMap Identifier is an array? - have found sources other than OntoServer that
    * also specify the R4 ConceptMap Identifier as an object instead of an array
    * This method handles this problem and converts ConceptMap Identifier to an array,
    * remove this method when this bug is removed in Ontoserver.
    * https://inera.atlassian.net/browse/TERM-84
    * */
    const handleR4ConceptMapIdentifier = (result: any) => {
        if ((result.identifier !== undefined) && (result.identifier !== null) && (result.identifier !== Array)) {
            result.identifier = [result.identifier];
        }
    };

    const handleEditDisabled = (editDisableStatus: boolean) => {
        //  debugger;
        /*        if(editStatus === false){
                    const identifierArray = methods.getValues("identifier");
                    validateHasIdentifiersCheckIdentiferArray(identifierArray,handleWarnMessageList);
                }*/
        setEditDisabled(editDisableStatus);
        if (editDisableStatus == false) {
            validateArtifact(editDisableStatus);
            // @ts-ignore
            if (JSON.stringify(methods.getValues("identifier")) === JSON.stringify([emptyIndentifierValues])) {
                // @ts-ignore
                methods.setValue("identifier", [emptyIndentifierFormValues])
            }
        }
    };

    const validateArtifact = (editDisableStatus: boolean) => {
        //   debugger;
        externallyValidateMetaData(methods.getValues(), infoMessageList, handleInfoMessageList,
            warnMessageList, handleWarnMessageList,
            errorMessageList, handleErrorMessageList, methods, setIsRequesting, editDisableStatus, conceptmap);
    }

    const retrieveRelatedArtifacts = (sourceUrl: string, targetUrl: string) => {

        let sourceSearchParams = {};
        // @ts-ignore
        sourceSearchParams["url:contains"] = sourceUrl;

        //search on source url, take first ID from searchResult, use ID to get CodeSystem and all its codes
        fhirClient
            .search({resourceType: codesystem, searchParams: sourceSearchParams})
            .then((sourceResponseData) => {
                if (sourceResponseData.entry !== undefined || sourceResponseData.entry.length !== 0) {
                    relatedArtifactsArray.push(sourceResponseData.entry[0]);
                    setRelatedArtifactsArray([...relatedArtifactsArray]);
                }
            })
            .then(() => {
                let targetSearchParams = {};
                // @ts-ignore
                targetSearchParams["url:contains"] = targetUrl;
                return fhirClient.search({resourceType: codesystem, searchParams: targetSearchParams});
            })
            .then((targetResponseData) => {
                if (targetResponseData.entry !== undefined && targetResponseData.entry.length !== 0) {
                    relatedArtifactsArray.push(targetResponseData.entry[0]);
                    setRelatedArtifactsArray([...relatedArtifactsArray]);
                }
            });
    };


    useEffect(() => {
        resetAsyncForm()
    }, [resetAsyncForm])

    useEffect(() => {
        collectNotices(infoMessageList, warnMessageList, errorMessageList, setMetaDataNotices, setContentDataNotices, setPublishDataNotices);
    }, [errorMessageList, warnMessageList, infoMessageList])


    const submitConceptMapSystem: SubmitHandler<IConceptMap> = async (data: IConceptMap) => {

        if (data !== undefined) {
            setIsRequesting(true);
            data.date = nowDateYearMonthDay();

            setIsRequesting(true);
            data.resourceType = conceptmap;

            if (sourceUrl !== null && data.group !== undefined && data.group[0].element !== undefined) {

                for (let i = 0; i < data.group[0].element.length; i++) {
                    let elementWithEquivalenceParameter = data.group[0].element[i];

                    if (elementWithEquivalenceParameter.target !== undefined &&
                        (elementWithEquivalenceParameter.target[0].equivalence === undefined ||
                            elementWithEquivalenceParameter.target[0].equivalence == "")) {
                        sourceTableData.splice(i, 1);
                    } else if (sourceTableData[i] !== undefined &&
                        sourceTableData[i].target !== undefined &&
                        // @ts-ignore
                        sourceTableData[i].target[0] !== undefined) {
                        // @ts-ignore
                        sourceTableData[i].target[0].equivalence = elementWithEquivalenceParameter.target[0].equivalence;
                        // @ts-ignore
                        sourceTableData[i].target[0].comment = elementWithEquivalenceParameter.target[0].comment;
                    }
                }

                // @ts-ignore
                // const rowSelection: RowSelectionTableState = sourceTableInstanceRef.current.getState().rowSelection;

                /*  let codeArray = [];
                  for (let rowSelectionKey in sourceTableData) {
                      codeArray.push(sourceTableData[+rowSelectionKey]);
                  }
      */
                //   let elementArray: IConceptMapGroupElement[] = sourceTableData;


                /* for (let i = 0; i < codeArray.length; i++) {

                     //Fhir ConceptMap rule for saving a group[*].element:
                     if (codeArray[i].target !== undefined && codeArray[i].equivalence != undefined) {

                         let target: IConceptMapGroupElementTarget = {
                             code: codeArray[i].target,
                             display: codeArray[i].targetdisplay,
                             comment: codeArray[i].comment,
                             equivalence: codeArray[i].equivalence
                         };

                         let targetArray: IConceptMapGroupElementTarget[] = [];
                         targetArray.push(target)

                         let element: IConceptMapGroupElement = {
                             code: codeArray[i].source,
                             display: codeArray[i].sourcedisplay,
                             target: targetArray
                         };

                         elementArray.push(element);
                     }
                 }
     */
                let groupArray: IConceptMapGroup[] = []
                const group: IConceptMapGroup = {
                    // @ts-ignore
                    source: sourceUrl,
                    // @ts-ignore
                    target: targetUrl,
                    element: sourceTableData
                };

                groupArray.push(group)

                data.group = groupArray;

            }
            data = removeDefaultConceptMapFormValues(data);
            translateIdentifierToUrn(data);

            fhirClient.update({
                // @ts-ignore
                resourceType: conceptmap,
                id: params.id,
                // @ts-ignore
                body: data,
                options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}
            })
                .then(response => {
                    translateIdentifierFromUrn(data, editDisabled);
                    addTTExtensionsToForm(data);
                    methods.reset(data);
                    setIsRequesting(false);
                    // @ts-ignore
                    setInitialVersion(methods.getValues("version"));

                    if (initialStatus !== data.status) {
                        if (initialStatus === "draft" && data.status === "active") {
                            setIsFromDraftToActiveSave(true);
                        } else if (initialStatus === "active" && data.status === "retired") {
                            setIsFromActiveToRetiredSave(true);
                        }
                        setInitialStatus(data.status);
                        retrieveVersionHistory(data, codesystem, setFoundHistory);
                    }
                    setActivateSaveSuccessNotice(true);


                    //     methods.reset(response);
                    // @ts-ignore
                    retrieveVersionHistoryOnSystemName(methods.getValues("name"), conceptmap, setFoundHistory);

                    handleIfValidationExternalNotificationsAllTypes(
                        // @ts-ignore
                        response, methods,
                        infoMessageList, handleInfoMessageList,
                        warnMessageList, handleWarnMessageList,
                        errorMessageList, handleErrorMessageList
                    )


                })
                .catch(e => {
                    setIsRequesting(false);
                    translateIdentifierFromUrn(data, editDisabled);
                    if (initialStatus !== data.status) {
                        // @ts-ignore
                        methods.setValue("status", initialStatus);
                    }
                    console.error("FhirClient Update Error Response" + JSON.stringify(e));
                    if (e.response !== undefined && e.response.status == 400  && e.response.data !== undefined) {
                        handleIfValidationExternalNotificationsAllTypes(
                            e.response.data, methods,
                            infoMessageList, handleInfoMessageList,
                            warnMessageList, handleWarnMessageList,
                            errorMessageList, handleErrorMessageList
                        )
                    } else {
                        setActivateSaveErrorNotice(true);
                        console.error("FhirClient Update Error Response" + JSON.stringify(e));
                    }
                });
        }
    };

    /*
        const getTitle = () => {
            return methods.getValues("title");
        };
    */

    const createNewVersion = () => {
        setShowCreateNewVersionDialog(true);
        setEditDisabled(true);
        // @ts-ignore
        createNewVersionButtonRef.current.click();
    };

    const isCodesBelongingToFormDirty = () => {
        //TODO Check if any arryas belonging to the form is updated  - when user turn off edit mode.
        return false;
    };



    renderCount++;

    return (
        <div>
            <CreateNewVersionDialog createButtonRef={createNewVersionButtonRef} artifactType={conceptmap}
                                    showCreateDialog={showCreateNewVersionDialog}
                                    setShowCreateDialog={setShowCreateNewVersionDialog}
                                    data={methods.getValues()}/>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submitConceptMapSystem)}>
                    <ArtifactHeader editDisabled={editDisabled} setEditDisabled={handleEditDisabled}
                                    artifactType={conceptmap} isRequesting={isRequesting}
                                    updateDisabled={editDisabled || errorMessageList.length !== 0}
                                    createNewVersion={createNewVersion}
                                    editModeActivated={editModeActivated}
                                    setEditModeActivated={setEditModeActivated}
                                    isCodesBelongingToFormDirty={isCodesBelongingToFormDirty}
                    />
                    <ValidationHeader editDisabled={editDisabled}
                                      infoMessageList={infoMessageList}
                                      warnMessageList={warnMessageList}
                                      errorMessageList={errorMessageList}
                                      activateSaveSuccessNotice={activateSaveSuccessNotice}
                                      setActivateSaveSuccessNotice={setActivateSaveSuccessNotice}
                                      activateSaveErrorNotice={activateSaveErrorNotice}
                                      setActivateSaveErrorNotice={setActivateSaveErrorNotice}
                                      artefactType={conceptmap}
                                      isFromDraftToActiveSave={isFromDraftToActiveSave}
                                      setIsFromDraftToActiveSave={setIsFromDraftToActiveSave}
                                      isFromActiveToRetiredSave={isFromActiveToRetiredSave}
                                      setIsFromActiveToRetiredSave={setIsFromActiveToRetiredSave}
                    />
                    <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between">
                            <IDSCol>

                                <IDSTabs>
                                    <IDSTab label="Metadata"
                                            notifications={editDisabled ? 0 : metaDataNotices}></IDSTab>
                                    <IDSTab label="Innehåll"
                                            notifications={editDisabled ? 0 : contentDataNotices}></IDSTab>
                                    <IDSTab label="Versionsinformation"
                                            notifications={editDisabled ? 0 : publishDataNotices}></IDSTab>
                                    <IDSTab label="Versionshistorik"></IDSTab>
                                    <IDSTab label="Kopplade artefakter"></IDSTab>
                                    <IDSTabPanel>
                                        <fieldset disabled={isLocked(editDisabled, methods)}>
                                            <NamePurpose artifactType={conceptmap} editDisabled={editDisabled}
                                                         handleWarnMessageList={handleWarnMessageList}
                                                         handleInfoMessageList={handleInfoMessageList}
                                                         handleErrorMessageList={handleErrorMessageList}
                                                         removeValidationMessagesOnPath={removeValidationMessagesOnPath}
                                            />
                                            <IdentifierArray editDisabled={editDisabled}
                                                             handleWarnMessageList={handleWarnMessageList}/>
                                        </fieldset>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <fieldset disabled={isLocked(editDisabled, methods)}>
                                            {(editDisabled == false) ?
                                                <GroupCreate
                                                    methods={methods}
                                                    sourceTableInstanceRef={sourceTableInstanceRef}
                                                    setSourceUrl={setSourceUrl}
                                                    setTargetUrl={setTargetUrl}
                                                    sourceTableData={sourceTableData}
                                                    setSourceTableData={setSourceTableData}
                                                />
                                                :
                                                <GroupReadOnly
                                                    editDisabled={editDisabled}
                                                    initUpdate={true}
                                                    artifactArray={relatedArtifactsArray}
                                                    sourceTableInstanceRef={sourceTableInstanceRef}/>
                                            }
                                        </fieldset>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                            <VersionInformation artifactType={conceptmap}
                                                                isLocked={isLocked(editDisabled, methods)}
                                                                editDisabled={editDisabled}
                                                                errorMessageList={errorMessageList}
                                                                handleErrorMessageList={handleErrorMessageList}
                                                                warnMessageList={warnMessageList}
                                                                handleWarnMessageList={handleWarnMessageList}
                                                                hasError={errorMessageList.length !== 0}
                                                                hasWarning={warnMessageList.length !== 0}
                                                                submitArtefact={submitConceptMapSystem}/>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <History history={foundHistory}
                                            // @ts-ignore
                                                 id={params.id}
                                                 type={conceptmap}
                                            // @ts-ignore
                                                 version={initialVersion}/>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <RelatedArtifacts artifactArray={relatedArtifactsArray}
                                                          type={valueset}
                                                          header={"Kodverk i Mappningsspecifikation: "}
                                            /* title={getTitle()}*/
                                        />
                                    </IDSTabPanel>
                                </IDSTabs>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                </form>
                {/*    <DevTool control={methods.control}/>  set up the dev tool */}
            </FormProvider>
        </div>
    );
};

export default ConceptMapReadUpdateForm;
