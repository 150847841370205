import React, {FC} from "react";
import {
    extensionVersionDescriptionCodeSystemUrl,
} from "../../../model/defaultvalues/Constant";
import {publishTabName} from "../../common/page/TabName";
import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {versionDescWarn} from "../meta/MetaWarnInfoConstant";
import RHookFormTextAreaExtensionValueString from "../../common/rhookform/RHookFormTextAreaExtensionValueString";

let renderCount = 0;

interface VersionDescriptionProps {
    editDisabled?: boolean,
    handleWarnMessageList?: any
}


const VersionDescription: FC<VersionDescriptionProps> = ({
                                                             editDisabled,
                                                             handleWarnMessageList
                                                         }) => {
    renderCount++;

    const versionDescriptionValidation = (value: string) => {
        let message = "Ge en utförligare versionsbeskrivning. Minimum 10 tecken."
        let validationResult = value !== undefined && ((value.trim().length > 0 && value.length < 10) && message);
        return collectValidationMessage(message, versionDescWarn, validationResult, handleWarnMessageList, publishTabName);
    }

    return (
        <RHookFormTextAreaExtensionValueString
            label={"Versionsbeskrivning (obligatorisk)"}
            editDisabled={editDisabled}
            tabName={publishTabName}
            extensionSystemUrl={extensionVersionDescriptionCodeSystemUrl}
            handleWarnMessageList={handleWarnMessageList}
            warnValidation={versionDescriptionValidation}/>
    );
}
export default VersionDescription;


