import axios from "axios";
import {LOGIN_API_BASE_URL, OAUTH2_REDIRECT_URI, SECTORS, USER} from "../constants/index";

const logout = () => {
    localStorage.removeItem(USER);
    localStorage.removeItem(SECTORS)
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem(USER));
};

const login = (username, password) => {
    return axios
        .get(LOGIN_API_BASE_URL + "/user/test/login?username=" + username + "&password=" + password +
            "&redirect_uri=" + OAUTH2_REDIRECT_URI + "&location=/")
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem(USER, JSON.stringify(response.data));
            }

            return response.data;
        });
};

const TestuserService = {
    login,
    logout,
    getCurrentUser,
};

export default TestuserService;
