import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {
    codeDuplicateCaseSensitiveError, codeDuplicateCaseSensitiveWarn,
} from "../meta/MetaWarnInfoConstant";
import {contentTabName} from "../../common/page/TabName";


let renderCount = 0;
const arrayname = "concept"


export const codeHasDuplicateValidation = (value: string, prevValue: string, rowindex: string, errorValidation: boolean, handleValidationMessageList: any, methods: any) => {
    const concepts = methods.getValues(arrayname);
    var areEqual = false;
    for (let i = 0; i < concepts.length; i++) {
        //Only compare with rows that is not itself
        if (Number(rowindex) != i) {
            let concept = concepts[i];

            if (concept.code !== undefined && String(concept.code).trim() !== "") {

                if (errorValidation) {
                    if (prevValue !== undefined && prevValue === concept.code && concept.codeError != undefined) {
                        concept.codeError = undefined;
                        collectValidationMessage("", codeDuplicateCaseSensitiveError, false, handleValidationMessageList, contentTabName, i.toString());
                        return areEqual;
                    }
                    if (value === concept.code) {
                        let message = "Samma kod finns mer än en gång. Säkerställ att varje kod bara finns en gång."
                        let validationmessage = collectValidationMessage(message, codeDuplicateCaseSensitiveError, message, handleValidationMessageList, contentTabName, rowindex);
                        areEqual = true;
                        //  rowConceptToValidate.codeError = message;
                        return message;
                    }
                } else {
                    if (prevValue !== undefined && prevValue !== concept.code && String(prevValue).toUpperCase() === String(concept.code).toUpperCase() && concept.codeWarn != undefined) {
                        concept.codeWarn = undefined;
                        collectValidationMessage("", codeDuplicateCaseSensitiveWarn, false, handleValidationMessageList, contentTabName, i.toString());
                        return areEqual;
                    }
                    if (value !== concept.code && String(value).toUpperCase() === String(concept.code).toUpperCase()) {
                        let message = "Samma kod finns mer än en gång i versaler/gemener.  Säkerställ att det är avsiktligt."
                        let validationmessage = collectValidationMessage(message, codeDuplicateCaseSensitiveWarn, message, handleValidationMessageList, contentTabName, rowindex);
                        areEqual = true;
                        //  rowConceptToValidate.codeWarn = message;
                        return message;
                    }
                }
            }
        }
    }
    //remove validationMessage if any
    if (areEqual == false && errorValidation) {
        //   rowConceptToValidate.codeError = undefined;
        collectValidationMessage("", codeDuplicateCaseSensitiveError, false, handleValidationMessageList, contentTabName, rowindex);
    } else if (areEqual == false && errorValidation == false) {
        //   rowConceptToValidate.codeWarn = undefined;
        collectValidationMessage("", codeDuplicateCaseSensitiveWarn, false, handleValidationMessageList, contentTabName, rowindex);
    }
    return areEqual;
}