export const checkHasCode = (worksheet) => {
    const firstHeadlineRow = worksheet.A1;

    if (firstHeadlineRow === undefined) {
        importAlert();
    } else {
        const valueInFirstCellInFirstHeadlineRow = firstHeadlineRow.v;
        if (valueInFirstCellInFirstHeadlineRow !== undefined) {
            const trimmedValue = valueInFirstCellInFirstHeadlineRow.trim();
            if (trimmedValue.toLowerCase() !== "kod") {
                importAlert();
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}

const importAlert = () => {
    alert("En kolumn med rubrik \"Kod\" måste finnas i imporfilen");
}

