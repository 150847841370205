import React from "react";
import {composeIncludeObjPath} from "../VSConstant";

export const removeConceptFromValueSet = (concept, system, version, conceptsToAdd, setConceptsToAdd) => {

    concept.selected = false;
    for (let i = 0; i < conceptsToAdd.length; i++) {

        let include = conceptsToAdd[i]

        if ((include.system === system && include.version === version) ||
            (include.system === system && include.version === undefined)) {

            const pos = include.concept.map(e => e.code).indexOf(concept.code);

            if (pos !== -1) {
                include.concept.splice(pos, 1);

                if (include.concept.length === 0) {
                    conceptsToAdd.splice(i, 1);
                }

                var array = [...conceptsToAdd]; // make a separate copy of the array
                setConceptsToAdd(array);
            }
        }

    }
}

export const removeCodeSystem = (id, system, version, tabKey, conceptsToAdd, setConceptsToAdd, relatedArtifactsArray, setRelatedArtifactsArray, methods) => {
    const hasSelectedCodes = hasSelectedCodesFromCodeSystemToRemove(id, system, version, tabKey, conceptsToAdd);
    if (hasSelectedCodes) {
        conceptsToAdd.splice(tabKey, 1); // 2nd parameter means remove one item only
        var newConceptsToAddArray = [...conceptsToAdd]; // make a separate copy of the array
        setConceptsToAdd(newConceptsToAddArray);
    }

        const newRelatedArtifactsArray = [...relatedArtifactsArray]; // make a separate copy of the array
        const relatedArtifactsArrayAfterRemove = newRelatedArtifactsArray.filter(item => item.resource.id !== id);

        setRelatedArtifactsArray(relatedArtifactsArrayAfterRemove)


    let includesInFormAfterRemove = methods.getValues(composeIncludeObjPath).filter(include => include.system !== system && include.version !== version);
    var newincludesInForm = [...includesInFormAfterRemove]; // make a separate copy of the array
    methods.setValue(composeIncludeObjPath, newincludesInForm)
}

export const hasSelectedCodesFromCodeSystemToRemove = (id, system, version, tabKey, conceptsToAdd) => {
    let hasSelectedCodes = false;
    const concept = conceptsToAdd[tabKey];

    if (concept !== undefined && concept.id !== undefined && concept.id === id) {
        hasSelectedCodes = true;
    } else if (concept.version !== undefined && concept.version.trim() !== "" &&
        concept.version === version && concept.system === system) {
        hasSelectedCodes = true;
    } else if (concept.system === system) {
        hasSelectedCodes = true;
    }
    return hasSelectedCodes;
}


export const addConceptToValueSet = async (concept, system, version, tabindex, e, conceptsToAdd, setConceptsToAdd, id) => {
//e.target.checked &&

    if (concept !== undefined && concept !== null) {

        if (conceptsToAdd[tabindex] === undefined) {

            var ninclude = createInclude(concept, system, version, tabindex, id)

            setConceptsToAdd([
                ...conceptsToAdd,
                ninclude
            ]);
        } else {

            if (concept instanceof Array) {

                for (let c of concept) {

                    //add if not already existing
                    if (conceptsToAdd[tabindex].concept.find(obj => {
                        return obj.code === c.code
                    }) === undefined) {
                        c.selected = true;
                        conceptsToAdd[tabindex].concept.push(c);
                    }
                }
            } else {
                concept.selected = true;
                conceptsToAdd[tabindex].concept.push(concept);
            }

            setConceptsToAdd([
                ...conceptsToAdd
            ]);
        }
    }
};

const createInclude = (concept, system, version, tabindex, id) => {

    var ninclude = {};
    ninclude.system = system;
    ninclude.version = version;
    ninclude.tabindex = tabindex
    ninclude.id = id;

    let includeConceptList = [];

    if (concept instanceof Array) {
        for (let c of concept) {
            c.system = system;
            c.version = version;
            c.selected = true;
        }
        includeConceptList = concept;

    } else {
        concept.system = system;
        concept.version = version;
        concept.selected = true;
        includeConceptList.push(concept);
    }

    ninclude.concept = includeConceptList;
    return ninclude;
};


export const addOrRemoveAllCodeSystemConceptsToValueSet = (conceptArrayInCodeSystem, system, version, tabkey, allSelected, includeArraySummery, setConceptsToAdd, id) => {

    if (conceptArrayInCodeSystem !== undefined && conceptArrayInCodeSystem !== null) {
        let includeInSummery = includeArraySummery.find(o => o.system === system && (o.version === version));

        if (allSelected && includeInSummery === undefined) {
            var ninclude = {};
            ninclude.system = system;
            ninclude.version = version;
            ninclude.tabindex = tabkey;
            ninclude.id = id;

            for (let c of conceptArrayInCodeSystem) {
                c.selected = true;
            }

            let includeConceptList = []
            includeConceptList.push(...conceptArrayInCodeSystem)
            ninclude.concept = includeConceptList;

            setConceptsToAdd([
                ...includeArraySummery,
                ninclude
            ]);
        } else if (includeInSummery !== undefined) {

            if (includeInSummery.concept !== undefined) {
                let newConceptsToAdd = [];
                for (let conceptInCodeSystem of conceptArrayInCodeSystem) {
                    //Collect concepts to add
                    if (allSelected && (includeInSummery.concept.find(x => x.code === conceptInCodeSystem.code) === undefined)) {
                        conceptInCodeSystem.system = system;
                        conceptInCodeSystem.version = version;
                        conceptInCodeSystem.selected = true;
                        newConceptsToAdd.push(conceptInCodeSystem);
                    }
                    //Remove
                    else if (!allSelected && (includeInSummery.concept.find(x => x.code === conceptInCodeSystem.code) !== undefined)) {
                        let conceptInSummery = includeInSummery.concept.find(x => x.code === conceptInCodeSystem.code);
                        const index = includeInSummery.concept.indexOf(conceptInSummery);
                        conceptInCodeSystem.selected = false;

                        if (index > -1) { // only splice array when item is found
                            includeInSummery.concept.splice(index, 1); // 2nd parameter means remove one item only
                        }
                    }
                }

                //Add collection to summery
                if (newConceptsToAdd.length > 0) {
                    if (includeArraySummery.length > 0) {

                        let includeInSummeryToAddConceptsTo = includeArraySummery.find(x => (x.system === newConceptsToAdd[0].system)
                            && (x.version === newConceptsToAdd[0].version));
                        includeInSummeryToAddConceptsTo.concept.push(...newConceptsToAdd);
                    }
                }

                setConceptsToAdd([
                    ...includeArraySummery
                ]);
            }

        }
    }
};