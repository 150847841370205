import {IContactDetail, IContactPoint, IIdentifier} from "fhir-typescript-models";
import {uniformResourceIdentifier, urnOID, urnUUID,} from "./Constant";
import {REDIRECT_API_BASE_URL} from "../../constants";

export const artifactUrl = REDIRECT_API_BASE_URL;
//   + "/" + uuidv4();

export const identifier = {
    system: "",
    value: ""
};

export const identifierList = [identifier];

//https://www.ietf.org/rfc/rfc3986.txt
//Note that this OID is created to aid with interconversion between CDA and FHIR
// - FHIR uses urn:ietf:rfc:3986 as equivalent to this OID
//https://terminology.hl7.org/3.1.0/NamingSystem-uri.html
const OIDsystem = uniformResourceIdentifier;

export const emptyIndentifierArrayWithDefaultUUID = {"system": OIDsystem, "value": urnUUID}
export const emptyIndentifierWithEmptySystem = {"system": ""}
export const emptyIndentifierWithDefaultOID = {"system": OIDsystem, "value": urnOID}
export const emptyIndentifierValues = {"system": "", "value": ""}
export const emptyIndentifierFormValues = {system: "", value: ""}
export const emptyIndentifierArrayWithOneDefaultOIDArray = [emptyIndentifierWithDefaultOID]

export const formUUID: string = "UUID";
export const formOID: string = "OID";

export const formLabelUUID: string = "UUID";
export const formLabelOID: string = "OID";

export const emptyFormOID: string = JSON.stringify({"system": formOID, "value": ""});
export const emptyFormUUID: string = JSON.stringify({"system": formUUID, "value": ""});

export const telecomEmpty: IContactPoint = {
    "system": "",
    "value": ""
};

export const telecomArrayWithEmpty: IContactDetail[] = [telecomEmpty];

export const contactEmpty: IContactDetail = {
    "name": "",
    "telecom": telecomArrayWithEmpty
};

export const contactListEmpty: IContactDetail[] = [contactEmpty];

export const contactListEmptyTelecomValue: string = " [{\"name\":\"\",\"telecom\":[{\"value\":\"\"}]}]";

export const formGeneratedEmptyTelecom: IContactPoint = {
    "value": ""
};

export const formGeneratedEmptyContactDetail: IContactDetail = {
    "name": "",
    "telecom": [formGeneratedEmptyTelecom]
};

export const formGeneratedEmptyContact: string = "{\"telecom\":[{\"value\":\"\"}],\"name\":\"\"}";


export const formGeneratedRetireReason: string = "{\"valueString\":\"\",\"url\":\"http://terminologitjansten.inera.se/fhir/StructureDefinition/retire-reason\"}"


export function removeEmptyMetaDataFields(data: any) {

    if (JSON.stringify(data.url?.trim()) === JSON.stringify("")) {
        data.url = undefined;
    }

    if (JSON.stringify(data.caseSensitive) === JSON.stringify("")) {
        data.caseSensitive = undefined;
    }

    if (JSON.stringify(data.experimental) === JSON.stringify("")) {
        data.experimental = undefined;
    }

    if (JSON.stringify(data.hierarchyMeaning?.trim()) === JSON.stringify("")) {
        data.hierarchyMeaning = undefined;
    }

    let resutl1 = (JSON.stringify(data.publisher?.trim()) === JSON.stringify(""));
    let result2 = data.publisher?.trim() === "";
    if (JSON.stringify(data.publisher?.trim()) === JSON.stringify("")) {
        data.publisher = undefined;
    }

    if (JSON.stringify(data.title?.trim()) === JSON.stringify("")) {
        data.title = undefined;
    }

    if (JSON.stringify(data.version?.trim()) === JSON.stringify("")) {
        data.version = undefined;
    }

    if (JSON.stringify(data.name?.trim()) === JSON.stringify("")) {
        data.name = undefined;
    }

    if (JSON.stringify(data.description?.trim()) === JSON.stringify("")) {
        data.description = undefined;
    }


    return data;
};


export function removeIdentifierFormDefault(data: any) {

    if (data.identifier !== undefined) {
        // console.log(" removeIdentifierFormDefault data.identifier: " + JSON.stringify(data.identifier))
        const datIdentifierAsString = JSON.stringify(data.identifier);
        if (datIdentifierAsString === "[{}]" ||
            datIdentifierAsString === JSON.stringify(identifierList)) {
            delete data.identifier;
        } else if (data.identifier === Array) {
            let arrayWithoutEmptyIdentifier: IIdentifier[] =
                // @ts-ignore
                data.identifier.filter(value => JSON.stringify(value) !== JSON.stringify(identifier) &&
                    identifierWithoutValue(identifier));
            if (arrayWithoutEmptyIdentifier.length === 0) {
                // @ts-ignore
                arrayWithoutEmptyIdentifier = undefined;
            }
            data.identifier = arrayWithoutEmptyIdentifier;
        }
        if (data.identifier !== undefined) {

            for (let i = 0; i < data.identifier.length; i++) {

                if (JSON.stringify(data.identifier[i]) === JSON.stringify(emptyIndentifierWithDefaultOID)) {
                    data.identifier.splice(i, 1);
                }
                if (JSON.stringify(data.identifier[i]) === JSON.stringify(emptyIndentifierValues)) {
                    data.identifier.splice(i, 1);
                } else if (JSON.stringify(data.identifier[i]) === JSON.stringify(emptyIndentifierFormValues)) {
                    data.identifier.splice(i, 1);
                } else if (JSON.stringify(data.identifier[i]) === JSON.stringify(emptyIndentifierWithEmptySystem)) {
                    data.identifier.splice(i, 1);
                } else if (JSON.stringify(data.identifier[i]) === JSON.stringify(emptyIndentifierArrayWithDefaultUUID)) {
                    data.identifier.splice(i, 1);
                } else if (JSON.stringify(data.identifier[i]) === emptyFormOID) {
                    // console.log("removeIdentifierFormDefault emptyFormOID");
                    data.identifier.splice(i, 1);
                } else if (JSON.stringify(data.identifier[i]) === emptyFormUUID) {
                    data.identifier.splice(i, 1);
                }
            }

            if (data.identifier.length == 0) {
                data.identifier = undefined;
            }
        }
    }
}

function identifierWithoutValue(identifier: any) {
    if (identifier && (identifier.value === undefined || identifier.value === "")) {
        return true;
    } else {
        return false;
    }
}

export function removeContactFormDefault(data: any) {

    if (JSON.stringify(data.contact) === JSON.stringify(contactListEmpty) ||
        JSON.stringify(data.contact) === JSON.stringify(contactListEmptyTelecomValue)) {
        delete data.contact;
    } else if (data.contact) {

        for (let i = 0; i < data.contact.length; i++) {
            let contactDetail = data.contact[i];

            if (JSON.stringify(contactDetail) === JSON.stringify(contactEmpty) ||
                JSON.stringify(contactDetail) === formGeneratedEmptyContact) {
                data.contact.splice(i, 1);
            }
            if (contactDetail.name !== undefined && contactDetail.name.trim() === "") {
                contactDetail.name = undefined;
            }

            removeTelecomFormDefault(contactDetail);
            removeTelecomEmptyValue(contactDetail);

            if (JSON.stringify(contactDetail) === "{}") {
                data.contact.splice(i, 1);
            }

        }

        if (data.contact?.length === 0) {
            data.contact = undefined;
        }
    }
}

export function removeTelecomFormDefault(contact: IContactDetail) {
    if (contact.telecom) {
        const arrayWithoutEmptyTelecom: IContactPoint[] =
            // @ts-ignore
            contact.telecom.filter(
                value => JSON.stringify(value) !== JSON.stringify(telecomEmpty));
        contact.telecom = arrayWithoutEmptyTelecom;
    }
}

export function removeTelecomEmptyValue(contact: IContactDetail) {
    if (contact.telecom) {
        const arrayWithoutEmptyTelecom: IContactPoint[] =
            // @ts-ignore
            contact.telecom.filter(telecom => JSON.stringify(telecom.value?.trim()) !== JSON.stringify(""));
        contact.telecom = arrayWithoutEmptyTelecom;
    }
    if (JSON.stringify(contact.telecom) === "[]") {
        contact.telecom = undefined;
    }
}


