import React, {useCallback, useEffect, useState} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import '@inera/ids-core/components/expandable/register';
import {getRenderCountText} from '../../../common/RenderCount';
import {string} from "yup";
import '@inera/ids-core/components/expandable/register';
import {ConceptMapTableRowObj} from "../model/ConceptMapTableRowObj";
import CMTableSourceReadOnly from "./CMTableSourceReadOnly";
import {codesystem, fhirUrl} from "../../../../model/defaultvalues/Constant";
import Client from "fhir-kit-client";
import GroupUpdate from "../create/GroupUpdate";
import {IConceptMapGroupElement} from "fhir-typescript-models/dist/src/models/fhir/interfaces/IConceptMapGroupElement";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";

let renderCount = 0;


const GroupReadOnly = (props: any) => {
    const methods = useFormContext();

    const {
        register,
        formState: {errors},
        control
    } = useFormContext();

    const {fields} = useFieldArray({
        name: `group`,
        control,
        rules: {
            required: "Please append at least 1 item"
        }
    });

    const [sourceTableData, setSourceTableData] = useState<IConceptMapGroupElement[]>([]);
    const [targetTableData, setTargetTableData] = useState<ConceptMapTableRowObj[]>([]);
    const fhirClient = new Client({baseUrl: fhirUrl});

    const resetAsyncForm = useCallback(async () => {

        var sourceData: IConceptMapGroupElement[] = methods.getValues("group[0].element");

        //Ontoserver does not allow saving not completed source-target mappings, so we need to fetch
        //the rows that have not been matched yet if ConceptMap is in status=draft.
        if (sourceData !== undefined && methods.getValues("status") === "draft") {
            var conceptsFromSourceCodeSystem: ICodeSystemConcept[] = [];
            let params = {};
            // @ts-ignore
            params["url:contains"] = methods.getValues("group[0].source");

            //search on source url, take first ID from searchResult, use ID to get CodeSystem and all its codes
            await fhirClient
                .search({resourceType: codesystem, searchParams: params})
                .then((data) => {
                    if (data.entry !== undefined || data.entry.length !== 0) {
                        // We only support one source and one target (the latest)
                        return data.entry[0].resource.id;
                    }
                }).then((artifactId) => {
                    return fhirClient.read({resourceType: codesystem, id: artifactId});
                }).then((codesystem) => {
                    conceptsFromSourceCodeSystem = codesystem.concept;
                });

            //remove mapped from allConceptsFromSourceCodeSystem to get the ones not mapped
            for (let mappedRow of sourceData) {
                // @ts-ignore
                const pos = conceptsFromSourceCodeSystem.map(e => e.code).indexOf(mappedRow.code);
                if (pos !== -1) {
                    conceptsFromSourceCodeSystem.splice(pos, 1);
                }
            }
            //add not mapped rows to sourceTableData
            sourceData.push(...conceptsFromSourceCodeSystem);
        }

        await setSourceTableData(sourceData)

    }, [methods.getValues("status")]);


    useEffect(() => {
        resetAsyncForm()
    }, [methods.getValues("group[0].element")])


    renderCount++;

    if (props.editDisabled) {
        return (

            <div className={'container'}>

                <IDSContainer gutterless={true}>
                    <IDSRow justify="space-between">
                        <IDSCol cols="6">
                            {getRenderCountText(renderCount)}
                            <br/>
                            <br/>
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>

                <div>
                    <br/><br/>
                    {(sourceTableData &&
                        methods.getValues("group[0].element")) &&
                        <CMTableSourceReadOnly sourceTableData={sourceTableData}/>}
                </div>
                <div className={'container'}>
                    <br/><br/>
                </div>
            </div>
        );
    } else {

        return (

            <div>
                {sourceTableData &&
                    <GroupUpdate
                        initUpdate={props.initUpdate}
                        artifactArray={props.artifactArray}
                        addConcept={props.addConcept}
                        removeConceptToValueSet={props.removeConceptToValueSet}
                        removeCodeSystemFromValueSet={props.removeCodeSystemFromValueSet}
                        sourceTableData={sourceTableData}
                        setSourceTableData={setSourceTableData}
                        setSourceUrl={props.setSourceUrl}
                        setTargetUrl={props.setTargetUrl}
                        targetTableData={targetTableData}
                        tabkey={1}
                        initTabTarget={true}
                        sourceTableInstanceRef={props.sourceTableInstanceRef}
                        methods={methods}
                    />}
            </div>
        );
    }
}
export default GroupReadOnly;