import React, {useState} from 'react'
import * as XLSX from 'xlsx'
import {IDSAlert, IDSButton, IDSButtonGroup, IDSLink} from "@inera/ids-react";
import {valueset} from "../../model/defaultvalues/Constant";
import {
    getParameterCaseInsensitive,
    removeEmojiFromJson
} from "../../components/common/CommonFunctions";
import ConceptTableForImportCodeSystem from "../../components/artifact/concept/table/ConceptTableForImportCodeSystem";
import ImportConceptTable from "./ImportConceptTable";
import {checkHasCode} from "./ImportCommonFunctions";

//TODO Remove ValueSet related import code as there is a new ImportExcelVS.js
// that specific for CodeSystem for Valuset import
function ImportExcel(props) {

    // on change states
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const formRef = React.useRef();
    // submit
    const [excelData, setExcelData] = useState(null);
    // it will contain array of objects

    const [codeDuplicates, setCodeDuplicates] = useState(null);
    const [fetchedCodes, setFetchedCodes] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);

    // handle File
    const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

    // Ref object to reference the input element
    const inputFile = React.useRef(null);

    const importConcepts = () => {
        //console.log("importConcepts")
        props.replaceCodes(excelData);

        setExcelData(null);
        //   props.addCodes(excelData);
        //   cleanForm();
    }

    const displayConfirmationDialog = async () => {
        await cleanDocumentElement();
        setConfirmationDialog(true)
    }

    const cleanDocumentElement = async () => {
        /*        if (document.getElementById("import-excel-form") != null) {
                    try {
                        document.getElementById("import-excel-form").reset();
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (formRef.current != null) {
                    try {
                        formRef.current.reset();
                    } catch (error) {
                        console.error(error);
                    }
                }*/

        document.getElementById("import-excel-form").reset();
        formRef.current.reset();
    }


    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                //console.log("IMPORT handleFile selectedFile has type")
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            } else {
                setExcelFileError('Vänligen välj bara excel fil typer');
                setExcelFile(null);
            }
        } else {
            console.log("IMPORT handleFile NO selectedFile")
            console.log('plz select your file');
        }
    }

    const fetch = () => {
        debugger
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {type: 'buffer'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            let data = XLSX.utils.sheet_to_json(worksheet);
            debugger
            if (checkHasCode(worksheet)) {

                data = data.map((e) => {
                    return {
                        code: getParameterCaseInsensitive(e, "kod"),
                        display: getParameterCaseInsensitive(e, "klartext"),
                        definition: getParameterCaseInsensitive(e, "beskrivning")
                    }
                });

                data = removeEmojiFromJson(data);

                setExcelData(data);
                const notunique = data.filter(
                    (obj, index) =>
                        data.findIndex((item) => (((item.code !== undefined) && (item.code === obj.code)) ||
                            ((item.display !== undefined) && (item.display === obj.display)))
                        ) !== index
                );

                notunique.length == 0 ? setCodeDuplicates(null) : setCodeDuplicates(notunique);


                //Only use this on later option to add codes

                /*               console.log("IMPORT fetch belongs to codesystem ");
                               compareValueSetIncludeConcepts(data, props.conceptsInCodeSystem);*/


                setFetchedCodes(true);
            } else {
                setExcelData(null);
            }
        } else {
            setExcelData(null);
        }
        formRef.current.reset();
    }

    const compareValueSetIncludeConcepts = (dataFromExcel, concept) => {

        let codesInCodeSystem = [];
        let codesNotInCS = [];

        if (dataFromExcel !== undefined && concept !== undefined) {

            for (const conceptFromExcel of dataFromExcel) {
                if (conceptFromExcel.code !== undefined) {
                    var result = concept.find(concept => {
                        return concept.code === conceptFromExcel.code;
                    })
                    if (result !== undefined) {
                        if (props.belongsTo === valueset) {
                            codesInCodeSystem.push(result);
                        } else {
                            codesNotInCS.push(result)
                        }
                    } else {
                        if (props.belongsTo === valueset) {
                            codesNotInCS.push(conceptFromExcel)
                        } else {
                            codesInCodeSystem.push(conceptFromExcel);
                        }
                    }
                }
            }
        }
        codesInCodeSystem = removeEmojiFromJson(codesInCodeSystem);
        codesNotInCS = removeEmojiFromJson(codesNotInCS);

        setExcelData(codesInCodeSystem);
        setCodeDuplicates(codesNotInCS);

        //console.log("IMPORT compareValueSetIncludeConcepts codesInCodeSystem: " + JSON.stringify(codesInCodeSystem));
        //console.log("IMPORT compareValueSetIncludeConcepts codesInCodeSystem: " + JSON.stringify(codesNotInCS));

        //console.log("IMPORT compareValueSetIncludeConcepts FINAL ExcelData : " + JSON.stringify(excelData));
        //console.log("IMPORT compareValueSetIncludeConcepts FINAL codeDuplicates : " + JSON.stringify(codeDuplicates));

    }

    const jumpToReleventDiv = (id) => {
        const releventDiv = document.getElementById(id);
        // behavior: "smooth" parameter for smooth movement
        releventDiv.scrollIntoView({behavior: "smooth"});
    }

    const removeRow = (index) => {
        if (index > -1) { // only splice array when item is found
            excelData.splice(index, 1);
            var newArray = [...excelData]; // make a separate copy of the array
            setExcelData(newArray)
        }
    }

    const getButtons = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <IDSButtonGroup>
                    <IDSButton
                        secondary size="m"
                        onClick={() => props.closeDialog()}>
                        AVBRYT
                    </IDSButton>
                    <IDSButton
                        secondary toggle active size="m"
                        onClick={() => confirmationDialog ? importConcepts() : displayConfirmationDialog()}>
                        LÄS IN KODPOSTER
                    </IDSButton>
                </IDSButtonGroup>
            </div>
        );
    };

    return (
        <div>

            {
                confirmationDialog ?
                    <div>
                        Du kommer nu att rensa alla kodposter i befintligt innehåll och ersätta allt med kodposter från
                        filen. Vill du fortsätta?
                        <br></br>
                        <br></br>
                        {getButtons()}
                    </div>
                    :
                    <div className="container">

                        {/* upload file section */}
                        <div
                            //  className='form'
                        >
                            <form
                                // className='form-group'
                                autoComplete="off" id="import-excel-form" ref={formRef}>
                                {/*       <a id="import-anchor"><label><h4 className="ids-heading-4">Importera koder från fil</h4></label></a>
                    <br></br>*/}
                                När du importerar koder från fil rensar och ersätter de alla befintliga kodposter.
                                <br></br>
                                <br></br>
                                Välj en excel-fil med kodposter att importera
                                <br></br>
                                <br></br>
                                <input type='file' className='form-control' onChange={handleFile} required
                                       ref={inputFile}></input>
                                {excelFileError && <div className='text-danger'
                                                        style={{marginTop: 5 + 'px'}}>{excelFileError}</div>}
                                <br></br>
                                <br></br>
                                <IDSButton secondary={true} size="s" onClick={() => fetch()}>
                                    Hämta koder från fil
                                </IDSButton>
                            </form>
                        </div>

                        <br></br>
                        <hr></hr>

                        {/* view file section */}
                        {/*{(fetchedCodes && ((codeDuplicates == null || codeDuplicates.length == 0) && props.belongsTo !== valueset)) &&

*/}
                        {(fetchedCodes) &&
                            <div>
                                {(codeDuplicates == null || codeDuplicates.length == 0) &&
                                    <h4 className="ids-heading-4">Inspektera hämtade koder</h4>}
                                <div className='viewer'>
                                    {/*      {excelData === null && <>Ingen fil vald</>}*/}
                                    {excelData !== null && (
                                        <div className='table-responsive'>
                                            <div
                                                //className="scroll"
                                            >
                                                {/*                 <table className='table'>
                                <thead>
                                <tr>
                                    <th scope='col' align={"left"}>Kod</th>
                                    <th scope='col' align={"left"}>Klartext</th>
                                    <th scope='col' align={"left"}>Beskrivning</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                </tr>
                                <Data excelData={excelData}/>
                                <tr>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                </tr>
                                </tbody>

                                <tfoot alith="center">
                                <IDSButton secondary={true} size="m" onClick={() => props.addCodes(excelData)}>
                                    Importera koder
                                </IDSButton>

                                </tfoot>
                            </table>*/}
                                                {(excelData !== null && excelData.length !== 0 || (codeDuplicates !== null && codeDuplicates.length > 0)) &&
                                                    (props.belongsTo === valueset) &&
                                                    <ImportConceptTable data={excelData} remove={removeRow}
                                                                        editDisabled={false}/>
                                                }


                                                {(excelData !== null && excelData.length !== 0 && (codeDuplicates == null || codeDuplicates.length == 0)) &&
                                                    (props.belongsTo !== valueset) &&
                                                    <ConceptTableForImportCodeSystem data={excelData}
                                                                                     setData={setExcelData}
                                                                                     editDisabled={false}/>
                                                }


                                                {(codeDuplicates !== null && codeDuplicates.length !== 0) &&
                                                    <div>
                                                        {(props.belongsTo === valueset) ?
                                                            <div><h4
                                                                className="ids-heading-4">{codeDuplicates.length} kodposter
                                                                går
                                                                inte att hitta i källan</h4>
                                                                Du kan antingen utesluta dessa kodposter från urvalet
                                                                genom att ta bort dem eller gå tillbaka ett steg och
                                                                byta källa
                                                                <br></br>
                                                                <br></br>
                                                                <ImportConceptTable data={codeDuplicates}
                                                                                    isErrorTable={true}/>
                                                            </div>
                                                            :
                                                            <div>
                                                                <h4 className="ids-heading-4"> Det finns dubbletter i
                                                                    den valda filen</h4>
                                                                För att kunna importera kodposterna behöver dubbletter i
                                                                kod och dubbletter i klartext
                                                                tas bort i den aktuella filen.
                                                                <br></br>
                                                                <br></br>
                                                                <ImportConceptTable data={codeDuplicates}
                                                                                    isErrorTable={true}/>
                                                                <IDSAlert
                                                                    sricontitle={"Kodposterna går inte att läsa in"}
                                                                    type={"error"}>
                                                                    Ta bort dubbletter i den fil du vill läsa in för att
                                                                    kunna gå vidare
                                                                </IDSAlert>
                                                                <br></br>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'left',
                                                                    alignItems: 'left'
                                                                }}>
                                                                    <IDSButtonGroup>
                                                                        <IDSButton
                                                                            secondary size="m"
                                                                            onClick={() => props.closeDialog()}>
                                                                            AVBRYT
                                                                        </IDSButton>
                                                                    </IDSButtonGroup>
                                                                </div>
                                                            </div>
                                                            /*      <div>
                                                                      <h4 className="ids-heading-4">{codeDuplicates.length} koder
                                                                      finns
                                                                      redan kodverket</h4>
                                                                      Du kan utesluta dessa kodposter genom att ta bort dem
                                                                      från importen
                                                                  </div>*/
                                                        }

                                                    </div>
                                                }

                                                {excelData !== null && excelData.length != 0 &&
                                                    (codeDuplicates === null || codeDuplicates.length === 0) &&
                                                    getButtons()

                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
            }
        </div>
    );
}


export default ImportExcel;