import {useFormContext} from "react-hook-form";
import RHookFormTextField from "../../common/rhookform/RHookFormTextField";
import React, {useEffect} from "react";
import {artifactUrl} from "../../../model/defaultvalues/MetaDataDefaultFormValue";

type FormValues = {
    identifier: {
        system: string;
        value: string;
    }[];
};

let renderCount = 0;


export default function Url(props?:any) {
    const methods = useFormContext();

    const {
        register,
        formState: {errors},
        control
    } = useFormContext<FormValues>();


    useEffect(() => {
        if(methods.getValues("url") === undefined || methods.getValues("url").length === 0){
            methods.setValue("url", artifactUrl);
        }
    }, []);

    renderCount++;

    return (
        <RHookFormTextField methods={methods} label="Url" name={"url"} disabled={true}/>
    );
}
