import React from "react";
import {Extension} from "fhir-typescript-models";
import {
    PrimitiveUri, PrimitiveString
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {UseFormReturn} from "react-hook-form";
import {getExtensionIndexForAnyParameterType, getExtensionArrayIndex} from "../../common/ExtensionUtil";


export const onChangeVersionDesc = (e: any, extensionFieldIndex: string, methods: UseFormReturn,
                                    extensionEmailUrl?: string) => {

    let index: number = getExtensionArrayIndex(methods, extensionEmailUrl)
    let systemFieldNameHierarchy = "extension[" + index + "].valueString";
    let urlFieldNameHierarchy = "extension[" + index + "].url";

    methods.setValue(systemFieldNameHierarchy, e.target.value);
    methods.setValue(urlFieldNameHierarchy, extensionEmailUrl);

}


export const getExtensionIndexForVersionDescription = (extensionUrl: string, fields?: any[]) => {
    return getExtensionIndexForAnyParameterType(extensionUrl, fields, createExtensionForVersionDescription);
}


const createExtensionForVersionDescription = (extensionUrl: string) => {
    const extension: Extension = new Extension();

    const primitiveUri: PrimitiveUri = new PrimitiveUri();
    primitiveUri.value = extensionUrl;

    extension.url = primitiveUri;

    const primitiveString: PrimitiveString = new PrimitiveString();
    primitiveString.value = "";

    extension.value = primitiveString;

    return extension;
}