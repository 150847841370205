import React from "react";
import {UseFormReturn} from "react-hook-form";
import {IValidationExternalNotificationCollection} from "./IValidationExternalNotificationCollection";
import {collectValidationMessage} from "../MetaValidationRules";
import {IRHookFormValidationMessage} from "../../rhookform/IRHookFormValidationMessage";
import {urnOID, urnUUID} from "../../../../model/defaultvalues/Constant";
import {metaDataTabName, publishTabName} from "../../page/TabName";

import {perFormValidationInternalMetaDataWarnings_hasWarnings} from "../ValidationInternalMetaDataWarningRulesUtil";


export const isValidationNotification = (responsebody: any): responsebody is IValidationExternalNotificationCollection => (responsebody as IValidationExternalNotificationCollection).validationNotifications !== undefined;

export const INFO_EXTERNAL_NOTE_LEVEL : string = "INFO";
export const WARNING_EXTERNAL_NOTE_LEVEL : string = "WARNING";

export const ERROR_EXTERNAL_NOTE_LEVEL : string = "ERROR";

export const handleIfValidationExternalNotificationsAllTypes = (data: IValidationExternalNotificationCollection, methods: UseFormReturn,
                                                                infoMessageList?: IRHookFormValidationMessage[], handleInfoMessageList?: any,
                                                                warnMessageList?: IRHookFormValidationMessage[], handleWarnMessageList?: any,
                                                                errorMessageList?: IRHookFormValidationMessage[], handleErrorMessageList?: any,
) => {
    handleIfValidationNotifications(INFO_EXTERNAL_NOTE_LEVEL, data, methods, infoMessageList, handleInfoMessageList);
    let warningsExist: boolean = perFormValidationInternalMetaDataWarnings_hasWarnings(methods, handleWarnMessageList);
    handleIfValidationNotifications(WARNING_EXTERNAL_NOTE_LEVEL, data, methods, warnMessageList, handleWarnMessageList);
    handleIfValidationNotifications(ERROR_EXTERNAL_NOTE_LEVEL, data, methods, errorMessageList, handleErrorMessageList);
};

export const handleIfValidationNotifications = (errorLevel: string, data: IValidationExternalNotificationCollection, methods: UseFormReturn, messageList?: IRHookFormValidationMessage[], handleMessageList?: any) => {

    if (data.validationNotifications !== undefined) {
        for (let validationNotification of data.validationNotifications) {
            let tabname = validationNotification.field === "version" ? publishTabName : metaDataTabName;
            let fields: string[] = [];
            if (validationNotification.errorLevel === errorLevel) {
                let fieldIdentifier = undefined;
                if (!fields.includes(validationNotification.field + validationNotification.value)) {
                    //Only accept one validation per field
                    fields.push(validationNotification.field + validationNotification.value);

                    if (validationNotification.field === "identifier") {
                        let identifiers = methods.getValues("identifier")
                        for (let i = 0; i < identifiers.length; i++) {
                            const startsWith = validationNotification.value.startsWith(urnUUID) ? urnUUID : urnOID;
                            //[{"system":"urn:ietf:rfc:3986","value":"urn:oid:1.2.752.129.5.1.46"}]
                            if (startsWith + identifiers[i].value === validationNotification.value) {
                                //`test.${index}.email`
                                methods.setError(validationNotification.field + "[" + i + "].value", {
                                    type: errorLevel,
                                    // @ts-ignore
                                    code: validationNotification.code.toString(),
                                    message: validationNotification.message,
                                    tabName: metaDataTabName
                                });
                                fieldIdentifier = "identifier." + i + ".value";
                                break;
                            }
                        }

                    } else {
                        methods.setError(validationNotification.field, {
                            type: errorLevel,
                            // @ts-ignore
                            code: validationNotification.code.toString(),
                            message: validationNotification.message,
                            tabName: tabname
                        });
                    }
                }

                if (messageList !== undefined) {
                    let message = validationNotification.message;
                    // @ts-ignore
                    collectValidationMessage(validationNotification.message, validationNotification.code.toString(), true, handleMessageList, tabname, fieldIdentifier)
                }
            }
        }
    }
};