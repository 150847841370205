import React from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSButtonGroup, IDSCol, IDSContainer, IDSDialog, IDSLink, IDSRow} from "@inera/ids-react";

const ValueSetWarnStatusDialog = (props) => {

    const getHeadline = () => {
        return "Aktivering av urvalet är inte möjligt, en eller flera kodverk är ej aktiva"
    }
    return (
        <div>
            <IDSDialog overlay={true} persistent={true} dismissible="true" show={props.showDialog}
                       headline={getHeadline()}
                       onClosed={() => {
                           props.setShowDialog(false);
                       }}
            >
                <a href="javascript:void(0)" trigger="true" ref={props.dialogRef} style={{color: '#FFF'}}></a>
                <p style={{color: '#FFF'}}>..................................................................................................................................</p>

                <div style={{width: "100%"}}>
                    <IDSContainer gutterless={true} style={{width: "100%"}}>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                <div className="container">
                                    För att aktivera behöver alla källor som artefakten bygger på vara aktiva.
                                    <br/> <br/>
                                </div>
                            </IDSCol>
                        </IDSRow>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                <div>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <IDSButtonGroup>
                                            <IDSLink underlined>
                                                <a href="#" onClick={() => {
                                                    props.setShowDialog(false);
                                                }}>STÄNG </a>
                                            </IDSLink>
                                        </IDSButtonGroup>
                                    </div>
                                </div>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                </div>
            </IDSDialog>
        </div>
    );
};
export default ValueSetWarnStatusDialog;