import React, {useEffect, useMemo, useRef, useState} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef, MRT_RowSelectionState,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import {
    ICodeSystemConcept,
    IValueSetComposeInclude
} from "fhir-typescript-models/dist/src/models/fhir/internal";

let renderCount = 0;

const VSIncludeCodeSummeryTable = (props: any) => {

    renderCount++;

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [summeryTableContent, setSummeryTableContent] = useState<any[]>([]);

    useEffect(() => {

        if (props.conceptsToAdd !== undefined) {

            let conceptArrayWithSystemSpecified: any[] = [];

            for (let i = 0; i < props.conceptsToAdd.length; i++) {
                let include = props.conceptsToAdd[i];
                if (include.concept !== undefined) {
                    // @ts-ignore
                    include.concept.forEach(function (element) {
                        element.system = include.system;
                        element.version = include.version;
                    });
                    conceptArrayWithSystemSpecified.push(...include.concept);
                }
            }

            setSummeryTableContent(conceptArrayWithSystemSpecified);

            for (let i = 0; i < conceptArrayWithSystemSpecified.length; i++) {
                rowSelection[i] = true;
            }
            setRowSelection({...rowSelection});
        }

    }, [props.conceptsToAdd])


    const columnDefinitions: MRT_ColumnDef<IValueSetComposeInclude>[] = [

        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
        {
            accessorKey: 'system',
            header: 'Källa',
            // @ts-ignore
            enableColumnFilters: true,
            enableEditing: false,
        },
        /* {
             accessorKey: 'version',
             header: 'Version',
             // @ts-ignore
             enableColumnFilters: true,
             enableEditing: false,
         },*/
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    return (
        <div>
            <div>
                <MaterialReactTable
                    {...commonTableSourceProps}
                    autoResetPageIndex={false}
                    columns={columns}
                    data={(summeryTableContent.length === 0) ? [] : summeryTableContent}
                    enableRowOrdering={false}
                    enableColumnFilters={true}
                    enableSorting={true}
                    localization={MRT_Localization_SV}
                    enableTopToolbar={false}
                    enableSelectAll={false}
                    enableRowSelection
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableEditing={true}
                    enableHiding={false}
                    editingMode="table"
                    positionActionsColumn="last"
                    enableExpanding
                    enableExpandAll //default
                    tableInstanceRef={tableInstanceRef}
                    initialState={{pagination: {pageSize: 50, pageIndex: 0}}}

                    muiSelectCheckboxProps={({row, table}) => ({
                        defaultChecked: true,
                        onChange: (event) => {
                            // @ts-ignore
                            props.removeConceptToValueSet(row.original, row.original.system, row.original.version);
                        },
                        // color: 'secondary',
                    })}

/*                    muiSelectAllCheckboxProps={({table}) => ({
                        onClick: (event) => {
                            props.setConceptsToAdd([]);
                       },
                        // color: 'secondary',
                    })}*/


                    muiTableHeadRowProps={{
                        sx: muiTableHeadRowPropsStyle
                    }}

                    muiTableProps={{
                        sx: muiTablePropsFiveColumnStyle
                    }}

                    muiTableBodyProps={{
                        sx: muiTableBodyPropsStyle
                    }}


                    muiTablePaperProps={{
                        elevation: 0, //change the mui box shadow
                    }}

                    icons={{
                        MoreVertIcon: () => <ImportExport/>,
                        MoreHorizIcon: () => <MoreVertIcon/>,
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            size: 10,
                        },
                        'mrt-row-drag': {
                            header: '',
                            size: 10,
                        },
                    }}

                    getSubRows={(originalRow) => {
                        return (
                            originalRow.concept
                        )
                    }}

                    muiTableHeadCellColumnActionsButtonProps={{
                        children: <ExpandMore/>
                    }}

                />
            </div>
        </div>
    );
};

export default VSIncludeCodeSummeryTable;
