import React, {FC} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import '@inera/ids-core/styles/themes/inera.css';
import {
    IDSRow,
    IDSCol,
    IDSContainer,
    IDSCard,
    IDSIcon
} from "@inera/ids-react";
import {IRHookFormValidationMessage} from "../../rhookform/IRHookFormValidationMessage";
import {SaveNotification} from "./SaveNotification";
import {SearchNotification} from "./SearchNotification";


interface SearchHeaderProps {
    searchErrorNotice?: boolean,
    setSearchErrorNotice?: any
}

export const SearchNotificationHeader: FC<SearchHeaderProps> = ({
                                                                searchErrorNotice,
                                                                setSearchErrorNotice
                                                            }) => {

    let renderCount = 0;

    return (
        <div>
            <div>
                {(searchErrorNotice !== undefined && searchErrorNotice == true) &&
                    <SearchNotification
                        searchErrorNotice={searchErrorNotice}
                        setSearchErrorNotice={setSearchErrorNotice}
                        idsAlertType={"error"}/>
                }
            </div>
        </div>
    );
};
