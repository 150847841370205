import * as React from "react";
import {Controller} from "react-hook-form";
import {FC, useState} from "react";

interface RHookFormWarnInfoControllerProps {
    name: string,
    defaultValue?: string,
    control: any,
    render: any,
    warn?: any,
    info?: any,
    toucherror?: any,
    methods: any
}

const RHookFormWarnInfoController: FC<RHookFormWarnInfoControllerProps> = ({
                                                                               name,
                                                                               defaultValue,
                                                                               control,
                                                                               render,
                                                                               warn,
                                                                               info,
                                                                               toucherror,
                                                                               methods
                                                                           }) => {

    const [isTouched, setIsTouched] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    return (
        <div
             onBlur={e => setIsTouched(true)}
             onChange={e => setIsDirty(true)}
        >
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({field, fieldState}) => {
                return render({
                    ...field,
                    isDirty,
                    isTouched,
                    // @ts-ignore
                    warning: (warn !== undefined && isTouched) ? warn(field.value, name) : undefined,
                    info: (info !== undefined && isTouched) ? info(field.value) : undefined,
                    toucherror: (toucherror !== undefined && isTouched) ? toucherror(field.value) : undefined,
                });
            }}
        />
        </div>
    );
};
export default RHookFormWarnInfoController;