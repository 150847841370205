import React, {useState} from "react";
import {IDSSelect} from "@inera/ids-react";
import {sortOptions} from "./SearchFilterCommons";
import {LAST_UPDATED} from "../../../constants";


let renderCount = 0;


export default function SearchSortList(props: any) {

    const [selectedSorting, setSelectedSorting] = useState("");

    const handleSort = (event: any) => {
        let newSortValue;
        if (event.target.value.endsWith(props.sortBy) && props.sortBy.charAt(0) !== "-") {
            newSortValue = "-" + event.target.value;
            props.setSortBy("-" + event.target.value)
        } else {

            if (event.target.value === LAST_UPDATED) {
                if (props.sortBy === "-" + LAST_UPDATED) {
                    newSortValue = event.target.value;
                    props.setSortBy(event.target.value)
                } else {
                    newSortValue = "-" + event.target.value;
                    props.setSortBy("-" + event.target.value)
                }
            } else {
                newSortValue = event.target.value;
                props.setSortBy(event.target.value);
            }
        }

        props.setNextSearchResultLink([]);
        props.retrieveArtifacts(newSortValue, true);
        const selectedInSortingList = event.target.value;
        setSelectedSorting(selectedInSortingList);
    };

    renderCount++;

    return (
        <div>{props.foundArtifacts.length !== 0 &&
            <IDSSelect>
                <label>Sortera sökresultat på</label>
                <select onChange={handleSort} value={selectedSorting}>
                    <option value="" disabled={false} selected={false} key={Math.random()}>Välj i listan</option>
                    {sortOptions.map((item) => (
                        <option value={item.value} key={Math.random()}>{item.label}</option>
                    ))}
                </select>
            </IDSSelect>
        }</div>
    );
}
