import React from 'react';
import {ENV_TYPE, IDP_URL,  REDIRECT_API_BASE_URL } from "./constants";

export const getLogoutIdpUrl = (token) => {
    return getIdpUrl() + "/oidc/logout?post_logout_redirect_uri=" + REDIRECT_API_BASE_URL + "/&id_token_hint=" + token;
};

const getIdpUrl = () => {

    let debugWhenPropertyInEnvironment = IDP_URL;
    if (ENV_TYPE === "DEV" || ENV_TYPE === "TEST") {
        return "https://idp.ineratest.org:443";
    } else if (ENV_TYPE === "QA") {
        return "https://idp.ineraqa.org:443";
    } else if (ENV_TYPE === "PROD") {
        return "https://idp.inera.org:443";
    }
};

