import {notmatched, fhirUrl} from "../../../../model/defaultvalues/Constant";
import Client from "fhir-kit-client";
import {translateCodeSystemToTargetTableData} from "../create/table/CMTableTargetUtil";

export const getEquivalenceSwedishText = (equivalence: string) => {
    if (equivalence == "relatedto") {
        return "relaterat till";
    } else if (equivalence == "equivalent") {
        return "motsvarande";
    } else if (equivalence == "equal") {
        return "lika";
    }
    if (equivalence == "wider") {
        return "bredare";
    } else if (equivalence == "subsumes") {
        return "subsumerar";
    } else if (equivalence == "narrower") {
        return "smalare";
    } else if (equivalence == "specializes") {
        return "specialiserad";
    } else if (equivalence == "inexact") {
        return "inexakt";
    } else if (equivalence == notmatched) {
        return "omatchad";
    } else if (equivalence == "disjoint") {
        return "osammanhängande";
    }
};

export const getLatestRelatedArtifactsViaUrl = (url: string, artifactType: string, setResponseData: any) => {

    const client = new Client({baseUrl: fhirUrl});

    let params = {};
    // @ts-ignore
    params["url:contains"] = url;

    client
        .search({resourceType: artifactType, searchParams: params})
        .then((data) => {
            if (data.entry !== undefined && data.entry.length !== 0) {
                // We only support one source and one target (the latest)
                return data.entry[0].resource.id;
            }
        })
        .then((id) => {
            client.read({resourceType: artifactType, id: id}).then((data) => {
                return data;
            })
                .then((data) => {
                    var array = translateCodeSystemToTargetTableData(data, undefined);
                    // @ts-ignore
                    setResponseData(array);

                })
        });
};