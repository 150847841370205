

export const isDraft = (methods) => {
    return methods.getValues("status") === "draft";
}

export const isActive = (methods) => {
    return methods.getValues("status") === "active";
}

export const isRetired = (methods) => {
    return methods.getValues("status") === "retired";
}

