import React, {FC, useMemo, useState} from "react";
import {getRenderCountText} from "../../../common/RenderCount";
import {formDateYearMonthDayTime} from "../../../common/CommonFunctions";
import {
    getSwedishStatusText, muiTableBodyCellPropsStyle, muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle
} from "../../../../styles/TTStyles";
import {
    versionCurrentStyle
} from "../../../../styles/TTStyles";
import {
    extensionRetireReasonSystemUrl,
    extensionVersionDescriptionCodeSystemUrl
} from "../../../../model/defaultvalues/Constant";
import {MRT_Localization_SV} from "material-react-table/locales/sv";
import ImportExport from "@mui/icons-material/ImportExport";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {IHistoryItem} from "./IHistoryItem";
import TextExpandable from "../../../common/rhookform/TextExpandable";
import {string} from "yup";
import {IDSIcon, IDSLink} from "@inera/ids-react";
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef,
} from 'material-react-table'


let renderCount = 0;


interface HistoryProps {
    version: string;
    id: string;
    type: string;
    history: any[];
    editDisabled?: boolean
}

const History: FC<HistoryProps> = ({
                                       version,
                                       id,
                                       type,
                                       history,
                                       editDisabled
                                   }) => {


    const [loadingRows, setLoadingRows] = useState(false);
    const historyRowDefaultData: IHistoryItem = {
        version: "",
        lastUpdated: "",
        versionDescription: "",
        status: "",
        retireReason: "",
        // @ts-ignore
        url: ""
    }
    const historyArrayWithRowDefaultData: IHistoryItem[] = [historyRowDefaultData]

    const getRef = (item: any) => {
        const isPropsVersionEmpty = version === undefined || version === "" ? true : false;
        const isItemVersionEmpty = item.resource.version === undefined || item.resource.version === "" ? true : false;
        if (item.resource.id == id &&
            ((item.resource.version === version) || (isPropsVersionEmpty && isItemVersionEmpty))
        ) {
            return <span style={versionCurrentStyle}>TITTAR PÅ</span>
        } else {
            return (
                <IDSLink underlined={true}>
                    <IDSIcon name="arrow"></IDSIcon>
                    <a href={"/" + type + "/" + item.resource.id}>Gå till version</a>
                </IDSLink>
            );
        }
    }

    const getHRef = (item: any) => {
        const isPropsVersionEmpty = version === undefined || version === "" ? true : false;
        const isItemVersionEmpty = item.resource.version === undefined || item.resource.version === "" ? true : false;
        if (item.resource.id == id &&
            ((item.resource.version === version) || (isPropsVersionEmpty && isItemVersionEmpty))
        ) {
            return undefined;
        } else {
            return "/" + type + "/" + item.resource.id;
        }
    }

    const getVersionDescription = (item: any) => {
        let versionDescription = "";
        if (item.resource.extension !== undefined && Array.isArray(item.resource.extension)) {
            for (let extension of item.resource.extension) {
                if (extension.url !== undefined && extension.url === extensionVersionDescriptionCodeSystemUrl) {
                    versionDescription = extension.valueString;
                }
            }
        }
        return versionDescription;
    }

    const getVersionDescriptionPath = (item: any) => {
        let versionDescriptionPath = "";
        if (item.resource.extension !== undefined && Array.isArray(item.resource.extension)) {
            for (let i = 0; i < item.resource.extension.length; i++) {
                let extension = item.resource.extension[i];
                if (extension.url !== undefined && extension.url === extensionVersionDescriptionCodeSystemUrl) {
                    versionDescriptionPath = "resource.extension[" + i + "].valueString";
                }
            }
        }
        return versionDescriptionPath;
    }


    const getRetiredReason = (item: any) => {
        let retiredReason = "";
        if (item.resource.status === "retired") {
            if (item.resource.extension !== undefined && Array.isArray(item.resource.extension)) {
                for (let extension of item.resource.extension) {
                    if (extension.url !== undefined && extension.url === extensionRetireReasonSystemUrl) {
                        retiredReason = extension.valueString;
                        break;
                    }
                }
            }
        }
        return retiredReason;
    }

    const getHistoryRowData = () => {
        let rowItems: IHistoryItem[] = []

        if (history !== undefined && Array.isArray(history) && history.length != 0) {

            for (let i = 0; i < history.length; i++) {
                let item = history[i];
              //  const statusOfVersion: string = getSwedishStatusTextOnly(item.resource.status);
                const statusOfVersion: string = item.resource.status;
                const retireReason: string = getRetiredReason(item);
                const versionDescription: string = getVersionDescription(item);
                const lastUpdated: string = formDateYearMonthDayTime(item.resource.meta.lastUpdated);
                // @ts-ignore
                const url: string = getHRef(item);

                const historyRowData: IHistoryItem = {
                    version: item.resource.version,
                    lastUpdated: lastUpdated,
                    versionDescription: versionDescription,
                    status: statusOfVersion,
                    retireReason: retireReason,
                    // @ts-ignore
                    url: url
                };
                rowItems.push(historyRowData);
            }
        } else {
            rowItems.push(historyRowDefaultData);
        }
        return rowItems;

    }

    const columnDefinitions: MRT_ColumnDef<IHistoryItem>[] = [
        {
            accessorKey: 'version',
            header: 'Version',
            // @ts-ignore
            enableColumnFilters: true
        },
        {
            accessorKey: 'lastUpdated',
            header: 'Senast ändrad',
            // @ts-ignore
            enableColumnFilters: true
        },
        {
            accessorKey: 'versionDescription',
            header: 'Versionsbeskrivning',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <div>
                <span><TextExpandable
                    text={cell.getValue<string>() === undefined ? "" : cell.getValue<number>().toLocaleString()}/></span>
                </div>
            )
        },
        {
            accessorKey: 'status',
            header: 'Versionsstatus',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <div>
                    {getSwedishStatusText(cell.getValue<string>())}
                </div>
            )
        },
        {
            accessorKey: 'retireReason',
            header: 'Orsak till avställning',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <span><TextExpandable
                    text={cell.getValue<string>() === undefined ? "" : cell.getValue<number>().toLocaleString()}/></span>
            )
        },
        {
            accessorKey: 'url',
            header: 'Länk',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <div>
                    {cell.getValue<string>() === undefined ?
                        <span style={versionCurrentStyle}>TITTAR PÅ</span>
                        :
                        <IDSLink underlined={true}>
                            <IDSIcon name="arrow"></IDSIcon>
                            <a href={cell.getValue<string>()}>Gå till version</a>
                        </IDSLink>
                    }
                </div>
            )
        }

    ];


    const columns = useMemo<MRT_ColumnDef<IHistoryItem>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<IHistoryItem>> & {
        columns: MRT_ColumnDef<IHistoryItem>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };


    renderCount++;

    return (
        <div>


            <div>
                <>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        // columns={columns}
                        data={((history === undefined) || (history.length == 0)) ? historyArrayWithRowDefaultData : getHistoryRowData()}
                        //data={[historyRowDefaultData]}
                        enableRowOrdering={false}
                        enableColumnFilters={false}
                        enableSorting={false}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableSelectAll={false}
                        enableEditing={false}
                        enableHiding={false}
                        enableRowActions
                        positionActionsColumn="last"
                        enableExpanding
                        enableExpandAll //default

                        muiExpandButtonProps={({row, table}) => ({

                            onClick: () => {
                                let rowrow = row;
                                let tabletable = table;

                                // @ts-ignore
                                getSubConcepts(row.originalSubRows, row.id)
                            }
                        })}


                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsFiveColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}


                        muiTableHeadCellColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}

                        muiTableBodyCellProps={{
                            sx: muiTableBodyCellPropsStyle,
                            //     children: <React.Fragment>{renderCustomNoDataComponent()}</React.Fragment>
                        }}

                    />
                </>
            </div>

            <br/><br/>
        </div>
    );
}
export default History;