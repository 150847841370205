import {useFieldArray, useFormContext} from "react-hook-form";
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import '@inera/ids-core/components/expandable/register';
import {getRenderCountText} from '../../../common/RenderCount';
import React, {FC, useEffect, useState} from "react";
import {string} from "yup";
import '@inera/ids-core/components/expandable/register';
import VSConceptTable from "./VSConceptTable";
import {versionCurrentStyle} from "../../../../styles/TTStyles";
import {IBundleEntry} from "fhir-typescript-models";
import IIncludeMeta from "./IIncludeMeta";
import {composeIncludeObjPath} from "../VSConstant";

let renderCount = 0;


interface VSComposeProps {
    artifactArray?: IBundleEntry[],
}


const Compose: FC<VSComposeProps> = ({artifactArray}) => {

    const methods = useFormContext();

    const getCodeSystemMeta = () => {
        if (artifactArray !== undefined && artifactArray?.length !== 0) {
            var realtedCodeSystemMeta: IIncludeMeta[] = []
            for (let bundleEntry of artifactArray) {
                if(bundleEntry.resource !== undefined){
                    let includeMeta = {} as IIncludeMeta;
                    // @ts-ignore
                    includeMeta.system = bundleEntry.resource.url;
                    // @ts-ignore
                    includeMeta.title = bundleEntry.resource.title;
                    realtedCodeSystemMeta.push(includeMeta);
                }
            }
            setIncludeMetaArray([...realtedCodeSystemMeta])
        }
        return [];
    };

    const [includeMetaArray, setIncludeMetaArray] = useState<IIncludeMeta[]>([]);

    useEffect(() => {
        getCodeSystemMeta();
    }, [artifactArray]);

    const {
        register,
        formState: {errors},
        control
    } = useFormContext();

    const {fields, append, remove} = useFieldArray({
        name: `compose.include`,
        control,
        rules: {
            required: "Please append at least 1 item"
        }
    });


    const getTitle = (url: string) => {
        if (includeMetaArray.length !== 0) {
            let includeMeta = includeMetaArray.find(x => x.system === url);
            return includeMeta?.title;
        }
    };


    renderCount++;

    return (
        <div>
            <div>
                <br/><br/>
                {fields.map((field, index) => {
                    return (

                        <div>
                            <IDSContainer gutterless={true}>
                                <IDSRow justify="space-between">
                                    <IDSCol cols="12">
                                        <p className="body"><span style={versionCurrentStyle}>
                                            <h3 className="ids-heading-3" style={versionCurrentStyle}>{getTitle(methods.getValues("compose.include[" + index + "].system"))}</h3>
                                       {/*     &nbsp;&nbsp;|&nbsp;&nbsp;*/}
                                            Källa: <strong>{methods.getValues(composeIncludeObjPath + "[" + index + "].system")}</strong>
                                        </span></p>
                                    </IDSCol>
                                </IDSRow>

                                <IDSRow justify="space-between">
                                    <IDSCol cols="12">
                                        <VSConceptTable
                                            data={methods.getValues(composeIncludeObjPath + "[" + index + "].concept")}
                                            methods={methods} showDefinition={false}
                                        />
                                    </IDSCol>
                                </IDSRow>
                            </IDSContainer>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
export default Compose;