import React, {useEffect} from 'react';

import {defaultCodeSystemForValueSetArray} from "../../../model/defaultvalues/ValueSetDefaultFormValue";
import VSIncludeCodeSummeryTable from "./VSIncludeCodeSummeryTable";

let renderCount = 0;

const VSIncludeCodeSummery = (props: any) => {

    renderCount++;

    return (

        <div>

            {(props.codeSystemsWithAllCodesSelectableCodes !== undefined &&
                    props.codeSystemsWithAllCodesSelectableCodes.length !== 0 &&
                    (JSON.stringify(props.codeSystemsWithAllCodesSelectableCodes)
                        !== JSON.stringify(defaultCodeSystemForValueSetArray))) &&
                <div>
                    <div className={'container'}>
                        <h4 className="ids-heading-4">Sammanställning av urval</h4>
                        <VSIncludeCodeSummeryTable conceptsToAdd={props.conceptsToAdd}
                                                   setConceptsToAdd={props.setConceptsToAdd}
                                                   removeConceptToValueSet={props.removeConceptToValueSet}
                                                   handleChange={props.handleChange}/>
                    </div>
                </div>
            }
            {/* TODO Filters  */}
        </div>
    );
};

export default VSIncludeCodeSummery;
