
const getRenderPrintStatus = () => {
    return false;
};
export const getRenderCountText = (renderCount) => {
    if(getRenderPrintStatus()){
        return "render count: " +  renderCount;
    } else {
        return "";
    }
};