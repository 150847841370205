import React from 'react';
import {ACCESS_TOKEN, REFRESH_TOKEN} from '../../constants';
import {useLocation, useNavigate} from 'react-router-dom'
import {getLogoutIdpUrl} from "../../TemporyIdpDetails";


export default function OAuth2RedirectHandler() {

    const search = useLocation().search;
    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const getDivContent = (token, error) => {
        if(error === undefined || error.trim() === ""){
            return (token ? navigate(location) : navigate("/"));
        }else {
            const url = getLogoutIdpUrl(error);
            window.location.href = url;
        }
    };


    const token = getUrlParameter('token');
    const refreshToken = getUrlParameter('refreshToken')
    const error = getUrlParameter('error');
    const location = getUrlParameter('location');

    if (token) {
        localStorage.setItem(ACCESS_TOKEN, token);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    const navigate = useNavigate();

    return (
        <div>
            {getDivContent(token, error)}
        </div>
    );
}
