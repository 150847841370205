import React from 'react';
import {getNestedObject} from "../CommonFunctions";

export const minErrorType = "min";
export const maxErrorType = "max";
export const requiredErrorType = "required";

export const matchesErrorType = "matches";


export const getErrorMessage = (name: string, methods: any) => {
    if (methods.formState.errors !== undefined && methods.formState.errors !== null) {
        const errorField = getErrorField(name, methods);
        //     console.log("getErrorMessage: name : " + name + " , errorFiled: " + inspect(errorField));
        const objParamaterMessage = errorField?.['message'];
        return objParamaterMessage;

    }
}

export const getErrorType = (name: string, methods: any) => {

    if (methods.formState.errors !== undefined && methods.formState.errors !== null) {
        const errorFiled = getErrorField(name, methods);
        const type = errorFiled?.['type'];
        //   console.log("getErrorMessage: name : " + name + " , getErrorType: " + type);
        return type;
    }
}


export const getErrorCode = (name: string, methods: any) => {

    if (methods.formState.errors !== undefined && methods.formState.errors !== null) {
        const errorFiled = getErrorField(name, methods);
        const code = errorFiled?.['code'];
        //   console.log("getErrorMessage: name : " + name + " , getErrorType: " + type);
        return code;
    }
}

function hasNumber(name: string) {
    return /\d/.test(name);
}


export const getErrorField = (name: string, methods: any) => {
    if (methods.formState.errors !== undefined && methods.formState.errors !== null) {
        const isNested: boolean = name.includes(".")
        const isArray: boolean = hasNumber(name);

        if (isNested == false && isArray == false) {
            // @ts-ignore
            return methods.formState.errors[name];
        } else {
            //methods.formState.errors?.['identifier']?.[0]?.['value']?.['message']
            const errorFiled = getErrorFieldFromArray(name, methods);
            return errorFiled;
        }
    }
}



const replaceArrayDotNotationWithBrackets = (str: string) => {
    const regex = new RegExp('[.]([^.&=]+)', 'g');
    const subst = `[$1]`;

// The substituted value will be contained in the result variable
    const result = str.replace(regex, subst);
    return result;
}

const getErrorFieldFromArray = (name: string, methods: any) => {
   return getNestedObject(methods.formState.errors, name)
}

