import React, {FC, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import RHookFormTextField from "../../common/rhookform/RHookFormTextField";
import {
    handlePerformedValidationAfterNewContent
} from "../validation/RHookFormValidationUtil";
import ITabName from "../page/ITabName";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../validation/ValidationInternalMetaDataWarningRulesUtil";
import {collectValidationInternalMetaDataYupErrorRules} from "../validation/ValidationInternalMetaDataYupErrorRulesUtil";

let renderCount = 0;

interface RHookFormTextFieldSpanProps {
    name: string;
    label: string;
    tabName?: ITabName;
    handleErrorMessageList?: any
    handleWarnMessageList?: any
    handleInfoMessageList?: any
    handleChangeEvent?: any
    rowindex?: string
    disabled: boolean
    enableValidationWhenFieldDisabled?: boolean
}

const RHookFormTextFieldSpan: FC<RHookFormTextFieldSpanProps> = ({
                                                                     name,
                                                                     label,
                                                                     tabName,
                                                                     handleErrorMessageList,
                                                                     handleWarnMessageList,
                                                                     handleInfoMessageList,
                                                                     handleChangeEvent,
                                                                     rowindex,
                                                                     disabled,
                                                                     enableValidationWhenFieldDisabled
                                                                 }) => {

        const methods = useFormContext();
        const [previousYupValidation, setPreviousYupValidation] = useState<string>();
        const arrayName = rowindex === undefined ? "" : name.substring(0, name.indexOf("."));

        useEffect(() => {
            if (tabName !== undefined) {
                collectValidationInternalMetaDataYupErrorRules(name, previousYupValidation, setPreviousYupValidation, handleErrorMessageList, methods, tabName, rowindex);
                removeValidationInternalMetaDataWarningFromValidationCollectionList(name, handleWarnMessageList, methods, tabName, undefined)
            }
            //methods.formState.errors?.['identifier']?.[0]?.['value']?.['message']

        }, [methods.formState.errors[name], methods.formState.errors[arrayName]]);


        const handleFieldChangeEvent = (event: any) => {
            if (tabName !== undefined) {
                handlePerformedValidationAfterNewContent(event, name, tabName, methods,
                    handleErrorMessageList, handleWarnMessageList, handleInfoMessageList);
            }

            if (handleChangeEvent !== undefined) {
                handleChangeEvent(event)
            }
        }

        renderCount++;

        return (
            <div onChange={(event) => handleFieldChangeEvent(event)}>
                <RHookFormTextField methods={methods} label={label} name={name} disabled={disabled}
                                    enableValidationWhenFieldDisabled={enableValidationWhenFieldDisabled}/>
            </div>
        );
    }
;

export default RHookFormTextFieldSpan;
