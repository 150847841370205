import ITabName from "../page/ITabName";
import {IRHookFormValidationMessage} from "../rhookform/IRHookFormValidationMessage";


export const createValidationNoteForMessageList = (label: string, value: string, tabName: ITabName, path?: string) => {
    let validationMessage : IRHookFormValidationMessage = {} as IRHookFormValidationMessage;
    validationMessage.path= path;
    validationMessage.label= label;
    validationMessage.value= value;
    validationMessage.tabName = tabName;
    return validationMessage;
};
