export function hasSpecialCharacters(string) {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(string);
}

export const hasWhiteSpace = (string) => {
    return /\s/g.test(string);
}

export function hasSwedishCharacters(string) {
    if (typeof string !== 'string' || !(string instanceof String)) {
        string = JSON.stringify(string);
    }

    if (string.includes("å") || string.includes("Å")) {
        return true;
    } else if (string.includes("ä") || string.includes("Ä")) {
        return true;
    } else if (string.includes("ö") || string.includes("Ö")) {
        return true;
    } else {
        return false;
    }
}


/*
Systemnamnet innehåller otillåtna tecken. Använd följande tecken:
        a-z, A-Z, 0-9, "-", ".", "_", "~"
        */
export function hasValidSystemNameCharacters(text) {
    const conditionRegex = /([^a-zA-Z0-9:\-\.\_\~\s])/g;
    const isValidSystemName = conditionRegex.test(text);
    return !isValidSystemName;
};