import axios from "axios";
import {ACCESS_TOKEN, LOGIN_API_BASE_URL, REFRESH_TOKEN} from '../constants';

const instance = axios.create({
    baseURL: LOGIN_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        return handleError(err);
    }
);


export const handleError = async (err) => {
    const originalConfig = err.config;

    if (originalConfig !== undefined &&
        (
            !originalConfig.url.startsWith("/user/refreshtoken") &&
            !originalConfig.url.startsWith("/oauth2") &&
            !originalConfig.url.startsWith("/test/login") &&
            err.response)
    ) {
        // Access Token was expired
        if (err.response.status === 401 && originalConfig._retry === undefined) {
            originalConfig._retry = false;

            try {
                const rs = await instance.post("/user/refreshtoken", {
                    refreshToken: localStorage.getItem(REFRESH_TOKEN),
                });

                const {accessToken} = rs.data;
                localStorage.setItem(ACCESS_TOKEN, accessToken);
                originalConfig._retry = false;
                return instance(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
    } else {
        originalConfig._retry = true;
        return Promise.reject(err);
    }
};


export default instance;
