import React, {useState} from "react";
import {Tabs, Tab, Grid} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import {
    useGmailTabsStyles,
    useGmailTabItemStyles,
} from '@mui-treasury/styles/tabs';
import {IDSButton, IDSButtonGroup, IDSLink, IDSIcon} from "@inera/ids-react";
import {codesystem, valueset, fhirUrl, SNOMED_CT_URL} from "../../../model/defaultvalues/Constant";
import Client from "fhir-kit-client";
import {defaultCodeSystemForValueSet} from "../../../model/defaultvalues/ValueSetDefaultFormValue";
import {a11yProps, TabPanel} from "../../common/tab/TabCommons";
import Search from "../search/Search";
import VSSelectableCodeSystemTable from "./VSSelectableCodeSystemTable";
import VSImportCodeSystemTabs from "./create/VSImportCodeSystemTabs";
import {TAB_TITLE_DEFAULT, TAB_VERSION_DEFAULT} from "./VSConstant";
import {isCodeSystemWithoutCodesInMetaDataObject} from "../../common/CommonFunctions";

const indicatorColors = ['#A33662', '#A33662', '#A33662', '#A33662'];

let renderCount = 0;

const VSTabsForCodeSystem = (props) => {
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[0]});
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const client = new Client({baseUrl: fhirUrl});


    const [fistEmptyTab, setFistEmptyTab] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    renderCount++;

    const isImportTab = (tabkey) => {
        return (props.tabList[tabkey].importtab === undefined || props.tabList[tabkey].importtab == false) ? false : true;
    }

    const setImportTab = (tabkey, isImportTab) => {
        let newArr = [...props.tabList]; // copying the old data array
        newArr[tabkey].importtab = isImportTab;
        props.setTabList(newArr);
    }

    const addTab = (importtab) => {
        let id = (props.tabList === undefined || props.tabList === null || props.tabList.length === 0) ? 0 : props.tabList[props.tabList.length - 1].id + 1;
        // let id = props.tabList.length;
        // props.tabList[props.tabList.length - 1].id + 1;
        addTabStep2(id, {
            key: id,
            id: id,
            title: TAB_TITLE_DEFAULT,
            version: TAB_VERSION_DEFAULT,
            system: undefined,
            loadSelectedCodeSystemForValueSet: false,
            importtab: (importtab === undefined || importtab == false) ? false : true
        });
    };

    const addTabStep2 = (id, tabHeader) => {
        props.setTabList([...props.tabList, tabHeader]);
        setTabValue(id);
    };

    const setLoadSelectedCodeSystemForValueSet = (tabKey, state) => {
        var array = [...props.tabList]; // make a separate copy of the array
        array[tabKey].loadSelectedCodeSystemForValueSet = state;
        props.setTabList(array);

    }

    const updateTab = (tabKey, title, version, system) => {
        if (version == undefined) {
            version = "-";
        }
        var array = [...props.tabList]; // make a separate copy of the array
        array[tabKey].title = title;
        array[tabKey].version = version;
        array[tabKey].system = system;
        props.setTabList(array);
    };


    const addImportStep = (tabKey, importStep, compleatedStep) => {

        let array = [...props.tabList]; // make a separate copy of the array
        let importstepsUndefined = array[tabKey].importsteps === undefined;

        if (importstepsUndefined) {
            array[tabKey].importsteps = [{importStep, compleatedStep}]
            props.setTabList(array);
        } else if (!importstepsUndefined && array[tabKey].importsteps[importStep] === undefined) {
            array[tabKey].importsteps[importStep] = {importStep, compleatedStep};
            props.setTabList(array);
        }

    };

    const deleteTab = e => {
        e.stopPropagation();

        //Always keep one tab
        /*   if (props.tabList.length === 1) {
               return;
           }*/

        let tabId = parseInt(e.target.id);
        const isNan = Number.isNaN(tabId);
        if (!isNan) {

            props.tabList.splice(tabId, 1); // 2nd parameter means remove one item only
            var newTabList = [...props.tabList]; // make a separate copy of the array

            for (let i = 0; i < newTabList.length; i++) {
                let tab = newTabList[i];
                tab.key = i;
                tab.id = i;
            }

            let tabIDIndex = 0;

            let curValue = parseInt(tabValue);

            if (
                //curValue === tabId &&
                newTabList.length > 0) {
                if (tabIDIndex == 0 && curValue > 0) {
                    curValue = curValue - 1;
                }
            } else if (newTabList.length == 0) {
                curValue = 0;
            }

            setTabValue(curValue);
            props.setTabList(newTabList);
            props.removeCodeSystemFromValueSet(tabId);
        } else {
            console.error("Tabnumber is not number(isNan), tabnumber: " + tabId);
        }
    };

    const addToSelectedCodeSystemForValueSet = (data) => {
            props.setCodeSystemsWithAllCodesSelectableCodes([
                ...props.codeSystemsWithAllCodesSelectableCodes,
                data
            ]);
    };

    const updateSelectedCodeSystemForValueSet = (data, tabkey) => {
        var array = [...props.codeSystemsWithAllCodesSelectableCodes]; // make a separate copy of the array
        array[tabkey] = data;
        props.setCodeSystemsWithAllCodesSelectableCodes(array);
    };

    const hasCodes = (codesystem) => {
        if (!isCodeSystemWithoutCodesInMetaDataObject(codesystem.url) && (codesystem.concept === undefined || codesystem.concept.length === 0)) {
            alert("Kodverket som du valt: " + codesystem.title + ", innehar inga koder")
            return false;
        } else {
            return true;
        }
    };


    const getVSSelectableCodeSystemTableOrAddCodesImportStep = (tabKey) => {
        const importTab = isImportTab(tabKey);
        let isImportStepOneComplete = false;
        if (importTab) {
            if (props.tabList[tabKey].importsteps !== undefined) {
                isImportStepOneComplete = (props.tabList[tabKey].importsteps[0] === undefined) ? false : true;
            }
        }

        //TODO if importTab IS ImportStepsCompleted?

        if ((importTab && isImportStepOneComplete) || !importTab) {
            var codesystem;
            //codeSystemsWithAllCodesSelectableCodes
            if (props.codeSystemsWithAllCodesSelectableCodes === undefined) {
                codesystem = [defaultCodeSystemForValueSet];
            } else {
                if (tabKey === undefined) {
                    codesystem = props.codeSystemsWithAllCodesSelectableCodes[0];
                } else {
                    codesystem = props.codeSystemsWithAllCodesSelectableCodes[tabKey];
                }
            }

            if (codesystem === undefined) {
                codesystem = [defaultCodeSystemForValueSet];
            }

            return (

                <div className={'container'}>

                    <VSSelectableCodeSystemTable
                        conceptsToAdd={props.conceptsToAdd}
                        setConceptsToAdd={props.setConceptsToAdd}
                        handleChange={props.handleChange}
                        tabKey={tabKey}
                        data={codesystem.concept}
                        removeConceptToValueSet={props.removeConceptToValueSet}
                        codesystem={codesystem}/>
                </div>
            );
        } else if (importTab
            //  && !isImportStepOneComplete
        ) {
            addImportStep(tabKey, 0, true);
            return (
                <div>
                </div>
            );
        }

        /*
        else if(importTab && isImportStepOneComplete){
              addImportStep(tabKey, 1, true);

              return (
                  <div>
                  </div>
              );
          }*/

    }


    /*       debugger

    //Set isImportStepOneComplete
    const importTab = isImportTab(tabKey);
    let isImportStepOneComplete = false;
    if (importTab) {
        if (props.tabList[tabKey].importsteps !== undefined) {
            isImportStepOneComplete = (props.tabList[tabKey].importsteps[0] === undefined) ? false : true;
        }
    }

    //TODO if importTab IS ImportStepsCompleted?

    if ((importTab && isImportStepOneComplete) || !importTab) {
        debugger
        var codesystem;
        if (props.codeSystemsWithAllCodesSelectableCodes === undefined  || props.codeSystemsWithAllCodesSelectableCodes.length == 0) {
            codesystem = [defaultCodeSystemForValueSet];
        } else {
            if (tabKey === undefined) {
                codesystem = props.codeSystemsWithAllCodesSelectableCodes[0];
            } else {
                codesystem = props.codeSystemsWithAllCodesSelectableCodes[tabKey];
            }
        }

        return (

            <div className={'container'}>

                <VSSelectableCodeSystemTable
                    conceptsToAdd={props.conceptsToAdd}
                    setConceptsToAdd={props.setConceptsToAdd}
                    handleChange={props.handleChange}
                    tabKey={tabKey}
                    data={codesystem.concept}
                    removeConceptToValueSet={props.removeConceptToValueSet}
                    codesystem={codesystem}/>
            </div>
        );
    } else if (importTab
        //  && !isImportStepOneComplete
    ) {
        debugger
        addImportStep(tabKey, 0, true);
        return (
            <div>
            </div>
        );
    }

    /!*
    else if(importTab && isImportStepOneComplete){
          addImportStep(tabKey, 1, true);

          return (
              <div>
              </div>
          );
      }*!/

}*/

    const getCodeSystemForValueSet = (id, version, tabkey, url) => {

        let codesystemAlreadySelectedIndex = undefined;

        for (let i = 0; i < props.codeSystemsWithAllCodesSelectableCodes.length; i++) {
            var codesystem = props.codeSystemsWithAllCodesSelectableCodes[i];
            if (codesystem.id === id) {
                codesystemAlreadySelectedIndex = i;
            }
        }

        if (id && codesystemAlreadySelectedIndex === undefined) {

            //   const isImportTab = (props.tabList[tabkey].importtab === undefined || props.tabList[tabkey].importtab == false) ? false : true;
            client.vread({resourceType: 'CodeSystem', id: id, version: version}).then((data) => {
                if (data && hasCodes(data)) {

                    if (props.codeSystemsWithAllCodesSelectableCodes[tabkey] != undefined) {
                        let newArr = [...props.codeSystemsWithAllCodesSelectableCodes]; // copying to new array
                        newArr[tabkey] = {
                            concept: data.concept,
                            id: data.id,
                            name: data.name,
                            resourceType: data.resourceType,
                            status: data.status,
                            title: data.title,
                            url: data.url,
                            version: data.version
                        };
                        props.setCodeSystemsWithAllCodesSelectableCodes(newArr);
                    } else {
                        addToSelectedCodeSystemForValueSet(data);
                    }

                    setLoadSelectedCodeSystemForValueSet(tabkey, true);

                    updateTab(tabkey, data.title, data.version, data.url);

                    if (props.conceptsToAdd[tabkey] != undefined) {
                        let newConceptsToAddArr = [...props.conceptsToAdd]; // copying to new array
                        newConceptsToAddArr[tabkey].id = data.id;
                        newConceptsToAddArr[tabkey].concept = [];
                        newConceptsToAddArr[tabkey].system = data.url;
                        newConceptsToAddArr[tabkey].version = data.version;
                        props.setConceptsToAdd(newConceptsToAddArr);
                    }
                }
            });

        } else {
            /*            setTabValue(codesystemAlreadySelectedIndex)
                        props.tabList[tabkey].importsteps = undefined;
                        props.tabList[tabkey].importtab = undefined;
                        props.removeCodeSystemFromValueSet(tabkey);

                        let newArr = [...props.tabList]; // copying the old datas array
                        props.setTabList(newArr);*/

            alert("Kodsystemet finns redan i Urvalet");

            //  props.tabList;
        }
    };

    const loadTabs = () => {
        /*      if (!props.initUpdate) {

                  if (fistEmptyTab) {
                      props.setTabList([
                          {
                              key: 0,
                              id: 0,
                              title: 'Kodverk',
                              version: '-',
                              system: undefined
                          }
                      ]);
                      setFistEmptyTab(false);
                  }
                  return loadTabsStep2();
              } else {*/

        let tabs = [];

        for (let i = 0; i < props.artifactArray.length; i++) {

            let version = "-";
            if (props.artifactArray[i].resource.version !== undefined) {
                version = props.artifactArray[i].resource.version;
            }


            let tab = {
                key: i,
                id: i,
                title: props.artifactArray[i].resource.title,
                version: version,
                system: props.artifactArray[i].resource.url
            }

            if (props.artifactArray[i].resource.version !== undefined) {
                tab.version = props.artifactArray[i].resource.version;
            }

            tabs.push(tab)
        }

        if (fistEmptyTab) {
            props.setTabList(tabs);
            setFistEmptyTab(false);
        }
        return loadTabsStep2();
        /* }*/
    }

    const getValueSetCodeSystemIds = () => {
        let codeSystemIds = [];
        if (props.codeSystemsWithAllCodesSelectableCodes !== undefined) {
            for (let i = 0; i < props.codeSystemsWithAllCodesSelectableCodes.length; i++) {
                codeSystemIds.push(props.codeSystemsWithAllCodesSelectableCodes[i].id)
            }
        }
        return codeSystemIds;
    }

    const loadTabsStep2 = () => {


        return (

            <div>
                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                        <Tabs
                            classes={tabsStyles}
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                children: <div className={`MuiIndicator-${tabValue}`}/>,
                            }}
                        >
                            {props.tabList.map(tab => (
                                <Tab
                                    key={tab.key.toString()}
                                    value={tab.id}
                                    label={
                                        <div className={'MuiTabItem-labelGroup'}>
                                            <div className={'MuiTabItem-label'}>
                                                <strong>{tab.title}</strong>
                                                {/*<span className={'MuiTabItem-tag'}>Codesystem</span>*/}
                                            </div>
                                            <div
                                                className={'MuiTabItem-subLabel'}>Version: <strong>{tab.version}</strong>
                                            </div>
                                        </div>}
                                    icon={
                                        <Close id={tab.id} onClick={deleteTab} style={{width: '25px'}}/>}
                                    /* className="mytab"*/
                                    classes={tabItem2Styles}
                                    {...a11yProps(tab.key.toString())}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                        <IDSButtonGroup>
                            <IDSLink underlined="true" size="1em">
                                <IDSIcon name={"plus"} size={"xs"}/>
                                <a href="#" onClick={() => {
                                    addTab();
                                }}>
                                    LÄGG TILL KODVERK</a>
                            </IDSLink>
                        </IDSButtonGroup>
                    </Grid>
                </Grid>


                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {props.tabList.map((tab, ix) => (
                            <div>
                                <TabPanel value={tabValue} index={tab.key}>

                                    <div>
                                        {(tab.importtab === undefined || tab.importtab == false) ? (
                                            <div>
                                                <IDSButtonGroup style={{
                                                    display: 'flex',
                                                    justifyContent: 'right',
                                                    alignItems: 'right'
                                                }}>
                                                    <IDSButton secondary onClick={() => setImportTab(ix, true)}
                                                               size="s">
                                                        IMPORTERA URVAL FRÅN FIL
                                                    </IDSButton>
                                                </IDSButtonGroup>

                                                <Search
                                                    selectedCodeSystemForValueSet={props.codeSystemsWithAllCodesSelectableCodes[tabValue]}
                                                    setSelectedCodeSystemForValueSetIndex={ix}
                                                    loadSelectedCodeSystemForValueSet={tab.loadSelectedCodeSystemForValueSet}
                                                    setLoadSelectedCodeSystemForValueSet={setLoadSelectedCodeSystemForValueSet}
                                                    searchForCodeSystem={true} addConcept={props.addConcept}
                                                    artifactType={codesystem}
                                                    belongsTo={valueset}
                                                    removeConceptToValueSet={props.removeConceptToValueSet}
                                                    tabKey={tab.key}
                                                    getCodeSystemForValueSet={getCodeSystemForValueSet}
                                                    getCodeSystemForValue={getVSSelectableCodeSystemTableOrAddCodesImportStep}
                                                    getValueSetCodeSystemIds={getValueSetCodeSystemIds}/>
                                            </div>
                                        ) : (

                                            <div>
                                                <IDSButtonGroup style={{
                                                    display: 'flex',
                                                    justifyContent: 'right',
                                                    alignItems: 'right'
                                                }}>
                                                    <IDSButton secondary onClick={() => setImportTab(ix, false)}
                                                               size="s">
                                                        AVBRYT IMPORTERA
                                                    </IDSButton>
                                                </IDSButtonGroup>
                                                <br/>

                                                <VSImportCodeSystemTabs
                                                    tabList={props.tabList}
                                                    setTabList={props.setTabList}
                                                    codeSystemsWithAllCodesSelectableCode={props.codeSystemsWithAllCodesSelectableCodes[tabValue]}
                                                    updateSelectedCodeSystemForValueSet={updateSelectedCodeSystemForValueSet}
                                                    setSelectedCodeSystemForValueSetIndex={ix}
                                                    loadSelectedCodeSystemForValueSet={tab.loadSelectedCodeSystemForValueSet}
                                                    setLoadSelectedCodeSystemForValueSet={setLoadSelectedCodeSystemForValueSet}
                                                    searchForCodeSystem={true}
                                                    addConcept={props.addConcept}
                                                    artifactType={codesystem}
                                                    belongsTo={valueset}
                                                    removeConceptToValueSet={props.removeConceptToValueSet}
                                                    tabKey={tabValue}
                                                    getCodeSystemForValueSet={getCodeSystemForValueSet}
                                                    getCodeSystemForValue={getVSSelectableCodeSystemTableOrAddCodesImportStep}
                                                    conceptsToAdd={props.conceptsToAdd}
                                                    setConceptsToAdd={props.setConceptsToAdd}
                                                    getValueSetCodeSystemIds={getValueSetCodeSystemIds}/>
                                            </div>
                                        )}
                                    </div>
                                </TabPanel>
                            </div>
                        ))}

                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <div>{loadTabs()}</div>
    );
};

export default VSTabsForCodeSystem;
