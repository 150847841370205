import {uniformResourceIdentifier, urnOID, urnUUID} from "../../../model/defaultvalues/Constant";
import {
    formUUID,
    formOID,
    emptyIndentifierValues,
    emptyIndentifierFormValues, identifierList
} from "../../../model/defaultvalues/MetaDataDefaultFormValue";


export function translateIdentifierToUrn(data: any) {
    if (JSON.stringify(data.identifier) === "[null]") {
        data.identifier = identifierList

    } else if (data.identifier !== undefined && Array.isArray(data.identifier)) {
        for (let i = 0; i < data.identifier.length; i++) {
            if (data.identifier[i].system === formUUID) {
                if (data.identifier[i].value.startsWith(urnUUID) == false) {
                    data.identifier[i].value = urnUUID + data.identifier[i].value
                }
                data.identifier[i].system = uniformResourceIdentifier;
            } else if (data.identifier[i].system === formOID) {
                if (data.identifier[i].value.startsWith(urnOID) == false) {
                    data.identifier[i].value = urnOID + data.identifier[i].value
                }
                data.identifier[i].system = uniformResourceIdentifier;
            } else if (data.identifier[i] === emptyIndentifierFormValues) {
                data.identifier[i] = undefined
            } else if (data.identifier[i].value === "" || data.identifier[i].value === undefined) {
                data.identifier[i] = undefined
            }
        }
    }
}

export function translateIdentifierFromUrn(data: any, editDisabled?: boolean) {
    //  debugger;
    if (JSON.stringify(data.identifier) === "[null]") {
        data.identifier = undefined;
    }
    if (data.identifier !== undefined && Array.isArray(data.identifier)) {

        for (let i = 0; i < data.identifier.length; i++) {
            //  debugger;
            if ((data.identifier[i].value !== undefined) && data.identifier[i].value.startsWith(urnUUID) == true) {
                data.identifier[i].value = data.identifier[i].value.substring(urnUUID.length)
                data.identifier[i].system = formUUID;
            } else if ((data.identifier[i].value !== undefined) && data.identifier[i].value.startsWith(urnOID) == true) {
                data.identifier[i].value = data.identifier[i].value.substring(urnOID.length)
                data.identifier[i].system = formOID;
            } else if (JSON.stringify(data.identifier[i]) === JSON.stringify({"system": ""})) {
                data.identifier = [emptyIndentifierFormValues];
            }
        }
    } else if (editDisabled !== undefined && editDisabled == false) {
        data.identifier = [emptyIndentifierFormValues];
    } else if (data.identifier === undefined) {
        data.identifier = [emptyIndentifierValues];
    }
    return data;
}


export const getIdentifierValueText = (value: string) => {

    if (value.startsWith(urnOID)) {
        return value.substring(8);
    } else if (value.startsWith(urnUUID)) {
        return value.substring(9);
    } else {
        return value;
    }
};


export const getIdentifierSystemFormValue = (identifier: any) => {

    if (identifier !== undefined) {

        if (identifier.value !== undefined && identifier.value.startsWith(urnOID)) {
            return formOID
        } else if (identifier.value !== undefined && identifier.value.startsWith(urnUUID)) {
            return formUUID
        } else if (identifier.system !== undefined) {
            return identifier.system;
        } else {
            return "";
        }
    } else {
        return "";
    }
};

export const transformSearchResponseEntryIdentifierText = (entry: any) => {
    for (let item of entry) {
        if ((item.resource?.identifier !== undefined) &&
            (item.resource?.identifier[0] !== undefined) &&
            (item.resource?.identifier[0].value !== undefined)) {

            for (let i = 0; i < item.resource?.identifier.length; i++) {
                let identifier = item.resource?.identifier[i];

                let valueText = getIdentifierValueText(identifier.value);
                let systemText = getIdentifierSystemFormValue(identifier);

                item.resource.identifier[i].value = valueText;
                item.resource.identifier[i].system = systemText;
            }
        }
    }
};

