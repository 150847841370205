import React from 'react';

import {
    codeDuplicateCaseSensitiveWarn, codeWhiteSpaceInfo,
} from "../meta/MetaWarnInfoConstant";
import {codeHasDuplicateValidation} from "./CodeMultiLevelValidation";
import {hasWhiteSpace} from "../../common/CommonValidationFunctions";
import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {contentTabName} from "../../common/page/TabName";

const arrayname = "concept"

export const infoValidateCode = (value: string, prevValue: string, rowindex: string, infoMessageList: [], handleInfoErrorMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {
    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        if (handleInfoErrorMessageList != undefined) {
            handleInfoErrorMessageList(null, codeDuplicateCaseSensitiveWarn, rowindex);
        }
    } else {

        let validationResults = []

        let whiteSpaceValidationResult = codeHasWhiteSpaceValidation(value, rowindex, infoMessageList, handleInfoErrorMessageList, methods);
        let whiteSpaceValidation = {"label": codeWhiteSpaceInfo, "message": whiteSpaceValidationResult}
        validationResults.push(whiteSpaceValidation);



        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }

        let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
        if (validationMessage !== "") {
            rowConceptToValidate.codeInfo = validationMessage;
            return validationMessage;
        } else {
            rowConceptToValidate.codeInfo = undefined;
            return false;
        }

    }
}

const codeHasWhiteSpaceValidation = (value: string, rowindex: string, errorMessageList: [], handleErrorMessageList: any, methods: any) => {
    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
        let message = "Skriv kod utan mellanslag. Terminologitjänsten tillåter undantagsfall kod med mellanslag bara för att stödja system som redan har beroenden till koden."
        let validationResult = value !== undefined && ((value !== "" && hasWhiteSpace(value)) && message);
        let validationmessage = collectValidationMessage(message, codeWhiteSpaceInfo, validationResult, handleErrorMessageList, contentTabName, rowindex);
        return validationmessage;
    } else {
        return false;
    }
}