import React, {useEffect, useMemo, useRef, useState} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyCellPropsStyle,
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';

import {IDSIcon, IDSLink} from "@inera/ids-react";
import {getHierarchyAsString} from "../../../common/rhookform/RHookFormArrayHierarchyFunction";
import {ICodeSystemConcept, Parameters} from "fhir-typescript-models/dist/src/models/fhir/internal";
import TextExpandable from "../../../common/rhookform/TextExpandable";
import Client from "fhir-kit-client";
import {fhirUrl} from "../../../../model/defaultvalues/Constant";


let renderCount = 0;
const fhirClient = new Client({baseUrl: fhirUrl});

const ConcepICD10 = (props: any) => {


    const arrayname = "concept"
    const tableRef = useRef();
    const [conceptCollection, setConceptCollection] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);

    const lookupCodeSystemCode = async (code: string, conceptArray: []) => {


        await fhirClient.request("CodeSystem/$lookup?code=" + code +
            "&system=" + props.url + "&version=" + props.version + "&property=*"
        )
            .then(response => {

                let responseParameters: Parameters = response as unknown as Parameters;

                let responseCode = undefined;
                let responseDisplay = undefined;
                let definition = undefined;
                let childCodes = [];
                let includes = [];
                let excludes = [];
                let notes = [];
                for (const element of responseParameters.parameter!) {
                    // @ts-ignore
                    if (element.name !== undefined && element.name === "code") {
                        // @ts-ignore
                        responseCode = element.valueCode;
                    }
                    // @ts-ignore
                    else if (element.name !== undefined && element.name === "display") {
                        // @ts-ignore
                        responseDisplay = element.valueString;
                    }
                    // @ts-ignore
                    else if (element.name === "property") {


                        // @ts-ignore
                        if (element.part[0].valueCode === "child" && element.part[0].name === "code" && element.part[1].name === "value"
                        ) {
                            // @ts-ignore
                            childCodes.push({code: element.part[1].valueCode, display: "", definition: ""});
                        }

                        // @ts-ignore
                        else if (element.part[0].valueCode === "definition" && element.part[0].name === "code"
                        ) {
                            // @ts-ignore
                            definition = element.part[1].valueCode;
                        }

                        // @ts-ignore
                        else if (element.part[0].valueCode === "note" && element.part[0].name === "code"
                        ) {
                            // @ts-ignore
                            notes.push(element.part[1].valueCode);
                        }

                        // @ts-ignore
                        else if (element.part[0].valueCode === "exclusion" && element.part[0].name === "code"
                        ) {
                            // @ts-ignore
                            excludes.push(element.part[1].valueString);

                        }
                        // @ts-ignore
                        else if (element.part[0].valueCode === "inclusion" && element.part[0].name === "code"
                        ) {
                            // @ts-ignore
                            includes.push(element.part[1].valueString);
                        }
                    }
                }


                const excludesAsString = excludes.join("\r\n\r\n")
                const includesAsString = includes.join("\r\n\r\n")
                const notesAsString = notes.join("\r\n")

                // @ts-ignore
                const concept = {
                    // @ts-ignore
                    code: responseCode,
                    // @ts-ignore
                    display: responseDisplay,
                    // @ts-ignore
                    definition: definition,
                    // @ts-ignore
                    concept: childCodes,
                    // @ts-ignore
                    includes: includesAsString,
                    // @ts-ignore
                    excludes: excludesAsString,
                    // @ts-ignore
                    notes: notesAsString
                }

                if (conceptArray !== undefined) {
                    // @ts-ignore
                    conceptArray.push(concept);
                }


            })
            .catch(e => {
                console.error("FhirClient ICD handleRowClickShowConceptData Error Response" + JSON.stringify(e));
            });

    };

    useEffect(() => {
        // @ts-ignore
        //   setConcept(oldArray => [...oldArray, concept]);

        if (props.methods.getValues(arrayname) === undefined || props.methods.getValues(arrayname).length == 0) {
            let codes = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
                "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"];

            getSetConcepts(codes);
        }

    }, []);


    const getSetConcepts = async (codes: string[]) => {
        // @ts-ignore
        let concepts = await getConcepts(codes);

        // @ts-ignore
        props.methods.setValue(arrayname, concepts)
        // @ts-ignore
        setConceptCollection(concepts);
        //    setConcept(concepts)
        //  setConcept(oldArray => [...oldArray, concept]);

    }

    const getConcepts = async (codes: string[]) => {
        setLoadingRows(true);
        // @ts-ignore
        let concepts = [];
        for (let code of codes) {
            // @ts-ignore
            await lookupCodeSystemCode(code, concepts);
        }
        setLoadingRows(false);
        // @ts-ignore
        return concepts;

    }


    renderCount++;

    /*   const resetAsyncForm = useCallback(async () => {
           setConceptHierarchy(getConceptHierarchy(props.data));
       }, []);*/

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true
        },
        {
            accessorKey: 'notes',
            header: 'Anmärkningar',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <span><TextExpandable
                    text={cell.getValue<string>() === undefined ? "" : cell.getValue<number>().toLocaleString()}/></span>
            )
        },
        {
            accessorKey: 'includes',
            header: 'Innefattar',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <span><TextExpandable
                    text={cell.getValue<string>() === undefined ? "" : cell.getValue<number>().toLocaleString()}/></span>
            )
        },
        {
            accessorKey: 'excludes',
            header: 'Utesluter',
            // @ts-ignore
            enableColumnFilters: true,
            Cell: ({cell}) => (
                // @ts-ignore
                <span><TextExpandable
                    text={cell.getValue<string>() === undefined ? "" : cell.getValue<number>().toLocaleString()}/></span>
            )
        },
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    // @ts-ignore
    const getSubConcepts = async (conceptsWithCodes: [], index: string) => {
        const hierarchyAsString = getHierarchyAsString(index, arrayname)
        //   const myArray = index.split(".");


        // @ts-ignore
        let codes: [] = conceptsWithCodes.map(a => a.code);
        let subRowConcepts = await getConcepts(codes);

        await props.methods.setValue(hierarchyAsString + "." + arrayname, subRowConcepts);


        // @ts-ignore
        const clonedArray = props.methods.getValues(arrayname).slice();
        // @ts-ignore
        await setConceptCollection(clonedArray);
    }

    renderCount++;

    return (
        <div>
            <div>
                <>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        data={conceptCollection}
                        enableRowOrdering={false}
                        enableColumnFilters={true}
                        enableSorting={false}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableSelectAll={true}
                        enableEditing={false}
                        enableHiding={false}
                        editingMode="table"
                        enableRowActions
                        positionActionsColumn="last"
                        enableExpanding
                        enableExpandAll //default
                        tableInstanceRef={tableInstanceRef}
                        state={{isLoading: loadingRows}}


                        muiExpandButtonProps={({row, table}) => ({

                            onClick: () => {
                                let rowrow = row;
                                let tabletable = table;

                                // @ts-ignore
                                getSubConcepts(row.originalSubRows, row.id)
                            }
                        })}


                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsFiveColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}

                        // @ts-ignore
                        getSubRows={(originalRow, index) => {
                            return (
                                originalRow.concept
                            )
                        }}

                        muiTableHeadCellColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}

                        muiTableBodyCellProps={{
                            sx: muiTableBodyCellPropsStyle
                        }}

                    />
                </>
            </div>

            <div className={'container'}>


                {(!props.editDisabled && props.appendNewRow !== undefined) &&
                    <div>
                        <IDSLink color="green" underlined={true}>
                            <IDSIcon name="plus"></IDSIcon>
                            <a role="button" onClick={() => {
                                props.appendNewRow({
                                    code: "",
                                    display: "",
                                    definition: ""
                                });
                            }}>Lägg till rad</a>
                        </IDSLink>
                    </div>
                }

                <br/>
            </div>
        </div>
    );
};

export default ConcepICD10;
