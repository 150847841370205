import React from 'react';

import {codeDuplicateCaseSensitiveWarn,
} from "../meta/MetaWarnInfoConstant";
import {codeHasDuplicateValidation} from "./CodeMultiLevelValidation";

const arrayname = "concept"

export const warnValidateCode = (value: string, prevValue: string, rowindex: string, warnMessageList: [], handleWarnErrorMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {
    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        if (handleWarnErrorMessageList != undefined) {
            handleWarnErrorMessageList(null, codeDuplicateCaseSensitiveWarn, rowindex);
        }
    } else {

        let validationResults = []

        let codeDuplicateValidationResult = codeHasDuplicateValidation(value, prevValue, rowindex, false, handleWarnErrorMessageList, methods);
        let codeDuplicateValidation = {
            "label": codeDuplicateCaseSensitiveWarn,
            "message": codeDuplicateValidationResult
        }
        validationResults.push(codeDuplicateValidation);


        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }

        let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
        if (validationMessage !== "") {
            rowConceptToValidate.codeWarn = validationMessage;
            return validationMessage;
        } else {
            rowConceptToValidate.codeWarn = undefined;
            return false;
        }

    }
}
