import React from 'react';

import {conceptmap} from "../../../../../model/defaultvalues/Constant";
import Search from "../../../search/Search";
import CMTableSource from "../table/CMTableSource";


let renderCount = 0;


const CMDefineGroupRightPanel = (props: any) => {

    renderCount++;

    const getDomContent = () => {

        if (props.initTabSource) {
            return (
                <div>
                    <br/><br/>
                    <CMTableSource
                        sourceTableData={props.sourceTableData}
                        setSourceTableData={props.setSourceTableData}
                        setLastTarget={props.setLastTarget}
                        hoveredTable={props.hoveredTable}
                        setHoveredTable={props.setHoveredTable}
                        lastTableSourceRowDroppedOn={props.lastTableSourceRowDroppedOn}
                        setLastTableSourceRowDroppedOn={props.setLastTableSourceRowDroppedOn}
                        draggingRow={props.draggingRow}
                        targetRowSelectionData={props.targetRowSelectionData}
                        /* cleanTargetRowSelection={cleanTargetRowSelection()}*/
                        sourceRowSelections={props.sourceRowSelections}
                        setSourceRowSelections={props.setSourceRowSelections}
                        sourceTableInstanceRef={props.sourceTableInstanceRef}
                        setTargetRowSelection={props.setTargetRowSelection}
                        methods={props.methods}
                    /><br/><br/></div>);
        } else if (props.sourceTableData.length === 0) {
            return (
                <Search
                    hideFilter={true}
                    getCodeSystemForArtifact={props.getCodeSystemForArtifact}
                    artifactTypeChoice={props.artifactTypeChoice}
                    searchForCodeSystem={true} addConcept={props.addConcept}
                    /*  artifactType={conceptmap}*/
                    belongsTo={conceptmap}
                    removeConceptToValueSet={props.removeConceptToValueSet}
                    tabKey={props.searchCol}/>);
        } else if (props.sourceTableData.length !== 0 && props.initTabTarget && props.targetTableData.length === 0) {
            return (
                <Search
                    hideFilter={true}
                    getCodeSystemForArtifact={props.getCodeSystemForArtifact}
                    artifactTypeChoice={props.artifactTypeChoice}
                    searchForCodeSystem={true} addConcept={props.addConcept}
                    /*      artifactType={conceptmap}*/
                    belongsTo={conceptmap}
                    removeConceptToValueSet={props.removeConceptToValueSet}
                    tabKey={props.searchCol}/>);
        } else {

            return (
                <div>
                    <br/><br/>
                    <CMTableSource
                        sourceTableData={props.sourceTableData}
                        setSourceTableData={props.setSourceTableData}
                        setLastTarget={props.setLastTarget}
                        hoveredTable={props.hoveredTable}
                        setHoveredTable={props.setHoveredTable}
                        lastTableSourceRowDroppedOn={props.lastTableSourceRowDroppedOn}
                        setLastTableSourceRowDroppedOn={props.setLastTableSourceRowDroppedOn}
                        draggingRow={props.draggingRow}
                        targetRowSelectionData={props.targetRowSelectionData}
                        setTargetRowSelectionData={props.setTargetRowSelectionData}
                        sourceRowSelections={props.sourceRowSelections}
                        setSourceRowSelections={props.setSourceRowSelections}
                        sourceTableInstanceRef={props.sourceTableInstanceRef}
                        methods={props.methods}
                    />

                </div>

            );
        }


    }

    return (
        <div>
            {getDomContent()}
        </div>
    );

};

export default CMDefineGroupRightPanel;
