import React, {useState} from "react";
import {IDSStepper, IDSStep} from "@inera/ids-react";
import Search from "../../search/Search";
import ImportExcelVS from "../../../../services/excel/ImportExcelVS";
import {TAB_TITLE_DEFAULT, TAB_VERSION_DEFAULT} from "../VSConstant";
import {isSnomed} from "../../../../codesystem/snomed/SNOMEDUtil";

let renderCount = 0;

const VSImportCodeSystemTabs = (props) => {

    const stepOneHeadline = "Ange den källa som urvalet är baserat på";
    const stepTwoHeadline = "Välj en excel fil att importera";

    const isTabCodeSystemSelected = (props.tabList[props.tabKey].title === TAB_TITLE_DEFAULT && props.tabList[props.tabKey].version === TAB_VERSION_DEFAULT) ? false : true;
    const isStepOneComplete = (!isTabCodeSystemSelected || props.tabList[props.tabKey].importsteps === undefined) ? false : true;
    const isStepTwoComplete = (props.tabList[props.tabKey] === undefined || props.tabList[props.tabKey].importsteps !== undefined) && (props.tabList[props.tabKey].importsteps[1] === undefined) ? false : true;

    const [showImport, setShowImport] = useState(true);

    const setImportStateFalse = () => {
        var array = [...props.tabList]; // make a separate copy of the array
        array[props.tabKey].importtab = false;
        props.setTabList(array);

    }

    const setSelected = (selected, code, conceptArray) => {
        return conceptArray.map(concept => {
            return concept.code === code ? {...concept, selected: selected} : concept
        })
    }

    const addCodesToCodeSystemsWithAllCodesSelectableCode_selectedWithoutDuplicates = (collection) => {
        for (let c of collection) {
            //add if not already existing
            if (props.codeSystemsWithAllCodesSelectableCode.concept.find(obj => {return obj.code === c.code}) === undefined) {
                props.codeSystemsWithAllCodesSelectableCode.concept.push(c);
            }else {
                modifySelectedConceptInArray(props.codeSystemsWithAllCodesSelectableCode.concept, c.code, c.display);
            }
        }
    }

    const modifySelectedConceptInArray = (arr, code, display) => {
        const targetObj = arr.find(obj => obj.code === code);
        if (targetObj) {
            targetObj.selected = true;
            targetObj.display = display;
        }
    };

    const addCodes = (data) => {
        const isSnomedCT = isSnomed(props.codeSystemsWithAllCodesSelectableCode.url);
        if (!isSnomedCT && props.codeSystemsWithAllCodesSelectableCode !== undefined) {
            for (let d of data) {
                props.codeSystemsWithAllCodesSelectableCode.concept.find(p =>
                    (p.code === d.code) && (p.selected = true, true) && (p.display === d.display)
                );
            }
        }else {
            let collection = [];
            for (let d of data) {
                d.selected = true;
                collection.push(d);
            }

            if(props.codeSystemsWithAllCodesSelectableCode.concept === undefined){
                props.codeSystemsWithAllCodesSelectableCode.concept = [];
            }

            addCodesToCodeSystemsWithAllCodesSelectableCode_selectedWithoutDuplicates(collection);
            props.updateSelectedCodeSystemForValueSet(props.codeSystemsWithAllCodesSelectableCode, props.tabKey);
        }

        //  ConceptsToAdd
        if (props.codeSystemsWithAllCodesSelectableCode.concept !== undefined) {
            props.addConcept(data, props.codeSystemsWithAllCodesSelectableCode.url, props.codeSystemsWithAllCodesSelectableCode.version, undefined, props.tabKey, props.codeSystemsWithAllCodesSelectableCode.title);
        }

        setImportStateFalse();
        setShowImport(false);
    }

    renderCount++;

    return (

        <div>
            {showImport && <IDSStepper>
                <IDSStep headline={stepOneHeadline} expanded={!isStepOneComplete} disabled={isTabCodeSystemSelected}
                         state={isStepOneComplete ? "valid" : "selected"}>
                    <Search
                        selectedCodeSystemForValueSet ={props.codeSystemsWithAllCodesSelectableCode}
                        loadSelectedCodeSystemForValueSet={props.loadSelectedCodeSystemForValueSet}
                        setLoadSelectedCodeSystemForValueSet={props.setLoadSelectedCodeSystemForValueSet}
                        searchForCodeSystem={props.searchForCodeSystem} addConcept={props.addConcept}
                        artifactType={props.artifactType}
                        belongsTo={props.belongsTo}
                        tabKey={props.tabKey}
                        getCodeSystemForValueSet={props.getCodeSystemForValueSet}
                        getCodeSystemForValue={props.getCodeSystemForValue}
                        getValueSetCodeSystemIds={props.getValueSetCodeSystemIds}/>
                </IDSStep>
                <IDSStep headline={stepTwoHeadline} disabled={!isStepOneComplete ? true : false}
                         state={isStepOneComplete ? "selected" : ""}
                         expanded={isStepOneComplete}>
                    <ImportExcelVS
                        artifactType={props.artifactType}
                        addCodes={addCodes}
                        belongsTo={props.belongsTo}
                        codeSystemsWithAllCodesSelectableCode={props.codeSystemsWithAllCodesSelectableCode}
                    />
                </IDSStep>
            </IDSStepper>}
        </div>
    );
};

export default VSImportCodeSystemTabs;
