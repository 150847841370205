import React from "react";
import {Extension} from "fhir-typescript-models";
import {UseFormReturn} from "react-hook-form";
import {PrimitiveString, PrimitiveUri} from "fhir-typescript-models/dist/src/models/fhir/internal";


export const hasExtension = (methods: UseFormReturn, extensionUrl: string) => {

    let hasExtension = false;
    const extensions: any[] = methods.getValues("extension")
    for (let extension of extensions) {
        if (extension.system !== undefined && extension.system === extensionUrl) {
            hasExtension = true;
            break;
        }
    }
    return hasExtension;
}

export const getExtensionArrayIndex = (methods: UseFormReturn, extensionUrl?: string) => {

    var index = methods.getValues("extension").length;

    if (index === undefined) {
        index = 0;
    } else if (index !== 0) {
        //Search id where there is already an extension with the same url, if so get the index
        for (let i = 0; i < methods.getValues("extension").length; i++) {
            if (methods.getValues("extension")[i].url === extensionUrl) {
                index = i;
            }
        }
    }
    return index;
}


export const getExtensionIndexForAnyParameterType = (extensionUrl: string, fields?: any[], createExtensionForParameterType?: any) => {

    var extensionIndex = undefined;

    // If there is no extensions, create one extension for the specific parameterType, and return index
    let extensionArrayLength = fields === undefined ? 0 : fields.length;
    if (fields === undefined || extensionArrayLength === 0) {
        let extensionArray: Extension[] = [];
        const extension: Extension = createExtensionForParameterType(extensionUrl);
        extensionArray.push(extension);
        extensionIndex = 0;
    }
    // Find out if there is an existing extension for the parameterType, return that index if found
    else {
        let extensionFound = false;
        for (let i = 0; i < fields.length; i++) {

            const extension = fields[i];

            if (extension.url === extensionUrl) {
                extensionIndex = i;
                extensionFound = true;
            }
        }

        if (!extensionFound) {
            // When no existing extension for specific parameterType is found in extension list,
            // return the new extension's index (to be used when creating the new Extension)
            extensionIndex = fields.length;
        }
    }

    return extensionIndex;
}

export const createExtensionValueString = (extensionUrl: string) => {

    const extension: Extension = new Extension();

    const primitiveUri: PrimitiveUri = new PrimitiveUri();
    primitiveUri.value = extensionUrl;

    extension.url = primitiveUri;

    const valueString: PrimitiveString = new PrimitiveString();
    valueString.value = "";

    extension.value = valueString;

    return extension;
}