import React from "react";
import {IDSButton} from "@inera/ids-react";
import CMTableTarget from "../table/CMTableTarget";
import {useFormContext} from "react-hook-form";


const TargetTab = (props) => {

    const methods = useFormContext();
    const changeTarget = () => {
        /*  props.initTabSource ? props.setInitTabSource(false): props.setInitTabSource(true)*/

        let sourcetabledataCopy = props.sourceTableData.slice();
        for (let i = 0; i < sourcetabledataCopy.length; i++) {
            let row = sourcetabledataCopy[i];
            row.target = undefined;
            methods.setValue("group[0].element[" + i + "].target", undefined);
        }
        props.setSourceTableData(sourcetabledataCopy);


        if (props.initTabTarget) {
            props.setInitTabTarget(false);
        }

        if (!props.initTabTarget) {
            props.setInitTabTarget(true);
        }
        if (props.setTargetTableData !== undefined) {
            props.setTargetTableData([]);
        }
    };

    if (!props.initTabTarget) {
        return (

            <div>
                <IDSButton size='s' secondary={true} onClick={() => {
                    props.setInitTabTarget(true);
                }}>
                    ANGE MÅL
                </IDSButton>
            </div>
        );
    } else if (props.initTabTarget && props.targetTableData.length === 0) {
        return (

            <div>
                <IDSButton size='s' secondary={true} onClick={() => {
                    changeTarget();
                }}>
                    AVBRYT
                </IDSButton>
            </div>
        );
    } else if (props.initTabTarget && props.targetTableData !== undefined) {
        //  setInitTabTarget(false);
        return (
            <div>
                <div className={'right'}>
                    <ids-link activeicon="">
                        <ids-icon name="arrow"></ids-icon>
                        <a href="#" onClick={() => {
                            changeTarget();
                        }}>Ändra mål</a>
                    </ids-link>
                </div>
                <br/>
                <CMTableTarget
                    targetTableData={props.targetTableData}
                    sourceTableData={props.sourceTableData}
                    setTargetTableData={props.setTargetTableData}
                    setSourceTableData={props.setSourceTableData}
                    setHoveredTable={props.setHoveredTable}
                    hoveredTable={props.hoveredTable}
                    lastTarget={props.lastTarget}
                    setLastTarget={props.setLastTarget}
                    lastTableSourceRowDroppedOn={props.lastTableSourceRowDroppedOn}
                    draggingRow={props.draggingRow}
                    setDraggingRow={props.setDraggingRow}
                    targetRowSelection={props.targetRowSelection}
                    setTargetRowSelection={props.setTargetRowSelection}
                    targetRowSelectionData={props.targetRowSelectionData}
                    setTargetRowSelectionData={props.setTargetRowSelectionData}
                    sourceRowSelections={props.sourceRowSelections}
                    setSourceRowSelections={props.setSourceRowSelections}
                />
            </div>
        );
    }
};

export default TargetTab;
