import React, {FC} from "react";
import {
    extensionRetireReasonSystemUrl,
} from "../../../model/defaultvalues/Constant";
import {publishTabName} from "../../common/page/TabName";
import RHookFormTextAreaExtensionValueString from "../../common/rhookform/RHookFormTextAreaExtensionValueString";

let renderCount = 0;

interface RetiredDescriptionProps {
}


const RetireReason: FC<RetiredDescriptionProps> = ({
                                                   }) => {

    renderCount++;

    const retiredDescriptionValidation = (value: string) => {
        //Nothing to validate here
        return false;
    }

    return (
        <RHookFormTextAreaExtensionValueString
            label={"Orsak till avställning (obligatorisk)"}
            editDisabled={false}
                              tabName={publishTabName}
                              extensionSystemUrl={extensionRetireReasonSystemUrl}
                              warnValidation={retiredDescriptionValidation}
        />
    );
}
export default RetireReason;


