import {ACCESS_TOKEN, USER} from '../constants';
import ttclient from "./ttclient";

export const request = async (options) => {

    try {
        let response;
        if(options.method === 'GET'){
            response =  await ttclient.get(options.url);
        }else if(options.method === 'PUT'){
            response =  await ttclient.put(options.url);
        }
        if (!response.status == 200) {
            return Promise.reject(response.ok);
        }
        return response;
    } catch (error) {
        // Handle errors
        if(error.response !== undefined){
            console.error("request rejected: " + JSON.stringify(error.response));
            return error.response;
        }else {
            console.error("error when requesting: " + JSON.stringify(error));
        }
    }
};


export const requestLogout = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);




    const response = await fetch(options.url, options)
        .then(response => response.text())
        .then(data => {
            return data;
        })
        .catch(error => console.error(error));


    if (response == undefined || !response.ok) {
        console.error('Logout Network response was not ok, response is undefined');
    //    throw new Error('Logout Network response was not ok, response is undefined');
    }
    else if (!response.ok) {
        console.error('Logout Network response was not ok, status: ' + response.status + ", error: " + response.error);
       // throw new Error('Logout Network response was not ok, status: ' + response.status + ", error: " + response.error);
    }else {
        console.log("requestLogout response status: " + response.status + ", response : " + JSON.stringify(response));
    }
    return response;
};

export function getCurrentUser() {
    return request({
        url: "/user/me",
        method: 'GET'
    });
}

export function tesGettUrlWithoutToken(url) {
    return request({
        url: url,
        method: 'GET'
    });
}

export async function selectSectorCode(sectorcode, role) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return await request({
        url: "/user/sector/" + sectorcode + "/role/" + role,
        method: 'PUT'
    });

}

export const getCurrentUserInBrowserSession = () => {
    if (localStorage.getItem(USER) == null) {
        return null;
    } else if (localStorage.getItem(USER) == undefined || localStorage.getItem(USER) == "undefined") {
        localStorage.removeItem(USER);
        return null;
    } else {
        let result = JSON.parse(localStorage.getItem(USER));
        return result;
    }
};