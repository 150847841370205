import {collectValidationMessage} from "../../../../common/validation/MetaValidationRules";
import {metaDataTabName} from "../../../../common/page/TabName";
import {
    extensionAuthorCodeSystemUrl,
    extensionAuthorCodeSystemUrlOld,
} from "../../../../../model/defaultvalues/Constant";
import {emptyAuthorName} from "../../../meta/MetaWarnInfoConstant";


export const validateAuthor = ( extensionArray, methods, handleWarnMessageList) => {
    let hasValidationFault = false;
    for (let i = 0; i < extensionArray.length; i++) {
        let extension = extensionArray[i];
        const result = validateAuthorExtension(i, extension, methods, handleWarnMessageList);
        if (result) {
            hasValidationFault = true;
            break;
        }
    }
    return hasValidationFault;
}

const validateAuthorExtension = (i, extension, methods, handleWarnMessageList) => {
    return validateContactDetailExtension(i, extension, methods, handleWarnMessageList,
        extensionAuthorCodeSystemUrl, extensionAuthorCodeSystemUrlOld, "innehållsansvarig", emptyAuthorName)
}

const validateContactDetailExtension = (i, extension, methods, handleWarnMessageList, url, oldUrl, swedishFieldName, errorCode) => {
    var indexHasEmptyAuthorName = 0;
    var hasEmptyAuthorName = false;
    if (extension.url !== undefined && extension.url.trim() !== ""
        && (extension.url === url || extension.url === oldUrl)
    ) {
        indexHasEmptyAuthorName = i;
        if (extension.valueContactDetail === undefined) {
            hasEmptyAuthorName = true
        } else if (extension.valueContactDetail.name === undefined || extension.valueContactDetail.name.trim() === "") {
            hasEmptyAuthorName = true
        }

        let messageEmptyAuthorName = "Fyll i " + swedishFieldName + " för att kunna aktivera";
        const authorFieldName = "extension." + indexHasEmptyAuthorName + ".valueContactDetail.name";
        if (hasEmptyAuthorName) {
            methods.setError(authorFieldName, {
                type: "WARNING",
                // @ts-ignore
                code: errorCode,
                message: messageEmptyAuthorName,
            });
            collectValidationMessage(messageEmptyAuthorName, authorFieldName + errorCode, true, handleWarnMessageList, metaDataTabName);
        } else {
            collectValidationMessage(messageEmptyAuthorName, authorFieldName + errorCode, false, handleWarnMessageList, metaDataTabName);
        }
    }
    return hasEmptyAuthorName;
}