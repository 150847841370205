import Config from "../config.json";

export const LOGIN_API_BASE_URL = `${Config.BACKEND_URL}`;
export const REDIRECT_API_BASE_URL= `${Config.FRONTEND_URL}`;
export const IDP_URL = `${Config.IDP_URL}`;
export const ENV_TYPE = `${Config.ENV}`;

export const ENV_TYPE_DEV = `DEV`;
export const ENV_TYPE_TEST = `TEST`;
export const ENV_TYPE_QA = `QA`;

export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_TOKEN_NOT_FOUND_MESSAGE = "No access token set.";
export const REFRESH_TOKEN = 'refreshToken';

export const USER = 'user';
export const SECTORS = 'sectors';
export const SEARCHRESULT_ID = 'search_result_id';

export const SYSTEM_ADMIN = "System Admin "

export const ROLE_ADMIN= "ROLE_ADMIN";
export const ROLE_USER= "ROLE_USER";

export const ROLES= [ROLE_ADMIN,ROLE_USER];

export const OAUTH2_REDIRECT_URI = REDIRECT_API_BASE_URL + '/oauth2/redirect'

export const INERA_IDP_AUTH_URL = LOGIN_API_BASE_URL + '/oauth2/authorize/ineraidp?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const LAST_UPDATED = '_lastUpdated';

export const VERTICAL_BAR_URL_ENCODING = "|";