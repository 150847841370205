import React, {useState} from 'react';
import {
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const RowActionsMenu = (props) => {

    const [anchorEl, setAnchorEl] = useState([]);

    const handleActionClick = (id, event) => {
        anchorEl[id] = event.target;
        var array = [...anchorEl];
        setAnchorEl(array);
    }

    const remove = (id, event) => {
        props.remove(id);
        if(props.table.getRowModel().rows.length == 1 && props.append !== undefined){
            props.append({
                code: "",
                display: "",
                definition: ""
            });
        }
        handleActionClose(id, event);
    }


    const handleActionClose = (id, event) => {
        anchorEl[id] = null;
        var array = [...anchorEl];
        setAnchorEl(array);
    }


    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={e => handleActionClick(props.rowid, e)}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id={props.rowid}
                anchorEl={anchorEl[props.rowid]}
                keepMounted
                open={Boolean(anchorEl[props.rowid])}
                onClose={e => handleActionClose(props.rowid, e)}
            >
                <MenuItem onClick={e => remove(props.rowid, e)}> Radera </MenuItem>
            </Menu>
        </div>
    )

}
export default RowActionsMenu;