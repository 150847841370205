import React from "react";
import {collectValidationMessage, validEmail} from "../../../common/validation/MetaValidationRules";
import {metaDataTabName} from "../../../common/page/TabName";

export const emailValidation = (value: string, validationlabel: string, telecomArrayObjRow: string, handleWarnMessageList: any) => {
    let trimmedValue = getTrimmedValue(value);
    let message = "Mailadress: Ange korrekt mailadress."
    let validationResult: string | boolean = false;
    if (trimmedValue !== undefined && trimmedValue.length != 0) {
        validationResult = trimmedValue !== undefined && (trimmedValue.length > 0 && !validEmail(trimmedValue) && message);
    }
    return collectValidationMessage(message, validationlabel, validationResult, handleWarnMessageList, metaDataTabName, undefined, telecomArrayObjRow);
}

export const urlValidation = (value: string, validationlabel: string, telecomArrayObjRow: string, handleWarnMessageList: any) => {
    let trimmedValue = getTrimmedValue(value);
    let message = "Webbadressen/URL är inte korrekt ifylld. Ange en korrekt URL."
    let validationResult: string | boolean = false;
    if (trimmedValue !== undefined && trimmedValue.length != 0) {
        validationResult = isValidHttpUrl(trimmedValue) ? false : message;
    }
    return collectValidationMessage(message, validationlabel, validationResult, handleWarnMessageList, metaDataTabName, undefined, telecomArrayObjRow);
}

export const phoneValidation = (value: string, validationlabel: string, telecomArrayObjRow: string, handleWarnMessageList: any) => {
    let trimmedValue = getTrimmedValue(value);
    let message = "Telefonnumret är inte korrekt ifyllt. Tillåtna tecken är +, - och siffror."
    let validationResult: string | boolean = false;
    if (trimmedValue !== undefined && trimmedValue.length != 0) {
        validationResult = validPhone(trimmedValue) ? false : message;
    }
    return collectValidationMessage(message, validationlabel, validationResult, handleWarnMessageList, metaDataTabName, undefined, telecomArrayObjRow);
}

export const validPhone = (phone: string) => {
    const phoneRegex = /^[0-9 ()+-]+$/;
    if (phoneRegex.test(phone)) {
        return true;
    } else {
        return false;
    }
}

/**
 ^ - Start of the string
 (?: - Start of a non-capturing group
 (?:https?|ftp) - Match either "http", "https", or "ftp"
 :\/\/ - Match "://"
 )? - End of the non-capturing group (make it optional)
 (?:www\.)? - Match "www." (optional)
 [a-z0-9-]+ - Match one or more alphanumeric characters or hyphens
 (?:\.[a-z0-9-]+)+ - Match one or more groups of a period followed by alphanumeric characters or hyphens
 [^\s]* - Match zero or more characters that are not whitespace
 $ - End of the string
 * */
const isValidHttpUrl = (value: string) => {
    // Regular expression for URL validation
    const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    // Test the given URL against the regex
    return urlRegex.test(value);
}

/*
* TelecomArray field is trimming the value
* (except phone, but phone-validation on trimmed value)
* in onChange function,
* so we validate on trimmed value.
* */
const getTrimmedValue = (value: string) => {
    let trimmedValue = undefined;
    if (value !== undefined) {
        trimmedValue = value.replace(/\s/g, '');
    }
    return trimmedValue;
}