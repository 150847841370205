import {IRHookSelectOption} from "../../../../common/rhookform/IRHookSelectOption";
import {MRTFilterSelectOptions} from "../../../../common/mrtable/MRTFilterSelectOptions";
import {notmatched} from "../../../../../model/defaultvalues/Constant";

export const categoryOptions: IRHookSelectOption[] = [
    {value: "relatedto", label: "Relaterat till"},
    {value: "equivalent", label: "Likvärdig"},
    {value: "equal", label: "Lika"},
    {value: "wider", label: "Bredare"},
    {value: "subsumes", label: "Underordnad"},
    {value: "narrower", label: "Snäv"},
    {value: "specializes", label: "Specialisering"},
    {value: "inexact", label: "Inexakt"},
    {value: "disjoint", label: "Osammanhängande"},
    {value: notmatched, label: "Omatchad"},
];

export const filterCategoryOptions: MRTFilterSelectOptions[] = [
    {value: "relatedto", text: "Relaterat till"},
    {value: "equivalent", text: "Likvärdig"},
    {value: "equal", text: "Lika"},
    {value: "wider", text: "Bredare"},
    {value: "subsumes", text: "Underordnad"},
    {value: "narrower", text: "Snäv"},
    {value: "specializes", text: "Specialisering"},
    {value: "inexact", text: "Inexakt"},
    {value: "disjoint", text: "Osammanhängande"},
    {value: notmatched, text: "Omatchad"},
];