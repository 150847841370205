import React, {useEffect, useState} from 'react';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import {useFieldArray, useForm, FormProvider} from "react-hook-form";
import ConceptTable from "./ConceptTable";


type FormValues = {
    concept: {
        code: string;
        display: string;
        definition: string;
    }[];
};

let renderCount = 0;

const ConceptTableForImportCodeSystem = (props: any) => {

    const [conceptHierarchyAsString, setConceptHierarchyAsString] = useState<string>("concept");

    const methodsForImportTable = useForm({
        defaultValues: {
            concept: props.data
        },
    });
    const control = methodsForImportTable.control;
    const {
        fields, append,
        update, remove,
        swap, move
    } = useFieldArray({
        name: "concept", // unique name for your Field Array
        control
    });

    useEffect(() => {
        var array = [...methodsForImportTable.getValues("concept")];
        props.setData(array);
    }, [methodsForImportTable.getValues("concept")]);

    const handleErrorMessageList = () => {
    }

    renderCount++;

    return (
        <div>
            <FormProvider {...methodsForImportTable} key={"formConceptTableForImportCodeSystem"}>
                <>
                    <ConceptTable
                        data={props.data}
                        update={update}
                        remove={remove}
                        move={move}
                        swap={swap}
                        methods={methodsForImportTable}
                        append={append}
                        appendNewRow={undefined}
                        conceptHierarchyAsString={conceptHierarchyAsString}
                        setConceptHierarchyAsString={setConceptHierarchyAsString}
                        editDisabled={true}
                        errorMessageList={[]}
                        handleErrorMessageList={handleErrorMessageList}
                        showImportDialog={false}
                    />
                </>
            </FormProvider>
        </div>
    );
};

export default ConceptTableForImportCodeSystem;
