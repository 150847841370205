import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {IDSInput} from "@inera/ids-react";
import RHookFormValidationMessage from "../validation/RHookFormValidationMessage";

interface ReactHookFormTextFieldIneraProps {
    label: string;
    name: string;
    methods: UseFormReturn;
    disabled: boolean;
    enableValidationWhenFieldDisabled?: boolean;
    isArray?: boolean;
}

const RHookFormTextField: FC<ReactHookFormTextFieldIneraProps> = ({
                                                                      label,
                                                                      name,
                                                                      methods,
                                                                      disabled,
                                                                      enableValidationWhenFieldDisabled,
                                                                      isArray,
                                                                  }: ReactHookFormTextFieldIneraProps) => {

    const getDisabled = () => {
        if (disabled !== undefined && disabled) {
            return disabled;
        } else {
            return false;
        }
    }

    const isValid = () => {
        if (methods.formState.errors[name]?.message === undefined) {
            return true;
        } else {
            return false;
        }
    }

    function hasNumber(name: string) {
        return /\d/.test(name);
    }

    function isMessageDisabled() {
        return enableValidationWhenFieldDisabled !== undefined && enableValidationWhenFieldDisabled ? false : disabled;
    }


    return (

        <div className={'container'} key={"container_textinput_" + name}>
            <>
                <IDSInput novalidation={false}>
                    <label>{label}</label>
                    <input type="text" {...methods.register(name)} defaultValue={""} key={"textinput_" + name}
                           disabled={getDisabled()}
                    />
                </IDSInput>
                <RHookFormValidationMessage name={name} editDisabled={isMessageDisabled()}/>

            </>
        </div>
    );
};


export default RHookFormTextField;