import React from "react";
import {IDSButton} from "@inera/ids-react";


const SourceTabs = (props) => {

    const changeSource = () => {
      /*  props.initTabSource ? props.setInitTabSource(false): props.setInitTabSource(true)*/

        if(props.initTabSource){
            props.setInitTabSource(false);
        }

        if(!props.initTabSource){
            props.setInitTabSource(true);
        }

        props.setSourceTableData([]);
    };

    if (props.initTabSource) {

        return (
            <IDSButton size='s' secondary={true} onClick={() => {
                props.setInitTabSource(false);
            }}>
                ANGE KÄLLA
            </IDSButton>
        );

    } else if (!props.initTabSource && props.sourceTableData.length > 0) {
        return (
            <div className={'right'}>
                <ids-link activeicon="">
                    <ids-icon name="arrow"></ids-icon>
                    <a href="#" onClick={() => {
                        changeSource();
                    }}>Ändra källa</a>
                </ids-link>
            </div>
        );
    } else if (!props.initTabSource) {
        return (
            <IDSButton size='s' secondary={true} onClick={() => {
                changeSource();
            }}>
                AVBRYT
            </IDSButton>
        );
    }
};

export default SourceTabs;
