import React from "react";
import {telecomEmpty} from "../../../../model/defaultvalues/MetaDataDefaultFormValue";


export const calculateShowBin = (index: number, arrayname: string, editDisabled: boolean, methods : any) => {
    let showBinResult = true;
    if (editDisabled) {
        showBinResult = false;
    } else if (index == 0) {
        try {
            const telecomArray = methods.getValues(arrayname);
            const emptyTelecom = (JSON.stringify(telecomArray[index]) === JSON.stringify(telecomEmpty));
            showBinResult = (telecomArray.length <= 1) && emptyTelecom ? false : true;
        } catch (error) {
            const telecomArray = methods.getValues(arrayname);
            console.error("TelecomArray showBin error, arrayname: " + arrayname + ", telecomArray: " +
                JSON.stringify(telecomArray) + ", error: " + error);
        }
    }
    return showBinResult;
}
