import React, {useState} from "react";
import {getHierarchyAsString} from "./RHookFormArrayHierarchyFunction";


export function RHookSelectForTable({ arrayname,
                                        name,
                                        index,
                                        methods,
                                        options,
                                         ...rest}) {

    const [hierarchyAsString, setHierarchyAsString] = useState(getHierarchyAsString(index, arrayname) + "." + name);


    const handleChange = event => {
        if (event.target.value === "true") {
            methods.setValue(hierarchyAsString, true);
        } else if (event.target.value == "false") {
            methods.setValue(hierarchyAsString, false);
        } else if (event.target.value === "") {
            methods.setValue(hierarchyAsString, undefined);
        }else{
            methods.setValue(hierarchyAsString, event.target.value);
        }
    };



    return (
        <div key={Math.random()}>
            <label></label>
                <select {...methods.register(hierarchyAsString)} {...rest} onChange={handleChange} defaultValue={""}>
                    <option value="" disabled="true" selected="selected" key={Math.random()}>Välj i listan</option>
                    {options.map((item) => (
                        <option value={item.value} key={Math.random()}>{item.label}</option>
                    ))}
                </select>
        </div>
    );
}
