import React, {useEffect, useState} from 'react';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import 'beautiful-react-diagrams/styles.css';

import {IDSSelect, IDSSpinner} from "@inera/ids-react";
import {ICodeSystemConcept,ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {useFormContext} from "react-hook-form";
import Client from "fhir-kit-client";
import {fhirUrl, SNOMED_CT_ID, SNOMED_CT_VERSION} from "../../../../model/defaultvalues/Constant";
import ConceptSNOMEDRefsetTable from "./ConceptSNOMEDRefsetTable";
import {IRHookSelectOption} from "../../../common/rhookform/IRHookSelectOption";
import Spinner from "react-bootstrap/Spinner";
import ConceptSnomedHierarchy from "./ConceptSnomedHierarchy";
import {dynamicSort} from "../../../common/CommonFunctions";
import {getSwedishValueFromDesignation} from "../../../../codesystem/snomed/SNOMEDUtil";
import {lookupSnomedCode} from "../../../../services/snomed/SnomedService";

let renderCount = 0;
////'content-type', 'application/json-patch+json'

const fhirClient = new Client({
    baseUrl: fhirUrl,
    customHeaders: {
        "Content-Type": "application/fhir+json",
        "Accept": "application/fhir+json"
    }
});


const ConceptSNOMED = (props: any) => {

    const methods = useFormContext();
    const [isRequestingSnomedRefset, setIsRequestingSnomedRefset] = useState(false);
    const [snomedRefSet, setSnomedRefSet] = useState([]);
    const [refSetTableData, setRefSetTableData] = useState([]);
    const formSnomedRef = React.useRef();
    const [selectedConceptToShow, setSelectedConceptToShow] = useState(undefined);
    const [initialSchema, setInitialSchema] = useState();

    const [selectedCode, setSelectedCode] = useState<string>();
    const [selectedCodeDisplay, setselectedCodeDisplay] = useState<string>();
    const [codeRelatedData, setCodeRelatedData] = useState<ValueSet>();

    const [parentChildDirection, setParentChildDirection] = useState<boolean>(true);
    const [parentChildDirectionData, setParentChildDirectionData] = useState<ValueSet>();


    const system = "http://snomed.info/sct";
    const version = SNOMED_CT_VERSION;
    const id = SNOMED_CT_ID  //"45991000052106-20230531"
    //"http://snomed.info/sct/45991000052106/version/20230531";

    const selectedValue = "Lime";

    // Set state
    const [selectedRefset, setSelectedRefset] = useState("");

    useEffect(() => {

        if (methods.getValues("id") === id) {
//'content-type', 'application/json-patch+json'
            //header('Content-type: image/jpeg')
            handleDiagramNodeClick("138875005", "SNOMED CT");
            setIsRequestingSnomedRefset(true);
            fhirClient.request('ValueSet/$expand?url=http%3A%2F%2Fsnomed.info%2Fsct%2F45991000052106%3Ffhir_vs%3Drefset' +
                "&includeDesignations=true&count=100&elements=expansion.contains.code,expansion.contains.display,expansion.contains.fullySpecifiedName,expansion.contains.active"
                //  ,{options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}}
            )
                .then(response => {

                    //  @ts-ignore
                    setSnomedRefSet(response.expansion.contains);
                    setIsRequestingSnomedRefset(false);
                })
                .catch(e => {
                    console.error("FhirClient SNOMED ValueSet/$expand Error Response" + JSON.stringify(e));
                });
        } else {
            fhirClient.request("ValueSet/$expand?_format=json" +
                "&url=" + methods.getValues("valueSet") +
                "&system-version=" + methods.getValues("url") + "|" + methods.getValues("version") +
                "&includeDesignations=true&count=100&elements=expansion.contains.code,expansion.contains.display,expansion.contains.fullySpecifiedName,expansion.contains.active"
            )
                .then(response => {

                    //  @ts-ignore
                    setSnomedRefSet(response.expansion.contains);
                    setIsRequestingSnomedRefset(false);
                })
                .catch(e => {
                    console.error("FhirClient SNOMED ValueSet/$expand Error Response" + JSON.stringify(e));
                });

        }


    }, [methods.getValues("id")]);


    const getRefSetOptions = () => {

        let refSetOtopns: IRHookSelectOption[] = [];
        //  @ts-ignore
        for (let concept of snomedRefSet) {
            let swedishValue = undefined;
            //  @ts-ignore
            swedishValue = getSwedishValueFromDesignation(concept.designation);

            //  @ts-ignore
            refSetOtopns.push({label: swedishValue === undefined ? concept.display : swedishValue, value: concept.code})
        }
        let sortedList = refSetOtopns.sort(dynamicSort("label"));
        return sortedList;
    }


    const getRefSetTableData = (contains: any) => {

        let concepts: ICodeSystemConcept[] = [];
        //  @ts-ignore
        for (let concept of contains) {
            let swedishValue = getSwedishValueFromDesignation(concept.designation);

            //  @ts-ignore
            concepts.push({display: swedishValue === undefined ? concept.display : swedishValue, code: concept.code})
        }

        return concepts;
    }


    // @ts-ignore
    const handleChange = (event: any) => {
        setSelectedRefset(event.target.value);
        setIsRequestingSnomedRefset(true);
        const url = methods.getValues("url");
        let isSnomed = url.includes("snomed");

        if (isSnomed == true) {
            fhirClient.request('ValueSet/$expand?url=http://snomed.info/sct/45991000052106?fhir_vs=refset' + "/" + event.target.value +
                "&system-version=" + url + "|" + "http://snomed.info/sct/45991000052106" + "&includeDesignations=true&count=100&elements=expansion.contains.code,expansion.contains.display,expansion.contains.fullySpecifiedName,expansion.contains.active",
                //   {options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}}
            )
                .then(response => {

                    //  @ts-ignore
                    setRefSetTableData(getRefSetTableData(response.expansion.contains));
                    setIsRequestingSnomedRefset(false);

                })
                .catch(e => {
                    console.error("FhirClient SNOMED setSnomedRefSetChoosenParent Error Response" + JSON.stringify(e));
                });

        }

    };

    const handleParents = (code: string, display: string, valueSet: ValueSet) => {
        setSelectedCode(code);
        setselectedCodeDisplay(display);
        setCodeRelatedData(valueSet);
    };

    const handleDiagramNodeClick = (code: string, display: string) => {
            lookupCodeSystemCode(code);
            requestParentViaCodeOnly(code, display);
    };

    const handleRefSetRowClick = (rowIndex: number) => {
        handleRowClickShowConceptData(rowIndex);
        handleRowClickShowParent(rowIndex);
    };

    const handleRowClickShowConceptData = (rowIndex: number) => {
        // @ts-ignore
        const code = refSetTableData[rowIndex].code;
        lookupCodeSystemCode(code);
    };

    const lookupCodeSystemCode = (code: string) => {
        const concept = lookupSnomedCode(code);
        // @ts-ignore
        setSelectedConceptToShow(concept);
    };


    const handleRowClickShowParent = (rowIndex: number) => {
        // @ts-ignore
        const code = refSetTableData[rowIndex].code;
        // @ts-ignore
        const display = refSetTableData[rowIndex].display;
        requestParentViaExpression(code, display);
    }

    async function requestParentViaExpression(code: string, display: string) {
        // @ts-ignore
        let responseValueSet: ValueSet = await requestParent(code, display, "expression", ">> " + code);
        await setParentChildDirection(false);
        await handleParents(code, display, responseValueSet);
    }

    async function requestParentViaCodeOnly(code: string, display: string) {
        // @ts-ignore
        let responseValueSet: ValueSet = await requestParent(code, display, "parent", code);
        await setParentChildDirection(true);
        await setSelectedCode(code);
        await setselectedCodeDisplay(display);

        // @ts-ignore
        if (responseValueSet.expansion.total != 0) {

            await setParentChildDirectionData(responseValueSet);
        } else {
            requestParentViaExpression(code, display);
        }
    }


    async function requestParent(code: string, display: string, propertyValue: string, value: string) {
        // @ts-ignore
        let responseValueSet: ValueSet = undefined;

        await fhirClient.request("ValueSet/$expand?_format=json&elements=expansion.contains.total,expansion.contains.contains",
            {
                method: 'POST',

                body: {
                    // @ts-ignore
                    "resourceType": "Parameters",
                    "parameter": [{
                        "name": "valueSet",
                        "resource": {
                            "resourceType": "ValueSet",
                            "compose": {
                                "include": [{
                                    "system": system,
                                    "filter": [{
                                        "property": propertyValue,
                                        "op": "=",
                                        "value": value
                                    }
                                    ],
                                    "version": version
                                }
                                ]
                            }
                        }
                    }, {
                        "name": "property",
                        "valueString": "sufficientlyDefined"
                    }, {
                        "name": "property",
                        "valueString": "inactive"
                    }, {
                        "name": "property",
                        "valueString": "parent"
                    }, {
                        "name": "count",
                        "valueInteger": 200000
                    }, {
                        "name": "includeDesignations",
                        "valueBoolean": true
                    }
                    ]
                }
            }
        )
            .then(response => {
                let parentConcept = undefined;
                responseValueSet = response as unknown as ValueSet;

            })
            .catch(e => {
                console.error("FhirClient SNOMED handleRowClickShowConceptData Error Response" + JSON.stringify(e));
            });

        return responseValueSet;
    };


    renderCount++;

    return (
        <div>
 {/*           <ConceptSNOMEDDiagram
                parentChildDirection={parentChildDirection}
                initialSchema={initialSchema}
                selectedCode={selectedCode}
                selectedCodeDisplay={selectedCodeDisplay}
                codeRelatedData={codeRelatedData}
                parentChildDirectionData={parentChildDirectionData}
                handleDiagramNodeClick={handleDiagramNodeClick}
            />*/}

            SNOMED CT BEGREPP
            <ConceptSnomedHierarchy
                parentChildDirectionData={parentChildDirectionData}
                data={refSetTableData}
                url={methods.getValues("url")}
                version={methods.getValues("version")}
                selectedCode={selectedCode}
                selectedCodeDisplay={selectedCodeDisplay}
                handleDiagramNodeClick={handleDiagramNodeClick}
            />
            {/*        }*/}
          {/*  {selectedConceptToShow !== undefined &&
                <>
                    <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between">
                            <IDSCol cols="7">
                                <IDSCard fill={true}>
                                    <div>
                                        <span style={{fontStyle: "bold", color: "green"}}>Int. klartext: </span>
                                        {
                                            // @ts-ignore
                                            selectedConceptToShow.display
                                        }
                                        <br/>
                                        <span style={{fontStyle: "bold", color: "green"}}>Svensk klartext: </span>
                                        {
                                            // @ts-ignore
                                            selectedConceptToShow.swedishDesignation
                                        }
                                        <br/>
                                        <span style={{fontStyle: "bold", color: "green"}}>kod: </span>
                                        {
                                            // @ts-ignore
                                            selectedConceptToShow.code
                                        }
                                    </div>
                                </IDSCard>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                    <br/><br/>
                </>
            }*/}


            <form id="snomed-form">
                <div className={'containerRefSetOptions'} key={Math.random()}>
                    <IDSSelect>
                        <label><h6 className="ids-heading-6">{"SNOMED CT Referens Sets"}</h6></label>
                        <select value={selectedRefset} onChange={handleChange} defaultValue={""}>
                            <option value={""} key={Math.random()}>Välj i listan</option>
                            {getRefSetOptions().map((item) => (
                                <option value={item.value} key={Math.random()}>{item.label}</option>
                            ))}
                        </select>
                    </IDSSelect>
                    {isRequestingSnomedRefset &&
                        <Spinner animation="border" role="status">
                            {/*<span className="visually-hidden">Sparar...</span>*/}
                            <IDSSpinner live="polite" srtext="Hämtar SNOMED Refset..."/>
                        </Spinner>
                    }
                </div>
            </form>

            <ConceptSNOMEDRefsetTable
                data={refSetTableData}
                update={props.update}
                remove={props.remove}
                move={props.move}
                swap={props.swap}
                methods={methods}
                append={props.append}
                appendNewRow={props.appendNewRow}
                conceptHierarchyAsString={props.conceptHierarchyAsString}
                setConceptHierarchyAsString={props.setConceptHierarchyAsString}
                editDisabled={true}
                errorMessageList={props.errorMessageList}
                handleErrorMessageList={props.handleErrorMessageList}
                showImportDialog={props.showImportDialog}
                setSelectedConceptToShow={setSelectedConceptToShow}
                handleParents={handleParents}
                handleRefSetRowClick={handleRefSetRowClick}
            />

        </div>
    );
};

export default ConceptSNOMED;
