import React, {FC} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSButton, IDSSpinner} from "@inera/ids-react";
import {getArtifactSwedishLabel} from "../../common/CommonFunctions";
import {useFormContext} from "react-hook-form";
import {IRHookFormValidationMessage} from "../../common/rhookform/IRHookFormValidationMessage";

interface ActivateButtonProps {
    labelForActionInSwedish: string,
    artifactType: string,
    onClickMethod: any
}

const StatusButton: FC<ActivateButtonProps> = ({
                                                   labelForActionInSwedish,
                                                   artifactType,
                                                   onClickMethod
                                               }) => {

    const methods = useFormContext();

    return (
        <div>
                <IDSButton secondary={false} size="s" color="primary" onClick={() => onClickMethod()}>
                    {labelForActionInSwedish + " " + getArtifactSwedishLabel(artifactType)}
                </IDSButton>
        </div>
    );
};
export default StatusButton;
