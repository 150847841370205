import {IDomainResource, IExtension} from "fhir-typescript-models";
import {
    contactEmpty,
    formGeneratedEmptyContactDetail,
    formGeneratedEmptyTelecom, formGeneratedRetireReason,
    removeTelecomEmptyValue,
    removeTelecomFormDefault
} from "./MetaDataDefaultFormValue";
import {
    extensionAuthorCodeSystemUrl,
    extensionAuthorCodeSystemUrlOld,
    extensionRetireReasonSystemUrl,
    extensionVersionDescriptionCodeSystemUrl,
    extensionVersionDescriptionCodeSystemUrlOld
} from "./Constant";


export const authorExtension: IExtension = {
    url: extensionAuthorCodeSystemUrl,
    valueContactDetail: contactEmpty,
};
export const versionDesciptionExtension: IExtension = {
    url: extensionVersionDescriptionCodeSystemUrl,
    valueString: "",
};
export const retireReasonExtension: IExtension = {
    url: extensionRetireReasonSystemUrl,
    valueString: "",
};
export const extensionList = [versionDesciptionExtension, authorExtension, retireReasonExtension];

export function isAuthorExtension(extension: any) {
    if (extension.url !== undefined && extension.url.trim() !== "" &&
        extension.url?.startsWith(extensionAuthorCodeSystemUrl.substring(0, 61)) ||
        extension.url?.startsWith(extensionAuthorCodeSystemUrlOld.substring(0, 48))) {
        return true;
    } else {
        return false;
    }
}

export function isVersionDescription(extension: any) {
    if (extension.url !== undefined && extension.url.trim() !== "" &&
        extension.url?.startsWith(extensionVersionDescriptionCodeSystemUrl.substring(0, 60)) ||
        extension.url?.startsWith(extensionVersionDescriptionCodeSystemUrlOld.substring(0, 48))) {
        return true;
    } else {
        return false;
    }
}

export function isRetireReason(extension: any) {
    if (extension.url !== undefined && extension.url.trim() !== "" &&
        extension.url?.startsWith(extensionRetireReasonSystemUrl.substring(0, 70))) {
        return true;
    } else {
        return false;
    }
}

export const addTTExtensionsToForm = async (data: any) => {

    if (data.extension === undefined) {
        data.extension = extensionList;
    } else {
        let hasAuthor = false;
        let hasVersionDesciption = false;
        let hasRetiredReason = false;

        for (let i = 0; i < data.extension.length; i++) {
            let extension = data.extension[i];
            hasAuthor = hasAuthor ? true : isAuthorExtension(extension);
            hasVersionDesciption = hasVersionDesciption ? true : isVersionDescription(extension);
            hasRetiredReason = hasRetiredReason ? true : isRetireReason(extension);
        }
        if (!hasAuthor) {
            data.extension.push(authorExtension)
        }
        if (!hasVersionDesciption) {
            data.extension.push(versionDesciptionExtension)
        }
        if (!hasRetiredReason) {
            data.extension.push(retireReasonExtension)
        }

    }
}

export function removeExtensionFormDefault(data: IDomainResource) {

    if (data.extension !== undefined) {

        if (JSON.stringify(data.extension) === JSON.stringify(extensionList)) {
            delete data.extension;
        } else if (JSON.stringify(data.extension) === JSON.stringify([])) {
            delete data.extension;
        } else {

            //removeEmptyDefaultExtensions
            for (let i = 0; i < data.extension.length; i++) {
                //The form can add ContactDetail to extension and interfer with method "removeEmptyDefaultExtensions"
                // - that is why this handling needs to run before "removeEmptyDefaultExtensions"
                removeContactDetailIfNotAuthorExtension(data.extension[i]);
            }
            removeEmptyDefaultExtensions(data);

            //Collect empty contact details
            for (let i = 0; i < data.extension.length; i++) {
                let extension = data.extension[i];
                removeContactDetailIfNotAuthorExtension(extension);
                handleAuthorExtensionIfEmptyValues(i, extension, data);
            }

            removeFormGeneratedContactDetailFromNonContactDetailExtensions(data);
        }
    }
    if (data.extension?.length === 0) {
        data.extension = undefined;
    }
}

export function removeEmptyDefaultExtensions(data: any) {
    let defaultDataIndexToDelete: number[] = [];
    for (let i = 0; i < data.extension.length; i++) {
        let extension = data.extension[i];
        collectEmptyExtensionsIndexToRemove(i, extension, defaultDataIndexToDelete);
    }

    //Remove default object
    for (let i = 0; i < defaultDataIndexToDelete.length; i++) {
        let index = defaultDataIndexToDelete[i];
        data.extension.splice(index, 1);
    }
}

export function removeFormGeneratedContactDetailFromNonContactDetailExtensions(data: any) {
    let defaultDataIndexToDelete: number[] = [];
    for (let i = 0; i < data.extension.length; i++) {
        let extension = data.extension[i];
        if(!isAuthorExtension(extension)){
            extension.valueContactDetail = undefined;
        }
    }
}


function collectEmptyExtensionsIndexToRemove(i: number, extension: any, defaultDataIndexToDelete: number[]) {

/*
    let ex = JSON.stringify(extension);
    let re = JSON.stringify(retireReasonExtension);
    if (JSON.stringify(extension).includes("retire")) {
        debugger
    }
*/

    //Collect empty Extensions to remove
    if (
        //author is more complex so more methods are used later in remove "extensions process" to examine authorExtension
        JSON.stringify(extension) === JSON.stringify(authorExtension) ||
        isEmptyVersionDescription(extension) ||
        isEmptyRetireReason(extension) ||
        extension == null) {
        defaultDataIndexToDelete.unshift(i);
    } else if (extension.url === undefined) {
        defaultDataIndexToDelete.unshift(i);
    }
}

function isEmptyRetireReason(extension: any): boolean {
    let isEmptyRetireReasonExtension = false;
    if (isRetireReason(extension) && hasEmptyValueString(extension)) {
        isEmptyRetireReasonExtension = true;
    }
    return isEmptyRetireReasonExtension;
}

function isEmptyVersionDescription(extension: any): boolean {
    let isEmptyVersionDescriptionExtension = false;
    if (isVersionDescription(extension) && hasEmptyValueString(extension)) {
        isEmptyVersionDescriptionExtension = true;
    }
    return isEmptyVersionDescriptionExtension;
}


function hasEmptyValueString(extension: any): boolean {
    return (extension.valueString === undefined || extension.valueString.trim() === "");
}


/*
* If the form has added empty default ContactDetails to another type of Extension that is not related to ContactDetails
* */
export function removeContactDetailIfNotAuthorExtension(extension: any) {
    if (!isAuthorExtension(extension) &&
        JSON.stringify(extension.valueContactDetail) === JSON.stringify(formGeneratedEmptyContactDetail) ||
        JSON.stringify(extension.valueContactDetail) === "{}") {
        extension.valueContactDetail = undefined;
    }
}

export function handleAuthorExtensionIfEmptyValues(i: number, extension: any, data: any) {
//If the form has added empty email telecom object or empty contact name
    if (isAuthorExtension(extension)) {
        let removeAuthorExtension = false;
        //Remove valueString as it is not part of Contact extension
        extension.valueString = undefined;

        //Remove empty name
        // @ts-ignore
        if (extension.valueContactDetail.name !== undefined && extension.valueContactDetail.name.trim() === "") {
            // @ts-ignore
            extension.valueContactDetail.name = undefined;
        }

        // @ts-ignore
        removeContactDetailDefaultFromExtension(extension);

        //If the form has added empty email telecom object
        if (isAuthorExtension(extension) &&
            JSON.stringify(extension.valueContactDetail) !== undefined &&
            JSON.stringify(extension.valueContactDetail?.telecom) !== undefined &&
            JSON.stringify(extension.valueContactDetail?.telecom) === JSON.stringify([formGeneratedEmptyTelecom])
        ) {
            // @ts-ignore
            extension.valueContactDetail.telecom = undefined;
        }

        // @ts-ignore
        if (extension.valueContactDetail === undefined) {
            removeAuthorExtension = true;
        }

        if (removeAuthorExtension) {
            data.extension.splice(i, 1);
        }
    }
}

export function removeContactDetailDefaultFromExtension(extension: any) {

    if (JSON.stringify(extension.valueContactDetail) === JSON.stringify(contactEmpty)) {
        delete extension.valueContactDetail;
    } else if (extension.valueContactDetail) {

        let contactDetail = extension.valueContactDetail;

        if (contactDetail.name !== undefined && contactDetail.name.trim() === "") {
            extension.name = undefined;
        }

        removeTelecomFormDefault(extension.valueContactDetail);
        removeTelecomEmptyValue(extension.valueContactDetail);

        if (JSON.stringify(extension.valueContactDetail) === "{}") {
            extension.valueContactDetail = undefined;
        }
    }
}
