import {collectValidationMessage} from "../../../../common/validation/MetaValidationRules";
import {publishTabName} from "../../../../common/page/TabName";
import {
    extensionVersionDescriptionCodeSystemUrl,
    extensionVersionDescriptionCodeSystemUrlOld
} from "../../../../../model/defaultvalues/Constant";
import {emptyVersionDescription} from "../../../meta/MetaWarnInfoConstant";


export const validateVersionDescription = (extensionArray, methods, handleWarnMessageList) => {
    let hasValidationFault = false;
    for (let i = 0; i < extensionArray.length; i++) {
        let extension = extensionArray[i];
        const result = validateVersionDescriptionExtension(i, extension, methods, handleWarnMessageList);
        if (result) {
            hasValidationFault = true;
            break;
        }
    }
    return hasValidationFault;
}

const validateVersionDescriptionExtension = (i, extension, methods, handleWarnMessageList) => {
    return validateContactDetailExtension(i, extension, methods, handleWarnMessageList,
        extensionVersionDescriptionCodeSystemUrl, extensionVersionDescriptionCodeSystemUrlOld, "versionbeskrivning", emptyVersionDescription)
}


const validateContactDetailExtension = (i, extension, methods, handleWarnMessageList, url, oldUrl, swedishFieldName, errorCode) => {
    var indexForVersionDescription = 0;
    var hasEmptyVersionDescription = false;
    if (extension.url !== undefined && extension.url.trim() !== "" &&
        (extension.url === url || extension.url === oldUrl) &&
        (extension.valueString === undefined || extension.valueString.trim() === "")) {
        hasEmptyVersionDescription = true;
        indexForVersionDescription = i;

        let messageEmptyVersionDescription = "Fyll i " + swedishFieldName + " för att kunna aktivera";
        const versionDescriptionFiledName = "extension." + indexForVersionDescription + ".valueString";
        if (hasEmptyVersionDescription) {
            methods.setError(versionDescriptionFiledName, {
                type: "WARNING",
                // @ts-ignore
                code: errorCode,
                message: messageEmptyVersionDescription,
            });
            collectValidationMessage(messageEmptyVersionDescription, versionDescriptionFiledName + errorCode, true, handleWarnMessageList, publishTabName);
        } else {
            collectValidationMessage(messageEmptyVersionDescription, versionDescriptionFiledName + errorCode, false, handleWarnMessageList, publishTabName);
        }
    }
    return hasEmptyVersionDescription;
}