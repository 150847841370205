import React from "react";
import {getHierarchyAsString} from "./RHookFormArrayHierarchyFunction";


/*
* This duplicated method is only to document this bugg in one place only:
* some bugg in RhookForm  - must use array update method - methods.setValue do not mount in arrays with lenght over 2
* */
export const remountArrayWithNewValue = (index, value, arrayUpdateMethod) => {
        arrayUpdateMethod(index, value);
};

/*
* mount the value for table to show value when editDisable = true
* */
export const remountConceptArrayWithNewValue = (index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod) => {
    // console.log("remountConceptArrayWithNewValue")
    let hierarchyAsString = getHierarchyAsString(index, arrayname);
    if ((arrayAppendMethod !== undefined) && index === 0) {
        let concept = methods.getValues(hierarchyAsString);
        arrayAppendMethod(methods.getValues(hierarchyAsString));
    } else if (arrayUpdateMethod !== undefined) {
        let concept = methods.getValues(hierarchyAsString);
        arrayUpdateMethod(index, methods.getValues(hierarchyAsString));
    }
};