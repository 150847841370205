import {FC, useState} from "react";
import {IDSLink} from "@inera/ids-react";

interface ExpandableTextInHtmlProps {
    text: string;
    limit: number;
}

const ExpandableTextInHtml: FC<ExpandableTextInHtmlProps> = ({text, limit}) => {
    const [showFull, setShowFull] = useState(false);

    const changeShow = () => {
        if (showFull) {
            setShowFull(false);
        } else {
            setShowFull(true);
        }
    };

    const getEndString = () => {
        if (text.length <= limit && !showFull) {
            return "";
        } else if (!showFull) {
            return "Visa mer";
        } else {
            return "Visa mindre";
        }
    };


    const getTextEnd = () => {
        const endText = showFull ? "Visa mindre" : "Visa mer";
        return (
            <>&nbsp;
                <IDSLink className="ids-my-2" underlined={true} size="s">
                    <a href="#" onClick={() => {
                        changeShow()
                    }}>{getEndString()}</a>
                </IDSLink>
            </>
        );
    };


    return (
        <div>
            {(text === undefined || text.trim().length == 0) ? undefined :
                <p>
                    <span>{showFull ? text : text.slice(0, limit)}</span>
                    {getTextEnd()}
                </p>
            }
        </div>
    );
}

export default ExpandableTextInHtml;
