import React, {useState} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSDialog} from "@inera/ids-react";
import VersionStatusForm from "./VersionStatusForm";
import {useFormContext} from "react-hook-form";
import {extensionRetireReasonSystemUrl} from "../../../model/defaultvalues/Constant";

const VersionStatusDialog = (props) => {

    const methods = useFormContext();

    const submitForm = (requestedStatus) => {

        methods.setValue("status", props.requestedStatus);
        let hasErrors = false;

        if (props.requestedStatus === "retired") {
            const extensions = methods.getValues("extension");
            if (extensions !== undefined && Array.isArray(extensions)) {
                for (let i = 0; i < extensions.length; i++) {
                    let extension = extensions[i];
                    if (extension.url !== undefined && extension.url === extensionRetireReasonSystemUrl) {
                        if (extension.valueString === undefined || extension.valueString.trim().length == 0
                            || extension.valueString.length < 10) {
                            hasErrors = true
                            break;
                        }
                    }
                }
            } else {
                hasErrors = true;
            }
        }

        if (hasErrors == false) {
            props.closeDialog(true);
        }
    }

    const getHeadline = () => {
        const begin = "Du håller på att "
        if (props.requestedStatus === "active") {
            return begin + "aktivera: " + methods.getValues("title");
        } else if (props.requestedStatus === "retired") {
            return begin + "avställa: " + methods.getValues("title");
        }
    }
    return (
        <div>
            <IDSDialog overlay={true} persistent={true} dismissible="true" show={props.showDialog}
                       headline={getHeadline()}
                       onClosed={() => {
                           props.closeDialog(false);
                       }}
            >
                <a href="javascript:void(0)" trigger="true" ref={props.dialogRef} style={{color: '#FFF'}}></a>
                <p style={{color: '#FFF'}}>..................................................................................................................................</p>
                <VersionStatusForm artifactType={props.artifactType} closeDialog={props.closeDialog}
                                   requestedStatus={props.requestedStatus} editDisabled={props.editDisabled}
                                   submitArtefact={submitForm}/>
            </IDSDialog>
        </div>
    );
};
export default VersionStatusDialog;