import React from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSDialog} from "@inera/ids-react";
import ImportExcel from "./ImportExcel";
import {codesystem} from "../../model/defaultvalues/Constant";

const ImportFromExcelDialog = (props) => {

    const closeDialog = () => {
        props.setShowImportDialog(false);
    }

    return (
        <div>
            <IDSDialog overlay={true} persistent={true} dismissible="true" show={props.showImportDialog}
                       headline={"Importera koder från fil"}
                       onClosed={() => {
                           closeDialog();
                       }}>
                <a href="javascript:void(0)" trigger="true" ref={props.importButtonRef} style={{color: '#FFF'}}></a>
                <ImportExcel artifactType={codesystem}
                             conceptsInCodeSystem={props.conceptsInCodeSystem}
                             addCodes={props.addCodes}
                             replaceCodes={props.replaceCodes}
                             closeDialog={closeDialog}
                             key={new Date().getTime()}/>
            </IDSDialog>
        </div>
    );
};
export default ImportFromExcelDialog;