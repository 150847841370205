import React from "react";


export const getPreviousVersion = (tagArray) => {

    const tagPreviousVersionSystemUrl = "http://terminologitjansten.inera.se/fhir/CodeSystem/terminologyservice-previous-version-tag";

    let previousVersion = undefined
    if (tagArray !== undefined) {
        let tag = tagArray.find(t => t.system.includes(tagPreviousVersionSystemUrl))
        if (tag !== undefined) {
            previousVersion = tag.code;
        }
    }
    return previousVersion;
};
