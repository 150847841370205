import React from "react";
import {CONTENT} from "../../common/page/TabName";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";

export const collectValidationsNotInContentTab = (oldValidationMessageList: []) => {
    // @ts-ignore
    let newList = []
    for(let validationResult of oldValidationMessageList){
        // @ts-ignore
        if(validationResult.tabName.name !== CONTENT){
            // @ts-ignore
            newList.push(validationResult);
        }
    }
    // @ts-ignore
    return newList;
}


export const removeDuplicateCodesInList = (concepts: ICodeSystemConcept[] ) => {
    return  concepts.filter((v,i,a)=>a.findIndex(v2=>(v2.code===v.code))===i)
}
