import React from "react";
import {Extension} from "fhir-typescript-models";
import {
    ContactDetail,
    PrimitiveUri,
    ContactPoint, ContactPointSystem, PrimitiveString
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {UseFormReturn} from "react-hook-form";
import {getExtensionIndexForAnyParameterType} from "../../../common/ExtensionUtil";

export const getExtensionIndexForEmail = (methods: UseFormReturn, extensionUrl: string, fields?: any[]) => {

    return getExtensionIndexForAnyParameterType(extensionUrl, fields, createExtensionForAuthor);
}


const createExtensionForAuthor = (extensionUrl: string) => {

   const authorExtension: Extension = new Extension();

    const primitiveUri: PrimitiveUri = new PrimitiveUri();
    primitiveUri.value = extensionUrl;

    authorExtension.url = primitiveUri;
    authorExtension.value = createContactDetailForEmail();

    return authorExtension;
}

const createContactDetailForEmail = () => {

    const contactPointSystem: ContactPointSystem = new ContactPointSystem();
    contactPointSystem.value = "email";

    const contactPointValue: PrimitiveString = new PrimitiveString();
    contactPointValue.value = "";

    const telecomForEmail: ContactPoint = new ContactPoint();
    telecomForEmail.system = contactPointSystem;
    telecomForEmail.value = contactPointValue;

    const telecomArray: Array<ContactPoint> = [];
    telecomArray.push(telecomForEmail);

    const contactDetail: ContactDetail = new ContactDetail();
    contactDetail.telecom = telecomArray;

    return contactDetail;
}

