import {IConceptMap} from "fhir-typescript-models";
import {IConceptMapGroupElement} from "fhir-typescript-models/dist/src/models/fhir/interfaces/IConceptMapGroupElement";
import {
    ICodeSystemConcept,
    IConceptMapGroupElementTarget,
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    removeContactFormDefault, removeEmptyMetaDataFields,
    removeIdentifierFormDefault
} from "./MetaDataDefaultFormValue";
import {removeEmojiFromJson} from "../../components/common/CommonFunctions";
import {removeExtensionFormDefault} from "./MetaDataExtensionDefaultFormValue";

export const concept: ICodeSystemConcept = {
    code: "",
    display: "",
    definition: "",
    concept: []
};

export const conceptList: ICodeSystemConcept[] = [concept];


export function removeDefaultConceptMapFormValues(data: IConceptMap) {

    data = removeEmojiFromJson(data);

    removeExtensionFormDefault(data);

    removeContactFormDefault(data);

    removeIdentifierFormDefault(data);

    removeEmptyMetaDataFields(data);

    removeEmptyGroupParameters(data);

    return data;
};


function removeEmptyGroupParameters(data: IConceptMap) {
    if (data.group !== undefined && data.group.length > 0) {

        for (let group of data.group) {

            if (group.source?.trim().length === 0) {
                group.source = undefined;
            }

            if (group.target?.trim().length === 0) {
                group.target = undefined;
            }

            if (group.element) {
                removeEmptyElementParameters(group.element);
            }

        }

    } else if (data.group !== undefined && data.group.length === 0) {
        data.group = undefined;
    }
}


function removeEmptyElementParameters(elementArray: Array<IConceptMapGroupElement>) {

    for (let i = 0; i < elementArray.length; i++) {
        let element = elementArray[i];

        if (element.code?.trim() === "") {
            element.code = undefined;
        }

        if (element.display?.trim() === "") {
            element.display = undefined;
        }


        if (element.target !== undefined) {
            for (let target of element.target) {
                removeEmptyTargetParameters(target);
            }
            if (JSON.stringify(element.target) === "[{}]" || element.target.length === 0) {
                element.target = undefined;
            }
        }
        // @ts-ignore
        else if (element.target?.length === 0) {
            element.target = undefined;
        }

        // @ts-ignore
        if (element.definition !== undefined) {
            // @ts-ignore
            element.definition = undefined;
        }

        // @ts-ignore
        if (element.designation !== undefined) {
            // @ts-ignore
            element.designation = undefined;
        }

        if (JSON.stringify(element) === "{}") {
            elementArray.splice(i, 1);
        } else if (element.target === undefined) {
            elementArray.splice(i, 1);
        }

    }

    removeElementWithEmptyEquivalence(elementArray);
    removeElementWithNoTarget(elementArray);
}

function removeEmptyTargetParameters(target: IConceptMapGroupElementTarget) {

    if (target.code?.trim() === "") {
        target.code = undefined;
    }

    if (target.display?.trim() === "") {
        target.display = undefined;
    }

    if (target.equivalence?.trim() === "") {
        target.equivalence = undefined;
    }

    if (target.comment?.trim() === "") {
        target.comment = undefined;
    }

}


function removeElementWithEmptyEquivalence(elementArray: Array<IConceptMapGroupElement>) {

    for (let i = 0; i < elementArray.length; i++) {
        let element = elementArray[i];

        if (element.target !== undefined) {
            for (let target of element.target) {
                if (target.equivalence === undefined || target.equivalence.trim() == "") {
                    elementArray.splice(i, 1);
                }
            }
        }
    }
}

function removeElementWithNoTarget(elementArray: Array<IConceptMapGroupElement>) {

    for (let i = 0; i < elementArray.length; i++) {
        let element = elementArray[i];
        if (element.target === undefined) {
            elementArray.splice(i, 1);
        }
    }
}

