import React, {useEffect, useMemo, useRef, useState} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef,
    MRT_Row, MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../../styles/TTStyles";
import RHookFormTextFieldForTable from "../../../common/rhookform/RHookFormTextFieldForTable";
import {MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import RowActionsMenu from "./RowActionsMenu";
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';

import {IDSIcon, IDSLink, IDSCard} from "@inera/ids-react";
import {getHierarchyAsString} from "../../../common/rhookform/RHookFormArrayHierarchyFunction";
import {
    ICodeSystemConcept,
    Parameters,
    ParametersParameter, ValueSet
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {conceptList} from "../../../../model/defaultvalues/CodeSystemDefaultFormValue";
import Client from "fhir-kit-client";
import {fhirUrl} from "../../../../model/defaultvalues/Constant";

let renderCount = 0;


const fhirClient = new Client({baseUrl: fhirUrl});

const ConceptSNOMEDRefsetTable = (props: any) => {


    const arrayname = "concept"
    const system = "http://snomed.info/sct";
    const version = "http://snomed.info/sct/45991000052106/version/20230531";

    renderCount++;

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true,
            Edit: ({cell, column, table, row}) =>
                <RHookFormTextFieldForTable
                    arrayname={arrayname}
                    fieldname={"code"}
                    index={row.id}
                    methods={props.methods}
                />
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true,
            Edit: ({cell, column, table, row}) =>
                <RHookFormTextFieldForTable
                    arrayname={arrayname}
                    fieldname={"display"}
                    index={row.id}
                    methods={props.methods}
                />
        }
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    const handleConceptDragDropHiararchyArray = (draggingRow: MRT_Row<ICodeSystemConcept>,
                                                 hoveredRow: MRT_Row<ICodeSystemConcept> | { id: string; } | null) => {

        let rhookFormDraggingRowHiararchyIndex = getHierarchyAsString(draggingRow.id, arrayname);
        let rhookFormHoveredHiararchyRowIndex;
        if (hoveredRow !== null && hoveredRow.id !== undefined) {
            rhookFormHoveredHiararchyRowIndex = getHierarchyAsString(hoveredRow.id, arrayname);
        }


        if (rhookFormHoveredHiararchyRowIndex !== undefined) {

            var draggingRowParentIndex = rhookFormDraggingRowHiararchyIndex.substring(0, rhookFormDraggingRowHiararchyIndex.lastIndexOf("["));
            var draggingRowConceptIndex = +(rhookFormDraggingRowHiararchyIndex.substring(rhookFormDraggingRowHiararchyIndex.lastIndexOf("[") + 1, rhookFormDraggingRowHiararchyIndex.length - 1));

            var hoveredRowParentIndex = rhookFormHoveredHiararchyRowIndex.substring(0, rhookFormHoveredHiararchyRowIndex.lastIndexOf("["));
            var hoveredRowConceptIndex = +(rhookFormHoveredHiararchyRowIndex.substring(rhookFormHoveredHiararchyRowIndex.lastIndexOf("[") + 1, rhookFormHoveredHiararchyRowIndex.length - 1));

            if (draggingRowParentIndex === hoveredRowParentIndex) {

                props.setConceptHierarchyAsString(hoveredRowParentIndex);
                props.move(draggingRowConceptIndex, hoveredRowConceptIndex);

            } else {
                //Move in different levels of hierarchy
                const addDraggingRow = async () => {
                    await props.setConceptHierarchyAsString(hoveredRowParentIndex);
                    props.insert(hoveredRowConceptIndex, draggingRow);
                }

                const removeDraggingRow = async () => {
                    await props.setConceptHierarchyAsString(draggingRowParentIndex);
                    props.remove(hoveredRowConceptIndex, hoveredRow);
                }

            }
        }
    };



    const handleRefSetRowClick = (rowIndex: number) => {
        props.handleRefSetRowClick(rowIndex);
    };

    renderCount++;

    return (
        <div>

            <div>
                <>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        columns={columns}
                        data={((props.data === undefined) || (props.data.length == 0) || (props.data[0].code === "")) ? conceptList : props.data}
                        enableRowOrdering={props.editDisabled ? false : true}
                        enableColumnFilters={true}
                        enableSorting={true}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableSelectAll={true}
                        enableEditing={props.editDisabled ? false : true}
                        enableHiding={false}
                        editingMode="table"
                        enableRowActions
                        positionActionsColumn="last"
                        enableExpanding
                        enableExpandAll //default
                        tableInstanceRef={tableInstanceRef}


                        muiTableBodyCellProps={({cell, row, table}) => ({

                            onClick: () => {
                                handleRefSetRowClick(row.index);
                            },


                        })}


                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsFiveColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}

                        getSubRows={(originalRow) => {
                            return (
                                originalRow.concept
                            )
                        }}

                        renderRowActions={({row, table}) => (
                            <div>
                                {(!props.editDisabled) &&
                                    <RowActionsMenu rowid={row.index} table={table} remove={props.remove}
                                                    append={props.append}/>
                                }
                            </div>
                        )}

                        renderRowActionMenuItems={({row}) => [
                            <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                            <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                        ]}

                        muiTableHeadCellColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}

                        muiTableBodyRowDragHandleProps={({table, row}) => ({
                            onDragEnd: () => {
                                const {draggingRow, hoveredRow} = table.getState();
                                if (hoveredRow && draggingRow) {
                                    if (!draggingRow.id.includes(".") && !hoveredRow.id.includes(".")) {
                                        // @ts-ignore
                                        props.move(draggingRow.id, hoveredRow.id);
                                    } else {
                                        handleConceptDragDropHiararchyArray(draggingRow, hoveredRow);
                                    }
                                }
                            },
                        })}
                    />
                </>
            </div>

            <div className={'container'}>


                {(!props.editDisabled && props.appendNewRow !== undefined) &&
                    <div>
                        <IDSLink color="green" underlined={true}>
                            <IDSIcon name="plus"></IDSIcon>
                            <a role="button" onClick={() => {
                                props.appendNewRow({
                                    code: "",
                                    display: "",
                                    definition: ""
                                    /*      ,
                                          property: [{code:"source", value:""}]*/
                                });
                            }}>Lägg till rad</a>
                        </IDSLink>
                    </div>
                }

                <br/>

                {/*TODO more links*/}
                {/*               <IDSLink color="green" underlined="true">
                    <IDSIcon name="plus"></IDSIcon>
                        <a href="#" onClick={props.append({
                            code: "",
                            display: "",
                            definition: ""

                        })}>Link kjihidjglskdfjg</a>

                </IDSLink>*/}


            </div>
        </div>
    );
};

export default ConceptSNOMEDRefsetTable;
