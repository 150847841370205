import React, {useState} from 'react'
import * as XLSX from 'xlsx'
import {IDSAlert, IDSButton, IDSContainer, IDSRow, IDSCol} from "@inera/ids-react";
import {valueset} from "../../model/defaultvalues/Constant";
import {checkHasCode} from "./ImportCommonFunctions";
import {getParameterCaseInsensitive} from "../../components/common/CommonFunctions";
import ConceptTableForImportCodeSystem from "../../components/artifact/concept/table/ConceptTableForImportCodeSystem";
import ImportConceptTable from "./ImportConceptTable";
import {removeDuplicateCodesInList} from "../../components/artifact/concept/ConceptUtil";
import {isSnomed} from "../../codesystem/snomed/SNOMEDUtil";
import {lookupSnomedCode} from "../snomed/SnomedService";

function ImportExcelVS(props) {

    // on change states
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const formRef = React.useRef();
    // submit
    const [excelData, setExcelData] = useState(null);
    // it will contain array of objects

    const [codesNotInCodeSystem, setCodesNotInCodeSystem] = useState(null);
    const [displaysNotInCodeSystem, setDisplaysNotInCodeSystem] = useState(null);
    const [fetchedCodes, setFetchedCodes] = useState(false);

    // handle File
    const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

    // Ref object to reference the input element
    const inputFile = React.useRef(null);

    const importConcepts = (excelData) => {
        props.addCodes(excelData);
        cleanForm();
    }

    const cleanForm = () => {
        document.getElementById("import-excel-form").reset();
        formRef.current.reset();
        setExcelData(null);
    }


    const handleFile = (e) => {
        console.log("IMPORT handleFile")
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            console.log("IMPORT handleFile selectedFile")
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("IMPORT handleFile selectedFile has type")
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            } else {
                setExcelFileError('Vänligen välj bara excel fil typer');
                setExcelFile(null);
            }
        } else {
            console.log("IMPORT handleFile NO selectedFile")
            console.log('plz select your file');
        }
    }


    const fetch = async () => {
        console.log("IMPORT fetch (excelFile !== null) : " + JSON.stringify((excelFile !== null)));
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {type: 'buffer'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            let data = XLSX.utils.sheet_to_json(worksheet);
            console.log("IMPORT fetch data : " + JSON.stringify(data));
            if (checkHasCode(worksheet)) {

                data = data.map((e) => {
                    return {
                        code: getParameterCaseInsensitive(e, "kod"),
                        display: getParameterCaseInsensitive(e, "klartext"),
                        definition: getParameterCaseInsensitive(e, "beskrivning")
                    }
                });

                console.log("IMPORT fetch : " + JSON.stringify(data));

                if (props.belongsTo === valueset) {
                    console.log("IMPORT fetch props.belongsTo: " + props.belongsTo)
                    await compareValueSetIncludeConcepts(data, props.codeSystemsWithAllCodesSelectableCode.concept);
                } else {
                    console.log("IMPORT fetch belongs to codesystem ");
                    await compareValueSetIncludeConcepts(data, props.conceptsInCodeSystem);
                }

                setFetchedCodes(true);
                console.log("IMPORT fetchedCodes A : " + fetchedCodes);
            } else {
                setExcelData(null);
                console.log("IMPORT fetchedCodes B : " + fetchedCodes);
            }
        } else {
            setExcelData(null);
            console.log("IMPORT fetchedCodes C : " + fetchedCodes);
        }
        console.log("IMPORT fetchedCodes D : " + fetchedCodes);
        formRef.current.reset();
    }

    const compareValueSetIncludeConcepts = async (dataFromExcel, concept) => {

        if (concept !== undefined) {
            console.log("IMPORT compareValueSetIncludeConcepts availableCodesInCodeSystem.concept : " + JSON.stringify(concept));
        }

        let codesInCodeSystem = [];
        let codesNotInCS = [];
        let displayNotInCS = [];

        console.log("IMPORT compareValueSetIncludeConcepts dataFromExcel: " + JSON.stringify(dataFromExcel));

        if (isSnomed(props.codeSystemsWithAllCodesSelectableCode.url)) {
            for (const conceptFromExcel of dataFromExcel) {
                const concept = await lookupSnomedCode(conceptFromExcel.code);
                if (concept === undefined) {
                    codesNotInCS.push(conceptFromExcel)
                } else if (conceptFromExcel.display === undefined || conceptFromExcel.display === "") {
                    codesInCodeSystem.push(concept);
                } else if (!(conceptFromExcel.display === concept.display ||
                    concept.swedishSynonym.includes(conceptFromExcel.display))) {
                    displayNotInCS.push(conceptFromExcel);
                } else {
                    codesInCodeSystem.push(conceptFromExcel);
                }
            }
        } else if (dataFromExcel !== undefined && concept !== undefined) {
            console.log("IMPORT compareValueSetIncludeConcepts A: ");

            for (const conceptFromExcel of dataFromExcel) {
                console.log("IMPORT compareValueSetIncludeConcepts B: ");
                if (conceptFromExcel.code !== undefined) {
                    console.log("IMPORT compareValueSetIncludeConcepts C: ");
                    var result = concept.find(concept => {
                        return concept.code === conceptFromExcel.code;
                    })
                    if (result !== undefined) {
                        console.log("IMPORT compareValueSetIncludeConcepts D: ");
                        if (props.belongsTo === valueset) {
                            console.log("IMPORT compareValueSetIncludeConcepts E: ");
                            codesInCodeSystem.push(result);
                        } else {
                            console.log("IMPORT compareValueSetIncludeConcepts F: ");
                            codesNotInCS.push(result)
                        }
                    } else {
                        console.log("IMPORT compareValueSetIncludeConcepts G: ");
                        if (props.belongsTo === valueset) {
                            console.log("IMPORT compareValueSetIncludeConcepts H: ");
                            codesNotInCS.push(conceptFromExcel)
                        } else {
                            console.log("IMPORT compareValueSetIncludeConcepts I: ");
                            codesInCodeSystem.push(conceptFromExcel);
                        }
                    }
                }


                //  if(conceptFromExcel.code !== undefined !== undefined &&result !== undefined )

                /*       if (conceptFromExcel.code !== undefined &&
                           (availableCodesInCodeSystem.concept.some(concept => concept['code'] === conceptFromExcel.code))) {
                           codesInCodeSystem.push(concept);
                       } else {
                           codesNotInCodeSystem.push(conceptFromExcel)
                       }*/
            }
        }
        codesInCodeSystem = removeDuplicateCodesInList(codesInCodeSystem);
        setExcelData(codesInCodeSystem);
        setCodesNotInCodeSystem(codesNotInCS);
        setDisplaysNotInCodeSystem(displayNotInCS);

        console.log("IMPORT compareValueSetIncludeConcepts codesInCodeSystem: " + JSON.stringify(codesInCodeSystem));
        console.log("IMPORT compareValueSetIncludeConcepts codesInCodeSystem: " + JSON.stringify(codesNotInCS));

        console.log("IMPORT compareValueSetIncludeConcepts FINAL ExcelData : " + JSON.stringify(excelData));
        console.log("IMPORT compareValueSetIncludeConcepts FINAL CodesNotInCodeSystem : " + JSON.stringify(codesNotInCodeSystem));

    }

    const removeCodesNotInCodeSystem = (linkref) => {
        console.log("IMPORT removeCodesNotInCodeSystem excelData : " + JSON.stringify(excelData))
        if ((excelData === null || excelData.length == 0)) {
            setFetchedCodes(false);
            handleReset();
        }

        setCodesNotInCodeSystem(null);

        jumpToReleventDiv("import-anchor");
    }

    const removeDisplaysNotInCodeSystem = (linkref) => {
        console.log("IMPORT removeDisplaysNotInCodeSystem excelData : " + JSON.stringify(excelData))
        if ((excelData === null || excelData.length == 0)) {
            setFetchedCodes(false);
            handleReset();
        }

        setDisplaysNotInCodeSystem(null);

        jumpToReleventDiv("import-anchor");
    }


    // Function to reset the input element
    const handleReset = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };

    const jumpToReleventDiv = (id) => {
        const releventDiv = document.getElementById(id);
        // behavior: "smooth" parameter for smooth movement
        releventDiv.scrollIntoView({behavior: "smooth"});
    }

    const removeRow = (index) => {
        if (index > -1) { // only splice array when item is found
            excelData.splice(index, 1);
            var newArray = [...excelData]; // make a separate copy of the array
            setExcelData(newArray)
        }
    }

    const hasImportErrorValidations = () => {
        return ((codesNotInCodeSystem === null || codesNotInCodeSystem.length == 0) &&
            (displaysNotInCodeSystem === null || displaysNotInCodeSystem.length == 0)) ? false : true;
    }

    const getImportErrorValidationMessage = () => {
        return hasImportErrorValidations() ? "utan fel" : "";
    }

    return (
        <div className="container">

            {/* upload file section */}
            <div
                //  className='form'
            >
                <form
                    // className='form-group'
                    autoComplete="off" id="import-excel-form" ref={formRef}>
                    <a id="import-anchor"><label><h4 className="ids-heading-4">Importera koder från fil</h4></label></a>
                    <br></br>
                    <input type='file' className='form-control' onChange={handleFile} required ref={inputFile}></input>
                    {excelFileError &&
                        <div className='text-danger' style={{marginTop: 5 + 'px'}}>{excelFileError}</div>}
                    <IDSButton secondary={true} size="s" onClick={() => fetch()}>
                        Hämta koder
                    </IDSButton>
                </form>
            </div>

            <br></br>
            <hr></hr>

            {/* view file section */}

            {fetchedCodes &&
                <div>
                    <h4 className="ids-heading-4">Inspektera hämtade koder {getImportErrorValidationMessage()}</h4>
                    <div className='viewer'>
                        {/*      {excelData === null && <>Ingen fil vald</>}*/}
                        {excelData !== null && (
                            <div className='table-responsive'>
                                <div
                                    //className="scroll"
                                >
                                    {/*                 <table className='table'>
                                <thead>
                                <tr>
                                    <th scope='col' align={"left"}>Kod</th>
                                    <th scope='col' align={"left"}>Klartext</th>
                                    <th scope='col' align={"left"}>Beskrivning</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                </tr>
                                <Data excelData={excelData}/>
                                <tr>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                    <td scope='col'><br/></td>
                                </tr>
                                </tbody>

                                <tfoot alith="center">
                                <IDSButton secondary={true} size="m" onClick={() => props.addCodes(excelData)}>
                                    Importera koder
                                </IDSButton>

                                </tfoot>
                            </table>*/}
                                    {(excelData !== null && excelData.length !== 0 ||
                                        (codesNotInCodeSystem !== null && codesNotInCodeSystem.length > 0) ||
                                        (displaysNotInCodeSystem !== null && displaysNotInCodeSystem.length > 0)
                                    ) &&
                                    (props.belongsTo === valueset) ?
                                        <ImportConceptTable data={excelData} remove={removeRow} editDisabled={false}/> :
                                        <ConceptTableForImportCodeSystem data={excelData} setData={setExcelData}
                                                                         editDisabled={false}/>
                                    }
                                    {(displaysNotInCodeSystem !== null && displaysNotInCodeSystem.length !== 0) &&
                                        <div>
                                            <div><h4 className="ids-heading-4">
                                                {displaysNotInCodeSystem.length} kodposter har klartexter som inte finns
                                                i källan
                                            </h4>
                                                Du kan utesluta dessa kodposter från urvalet genom att
                                                korrigera filen och endast använda klartexter som tillhör
                                                koderna i kodverket.
                                            </div>

                                            <ImportConceptTable data={displaysNotInCodeSystem} isErrorTable={true}/>
                                            {/*         <div
                                                style={{display: 'flex', justifyContent: 'right', alignItems: 'right'}}>
                                                <Bin remove={removeDisplaysNotInCodeSystem} index={0}/>
                                                <ids-link activeicon="">
                                                    <a href="#import-anchor" onClick={() => {
                                                        removeDisplaysNotInCodeSystem();
                                                    }}> {"TA BORT KODPOSTER DÄR KLARTEXT EJ FINNS I KÄLLAN"}</a>
                                                </ids-link>
                                            </div>*/}
                                        </div>
                                    }
                                    {(codesNotInCodeSystem !== null && codesNotInCodeSystem.length !== 0) &&
                                        <div>

                                            <div><h4 className="ids-heading-4">
                                                {codesNotInCodeSystem.length} kodposter går inte att hitta i källan
                                            </h4>
                                                Du kan utesluta dessa kodposter från urvalet genom att korrigera filen
                                                och endast använda koder som ingår i kodverket.
                                            </div>


                                            <ImportConceptTable data={codesNotInCodeSystem} isErrorTable={true}/>
                                            {/*           <div
                                                style={{display: 'flex', justifyContent: 'right', alignItems: 'right'}}>
                                                <Bin remove={removeCodesNotInCodeSystem} index={0}/>
                                                <ids-link activeicon="">
                                                    <a href="#import-anchor" onClick={() => {
                                                        removeCodesNotInCodeSystem();
                                                    }}> {"TA BORT KODPOSTER SOM EJ FINNS I KÄLLAN"}</a>
                                                </ids-link>
                                            </div>*/}
                                        </div>
                                    }

                                    {hasImportErrorValidations() &&
                                        <div>
                                            <IDSContainer gutterless={true}>
                                                <IDSRow justify="space-between">
                                                    <IDSCol cols="auto"> </IDSCol>
                                                    <IDSCol cols="auto">
                                                        <IDSAlert
                                                            sricontitle={"Kodposterna går inte att läsa in"}
                                                            type={"error"}>
                                                            Korrigera filen och läs in den på nytt för att
                                                            kunna gå vidare
                                                        </IDSAlert>
                                                        <br></br>
                                                    </IDSCol>
                                                    <IDSCol cols="auto"> </IDSCol>
                                                </IDSRow>
                                            </IDSContainer>
                                        </div>
                                    }

                                    {excelData !== null && excelData.length != 0 && !hasImportErrorValidations()
                                        &&
                                        <div style={{display: 'flex', justifyContent: 'right', alignItems: 'right'}}>
                                            <IDSButton
                                                secondary={true} size="m" onClick={() => importConcepts(excelData)}>
                                                Importera koder
                                            </IDSButton></div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

export default ImportExcelVS;