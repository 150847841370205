import React, {useRef, useState} from "react";
import {whiteBgColor} from "../styles/TTStyles";

const Bin = (props) => {

    return (
        <div className={'container'}>
            <button type="button" onClick={() => props.remove(props.index)} style={whiteBgColor}>
                <img src={require('./delete-bin.png')}/>
            </button>
        </div>
    );
}

export default Bin;
