import React, {FC, useState, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {SubmitHandler, FormProvider, useForm, UseFormProps, UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import {yupResolver} from "@hookform/resolvers/yup";
import SaveButton from "../button/SaveButton";
import RHookFormTextField from "../../rhookform/RHookFormTextField";
import {schema, schemaNewVersion} from "../../validation/ValidationInternalMetaDataYupErrorRules";
import {ITTArtifactBase} from "./ITTArtifactBase";
import {
    removeDefaultCodeSystemFormValues
} from "../../../../model/defaultvalues/CodeSystemDefaultFormValue";
import {ACCESS_TOKEN} from "../../../../constants";
import Client from "fhir-kit-client";
import {
    baseurl,
    extensionRetireReasonSystemUrl,
    extensionVersionDescriptionCodeSystemUrl
} from "../../../../model/defaultvalues/Constant";
import {useNavigate} from "react-router-dom";
import {ICodeSystem} from "fhir-typescript-models";
import {nowDateYearMonthDay} from "../../CommonFunctions";
import {translateIdentifierToUrn} from "../../../artifact/meta/IdentifierTranslator";
import Name from "../../../artifact/meta/Name";
import {handleIfValidationNotifications} from "../../validation/external/ValidationExternalNotificationService";
import RHookFormTextFieldSpan from "../../rhookform/RHookFormTextFieldSpan";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            minHeight: '100vh',
        },
    })
);


let renderCount = 0;

interface CreateDialogFormProps {
    artifactType: string,
    closeDialog: any,
    resetDialog?: boolean,
    title: string,
    name: string,
    url: string,
    data: any;
}


export const CreateNewVersionDialogForm: FC<CreateDialogFormProps> = ({
                                                                          artifactType,
                                                                          closeDialog,
                                                                          resetDialog,
                                                                          title, name,
                                                                          url, data
                                                                      }) => {
    // @ts-ignore


    const [isRequesting, setIsRequesting] = useState(false);

    const defaultForNewVersion = {
        version: "",
        title: title,
        name: name,
        url: url,
        status: 'draft',
        resourceType: artifactType,
    }

    const methodsForCreateNewVersionForm: UseFormReturn<ITTArtifactBase, UseFormProps> = useForm<ITTArtifactBase>({
        defaultValues: defaultForNewVersion,
        resolver: yupResolver(schemaNewVersion),
    });

    if (resetDialog) {
        methodsForCreateNewVersionForm.reset(defaultForNewVersion)
    }

    const closeDialogModule = () => {
        methodsForCreateNewVersionForm.reset(defaultForNewVersion);
        closeDialog();
    }

    let previousVersion = data.version === undefined ? "" : data.version.repeat(1);

    useEffect(() => {
        methodsForCreateNewVersionForm.reset(defaultForNewVersion);
    }, [title, name, url]);


    const fhirClient = new Client({baseUrl: baseurl + "/version/" + previousVersion});

    const navigate = useNavigate();

    const submitCodeSystem: SubmitHandler<ICodeSystem> = async (newVersionFormData: ICodeSystem) => {

        if (data !== undefined && newVersionFormData.version !== undefined) {
            data.version = newVersionFormData.version;
            setIsRequesting(true);

            data.id = undefined;
            data.status = "draft";
            data.date = nowDateYearMonthDay();
            data.title = newVersionFormData.title;
            data = removeDefaultCodeSystemFormValues(data);
            translateIdentifierToUrn(data);

            //remove versionDesciption
            if (data.extension !== undefined && Array.isArray(data.extension)) {
                for (let ext of data.extension) {
                    if (ext.url !== undefined && ext.url === extensionVersionDescriptionCodeSystemUrl) {
                        ext.valueString = "";
                    }
                }
            }

            //remove retire reason
            if (data.extension !== undefined && Array.isArray(data.extension)) {
                for (let i = 0; i < data.extension.length; i++) {
                    if (data.extension[i].url === extensionRetireReasonSystemUrl) {
                        data.identifier.splice(i, 1);
                    }
                }
            }

            // @ts-ignore
            /*  data.meta.profile = ["https://example.org/fhir/StructureDefinition/profile/IneraCodeSystem"];*/

            const headers = new Headers({
                'Content-Type': 'application/json',
            })
            if (localStorage.getItem(ACCESS_TOKEN)) {
                headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
            }

            fhirClient.create({
                resourceType: artifactType,
                // @ts-ignore
                body: data,
                options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}},
            }).then(response => {
                closeDialogModule();
                methodsForCreateNewVersionForm.reset(defaultForNewVersion)
                setIsRequesting(false);
                navigate('/' + artifactType + '/' + response.id + "?edit=true", {replace: true});
                window.location.reload();
            })
                .catch(e => {
                    setIsRequesting(false);
                    console.error("FhirClient Create Error Response" + JSON.stringify(e));
                    if (e.response.status == 400) {
                        handleIfValidationNotifications("ERROR", e.response.data, methodsForCreateNewVersionForm);
                    }
                });
        }
    };

    renderCount++;

    return (
        <div style={{width: "100%"}}>
            <IDSContainer gutterless={true} style={{width: "100%"}}>
                <IDSRow justify="space-between">
                    <IDSCol>
                        <FormProvider {...methodsForCreateNewVersionForm}>
                            <form onSubmit={methodsForCreateNewVersionForm.handleSubmit(submitCodeSystem)}>
                                <IDSContainer gutterless={true} style={{width: "100%"}}>
                                    <IDSRow justify="space-between">
                                        <IDSCol cols="6">
                                            <RHookFormTextFieldSpan label="Titel (obligatorisk)" name="title"
                                                                    disabled={false}
                                                                    enableValidationWhenFieldDisabled={true}/>
                                        </IDSCol>
                                    </IDSRow>
                                    <IDSRow justify="space-between">
                                        <IDSCol cols="6">
                                            <Name artifactType={artifactType} disabled={true}
                                                  enableValidationWhenFieldDisabled={true}/>
                                        </IDSCol>
                                    </IDSRow>
                                    <IDSRow justify="space-between">
                                        <IDSCol cols="6">
                                            <RHookFormTextField methods={methodsForCreateNewVersionForm} label="Version"
                                                                name={"version"} disabled={false}/>
                                        </IDSCol>
                                    </IDSRow>
                                </IDSContainer>
                                <SaveButton isRequesting={isRequesting}/>
                            </form>
                            {/*   <DevTool control={methods.control}/>  set up the dev tool */}
                        </FormProvider>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default CreateNewVersionDialogForm;