import React, {useState} from "react";
import {Tabs, Tab, Grid} from "@material-ui/core";

import {
    useGmailTabsStyles,
    useGmailTabItemStyles,
} from '@mui-treasury/styles/tabs';
import {a11yProps, TabPanel} from "../../../../common/tab/TabCommons";
import TargetTab from "./TargetTab";
import SourceTabs from "./SourceTab";


const indicatorColors = ['#40775e', '#40775e'];

let renderCount = 0;

const CMDefineGroupLeftPanelTabs = (props) => {

    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[0]});
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const [tabList, setTabList] = useState([]);
    const [fistEmptyTab, setFistEmptyTab] = useState(true);

    renderCount++;

    const getTabPenel = (tabkey) => {

        if (tabkey === 0) {
            return (
                <SourceTabs
                    initTabSource={props.initTabSource}
                    setInitTabSource={props.setInitTabSource}
                    sourceTableData={props.sourceTableData}
                    setSourceTableData={props.setSourceTableData}
                    />
            );
        } else {
            return (<TargetTab
                initTabTarget={props.initTabTarget}
                setInitTabTarget={props.setInitTabTarget}
                targetTableData={props.targetTableData}
                sourceTableData={props.sourceTableData}
                setTargetTableData={props.setTargetTableData}
                setSourceTableData={props.setSourceTableData}
                setHoveredTable={props.setHoveredTable}
                hoveredTable={props.hoveredTable}
                lastTarget={props.lastTarget}
                setLastTarget={props.setLastTarget}
                lastTableSourceRowDroppedOn={props.lastTableSourceRowDroppedOn}
                draggingRow={props.draggingRow}
                setDraggingRow={props.setDraggingRow}
                targetRowSelection={props.targetRowSelection}
                setTargetRowSelection={props.setTargetRowSelection}
                targetRowSelectionData={props.targetRowSelectionData}
                setTargetRowSelectionData={props.setTargetRowSelectionData}
                sourceRowSelections={props.sourceRowSelections}
                setSourceRowSelections={props.setSourceRowSelections}/>);
        }
    };


    const loadTabsStep1 = () => {
            initLeftPanelTabHeads();
            return loadLeftPanelTabs();
    }

    const initLeftPanelTabHeads = () => {
        if (fistEmptyTab) {
            var array = [
                {
                    key: 0,
                    id: 0,
                    title: 'Källa',
                    version: '',
                    system: undefined,
                    loadSelectedCodeSystemForValueSet: false
                },
                {
                    key: 1,
                    id: 1,
                    title: 'Mål',
                    version: '',
                    system: undefined,
                    loadSelectedCodeSystemForValueSet: false
                }
            ]
            setTabList(array);
            setFistEmptyTab(false);
        }
    }


    const loadLeftPanelTabs = () => {
        return (

            <div>
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                        <Tabs
                            classes={tabsStyles}
                            value={props.tabValue}
                            onChange={props.handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                children: <div className={`MuiIndicator-${props.tabValue}`}/>,
                            }}
                        >
                            {tabList.map(tab => (
                                <Tab
                                    key={tab.key.toString()}
                                    value={tab.id}
                                    label={
                                        <div className={'MuiTabItem-labelGroup'}>
                                            <div className={'MuiTabItem-label'}>
                                                <strong>{tab.title}</strong>
                                                {/*<span className={'MuiTabItem-tag'}>Codesystem</span>*/}
                                            </div>
                                        </div>}
                                    /* className="mytab"*/
                                    classes={tabItem2Styles}
                                    {...a11yProps(tab.key.toString())}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                </Grid>


                <Grid container alignItems="flex-start" justifyContent="flex-start">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {/*  TabPanel {JSON.stringify(tabValue)}*/}
                        {tabList.map(tab => (
                            <div>
                                {/*  Tablist  {tab.key.toString()}, ID: {tab.id.toString()}*/}
                                <TabPanel value={props.tabValue} index={tab.key} key={tab.key.toString() + "TabPanel"}>
                                    {getTabPenel(tab.key)}
                                </TabPanel>
                            </div>
                        ))}

                    </Grid>
                </Grid>


            </div>
        );
    };


    return (
        <div>{loadTabsStep1()}</div>
    );
};

export default CMDefineGroupLeftPanelTabs;
