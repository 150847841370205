import React, {useState} from 'react';

import {ConceptMapTableRowObj} from '../model/ConceptMapTableRowObj';
import {getRenderCountText} from '../../../common/RenderCount';
import {SchemaOf, string, object, array} from 'yup';
import {
    IDSCol, IDSContainer, IDSRow
} from "@inera/ids-react";
import {codesystem, conceptmap, valueset} from "../../../../model/defaultvalues/Constant";
import Client from "fhir-kit-client";
import {fhirUrl} from "../../../../model/defaultvalues/Constant";
import {CodeSystem} from "fhir-typescript-models";

import CMDefineGroupRightPanel from "./panel/CMDefineGroupRightPanel";
import {MRT_Row} from "material-react-table";
import {RowSelectionState} from "@tanstack/react-table";
import CMDefineGroupLeftPanelTabs from "./panel/CMDefineGroupLeftPanelTabs";
import {translateCodeSystemToTargetTableData} from "./table/CMTableTargetUtil";


let renderCount = 0;


const GroupBase = (props: any) => {

    renderCount++;

    const artifactTypeChoice = [codesystem, valueset];
    const [searchCol, setSearchCol] = useState(0);


    const [disableTargetTabChoice, setDisableTargetTabChoice] = useState(props.disableTargetTabChoice === undefined ? true : props.disableTargetTabChoice);
    const fhirClient = new Client({baseUrl: fhirUrl});
    const [lastTarget, setLastTarget] = useState<string | null>(null);
    const [initTabSource, setInitTabSource] = useState(props.initTabSource === undefined ? true : props.initTabSource);
    const [initTabTarget, setInitTabTarget] = useState(props.initTabTarget === undefined ? false : props.initTabTarget);

    const [hoveredTable, setHoveredTable] = useState<string | null>(null);
    const [lastTableSourceRowDroppedOn, setLastTableSourceRowDroppedOn] = useState<number | undefined>(undefined);
    const [draggingRow, setDraggingRow] = useState<MRT_Row<ConceptMapTableRowObj> | null>(null);
    const [targetRowSelection, setTargetRowSelection] = useState<RowSelectionState>({});
    const [targetRowSelectionData, setTargetRowSelectionData] = useState<ConceptMapTableRowObj | null>(null);
    const [sourceRowSelections, setSourceRowSelections] = useState<RowSelectionState>({});
    const [tabValue, setTabValue] = useState(props.tabValue === undefined ? 0 : props.tabValue);

    const cleanTargetRowSelection = () => {
        setTargetRowSelection({});
    };

    const handleTabChange = (event: any, value: number) => {
        if (!disableTargetTabChoice
            /*    || event === undefined*/
        ) {
            setTabValue(value);
            setSearchCol(value);
        }
    };

    const getCodeSystemForArtifact = (id: string, version: string, resourceType: string, tabKey: number) => {
        if (id) {
            fhirClient.vread({resourceType: resourceType, id: id, version: version}).then((data) => {
                if (data) {
                    loadData(data, tabKey);
                }
            });
        }
    };

    const loadData = (data: any, tabKey: number) => {
        if (tabKey === 0) {
            setDisableTargetTabChoice(false);
            // @ts-ignore
            loadSourceTableDataWithSourceCodeSystem(data);
        } else {
            loadTargetTableData(data);
        }
    }

    const loadSourceTableDataWithSourceCodeSystem = (data: CodeSystem) => {
        props.setSourceUrl(data.url);

        var array = [];
        if (data.concept !== undefined) {
            for (let i = 0; i < data.concept.length; i++) {
                var concept = data.concept[i];
                array.push({code: concept.code, display: concept.display});
            }
            // @ts-ignore
            props.setSourceTableData(array);
            handleTabChange(undefined, 1);
        } else {
            // @ts-ignore
            loadDataNoConceptCodesErrorMessage(data.title);
        }

    }

    const loadDataNoConceptCodesErrorMessage = (title: string) => {
        alert("Valt kodsystem med titel: " + title + " ,har inga koder; lägg till koder i det valda kodsystemet eller välj ett annat kodsystem");
    }

    const loadTargetTableData = (data: any) => {
        var array = translateCodeSystemToTargetTableData(data, props.setTargetUrl);
        if (array.length == 0) {
            loadDataNoConceptCodesErrorMessage(data.title);
        } else {
            // @ts-ignore
            props.setTargetTableData(array);
        }
    }


    return (
        <div className={'container'}>
            {getRenderCountText(renderCount)}
            <br/>
            <br/>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="5">

                        <CMDefineGroupLeftPanelTabs
                            setSearchCol={setSearchCol}
                            initTabSource={initTabSource}
                            setInitTabSource={setInitTabSource}
                            initTabTarget={initTabTarget}
                            setInitTabTarget={setInitTabTarget}
                            targetTableAvailable={true}
                            disableTargetTabChoice={disableTargetTabChoice}
                            initUpdate={props.initUpdate}
                            artifactArray={props.artifactArray}
                            artifactType={valueset}
                            addConcept={props.addConcept}
                            removeConceptToValueSet={props.removeConceptToValueSet}
                            removeCodeSystemFromValueSet={props.removeCodeSystemFromValueSet}
                            sourceTableData={props.sourceTableData}
                            setSourceTableData={props.setSourceTableData}
                            targetTableData={props.targetTableData}
                            setTargetTableData={props.setTargetTableData}
                            setHoveredTable={setHoveredTable}
                            hoveredTable={hoveredTable}
                            lastTarget={lastTarget}
                            setLastTarget={setLastTarget}
                            lastTableSourceRowDroppedOn={lastTableSourceRowDroppedOn}
                            draggingRow={draggingRow}
                            setDraggingRow={setDraggingRow}
                            targetRowSelection={targetRowSelection}
                            setTargetRowSelection={setTargetRowSelection}
                            targetRowSelectionData={targetRowSelectionData}
                            setTargetRowSelectionData={setTargetRowSelectionData}
                            sourceRowSelections={sourceRowSelections}
                            setSourceRowSelections={setSourceRowSelections}
                            tabValue={tabValue}
                            setTabValue={setTabValue}
                            handleTabChange={handleTabChange}
                        />


                    </IDSCol>
                    <IDSCol cols="7">
                        <CMDefineGroupRightPanel
                            initTabSource={initTabSource}
                            initTabTarget={initTabTarget}
                            setInitTabTarget={setInitTabTarget}
                            addConcept={props.addConcept}
                            removeConceptToValueSet={props.removeConceptToValueSet}
                            getCodeSystemForArtifact={getCodeSystemForArtifact}
                            artifactTypeChoice={artifactTypeChoice}
                            searchCol={searchCol}
                            sourceTableData={props.sourceTableData}
                            setSourceTableData={props.setSourceTableData}
                            setLastTarget={setLastTarget}
                            hoveredTable={hoveredTable}
                            setHoveredTable={setHoveredTable}
                            lastTableSourceRowDroppedOn={lastTableSourceRowDroppedOn}
                            setLastTableSourceRowDroppedOn={setLastTableSourceRowDroppedOn}
                            draggingRow={draggingRow}
                            cleanTargetRowSelection={cleanTargetRowSelection}
                            targetRowSelectionData={targetRowSelectionData}
                            setTargetRowSelectionData={setTargetRowSelectionData}
                            sourceRowSelections={sourceRowSelections}
                            setSourceRowSelections={setSourceRowSelections}
                            targetTableData={props.targetTableData}
                            sourceTableInstanceRef={props.sourceTableInstanceRef}
                            methods={props.methods}
                        />
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default GroupBase;
