import React from 'react';
import {REDIRECT_API_BASE_URL, USER} from "../../../constants";
import {getArtifactSwedishLabel, replaceSwedishCharacters} from "../../../components/common/CommonFunctions";
import {sectorOptions} from "../../../services/SectorUtil";

export const handleNameChange = (nameWithoutSwedishCharacters: string, methods: any, artifactType: string) => {
    // @ts-ignore
    let urlWithUpdatedName = REDIRECT_API_BASE_URL + getSectorURI() + getArtifactURI(artifactType) + getURI(nameWithoutSwedishCharacters);
    methods.setValue("url", urlWithUpdatedName);
};

const getSectorURI = () => {
    // @ts-ignore
    let sectorCode: any = JSON.parse(localStorage.getItem(USER)).selectedSectorCode;
    let sectorName = undefined;
    for(let sector of sectorOptions){
        if(sector.value === sectorCode){
            sectorName = sector.label;
            break;
        }
    }
    // @ts-ignore
    let sectorNameWithoutWhiteSpace = sectorName.replaceAll(" ", "-")
    return getURI(sectorNameWithoutWhiteSpace);
};



const getArtifactURI = (artifactType: string) => {
    // @ts-ignore
    return "/" + getArtifactSwedishLabel(artifactType).toLowerCase();
};


const getURI = (s: string) => {
    if (s !== undefined && s !== "") {
        return  "/" + adaptForURI(s).toLowerCase();
    } else {
        return  "";
    }
};

const adaptForURI = (s: string) => {
    let trimmed = s.replaceAll(" ", "");
    let noSwedishCharacters =  replaceSwedishCharacters(trimmed);
    return encodeURIComponent(noSwedishCharacters)
};

