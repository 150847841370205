import {useFieldArray, useFormContext} from "react-hook-form";
import {IDSButton, IDSCol, IDSContainer, IDSLink, IDSRow, IDSDialog} from "@inera/ids-react";
import '@inera/ids-core/components/expandable/register';
import {getRenderCountText} from './../../common/RenderCount';
import React, {useEffect, useRef, useState} from "react";
import {string} from "yup";
import '@inera/ids-core/components/expandable/register';
import ConceptTable from "./table/ConceptTable";
import {concept} from "../../../model/defaultvalues/CodeSystemDefaultFormValue";
import ImportFromExcelDialog from "../../../services/excel/ImportFromExcelDialog";
import {downloadExcel} from "../../../services/excel/ExportExcel";
import {appendConcepts} from "./ConceptRHookFormUtil";
import {
    SNOMED_CT_ID,
    swedishICD10_83_name,
    swedishICD10_miniexample,
    swedishICD10_name,
} from "../../../model/defaultvalues/Constant";
import ConceptSNOMED from "./table/ConceptSNOMED";
import ConcepICD10 from "./table/ConcepICD10";
import {isLocked} from "../../../user/PermissionUtil";
import {conceptList} from "../../../model/defaultvalues/CodeSystemDefaultFormValue";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {CONTENT} from "../../common/page/TabName";
import {collectValidationsNotInContentTab} from "./ConceptUtil";


type FormValues = {
    concept: {
        code: string;
        display: string;
        definition: string;
    }[];
};

let renderCount = 0;


export default function Concept(props?: any) {
    const methods = useFormContext();
    const isSwedishSnomed: boolean = methods.getValues("id") === SNOMED_CT_ID;
    const isSwedishICD10: boolean =
        (methods.getValues("name") === swedishICD10_name) ||
        (methods.getValues("name") === swedishICD10_83_name) ||
        (methods.getValues("name") === swedishICD10_miniexample);
    const [conceptHierarchyAsString, setConceptHierarchyAsString] = useState<string>("concept");

    const [showImportDialog, setShowImportDialog] = useState(false);
    const importButtonRef = useRef("export-button-codesystem-concepts");


    const importExcel = () => {
        setShowImportDialog(true);
        // @ts-ignore
        importButtonRef.current.click();
    };

    const {
        formState: {errors},
        control
    } = useFormContext<FormValues>();


    const {
        fields, append,
        remove, update,
        swap, move, replace
    } = useFieldArray({
        // @ts-ignore
        name: conceptHierarchyAsString,
        control,
        rules: {
            required: "Please append at least 1 item"
        }
    });

    const appendNewRow = () => {
        // @ts-ignore
        append(concept);
    }

    const addCodes = (codes: []) => {
        // @ts-ignore
        appendConcepts(codes, fields, append, update)
        setShowImportDialog(false);
    }

    const replaceCodes = (concepts: ICodeSystemConcept[]) => {
        remove();
        removeConceptFromValidationLists();

        if (concepts != undefined && Array.isArray(concepts) && concepts.length != 0) {
            // @ts-ignore
            appendConcepts(concepts, fields, append, update)
        } else {
            // @ts-ignore
            appendConcepts(conceptList, fields, append, update)
        }
        setShowImportDialog(false);
    }

    const removeConceptFromValidationLists = () => {

        // @ts-ignore
        let newErrorList: [] = collectValidationsNotInContentTab(props.errorMessageList);
        // @ts-ignore
        let newWarnList: [] = collectValidationsNotInContentTab(props.warnMessageList);
        // @ts-ignore
        let newInfoList: [] = collectValidationsNotInContentTab(props.infoMessageList);

        props.setErrorMessageList(newErrorList);
        props.setWarnMessageList(newWarnList);
        props.setInfoMessageList(newInfoList);
    }

    const enableEditing = () => {
        if (!props.editDisabled && showImportDialog) {
            return false;
        } else if (props.editDisabled) {
            return false;
        } else if (!props.editDisabled) {
            return true;
        }
    }


    const ExportLink = () => {
        if (fields.length > 0 && props.editDisabled) {
// @ts-ignore
            var exportObjects = []
            for (let item of fields) {
                var b = {
                    kod: item.code,
                    klartext: item.display,
                    beskrivning: item.definition
                };
                exportObjects.push(b);
            }

            return (
                <div>
                    {
                        (
                            (methods.getValues("concept") !== undefined) &&
                            (Array.isArray(methods.getValues("concept"))) &&
                            (methods.getValues("concept").length != 0)
                        ) &&

                        <IDSButton size='m' secondary={true} onClick={() => {
                            // @ts-ignore
                            downloadExcel(exportObjects, methods.getValues("name") + "-" + methods.getValues("version"));
                        }}>
                            EXPORTERA KODER
                        </IDSButton>
                    }
                </div>
            );
        }
    };

    renderCount++;

    return (
        <div>
            <br/>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="9">
                        <ImportFromExcelDialog
                            artifactType={props.artifactType}
                            importButtonRef={importButtonRef}
                            showImportDialog={showImportDialog}
                            setShowImportDialog={setShowImportDialog}
                            addCodes={addCodes}
                            replaceCodes={replaceCodes}
                            conceptsInCodeSystem={fields}
                        />
                    </IDSCol>
                    <IDSCol cols="3">
                        {
                            (!props.editDisabled && (methods.getValues("status") === "draft")) ?
                                <IDSButton size='m' secondary={true} onClick={() => {
                                    importExcel();
                                }}>
                                    IMPORTERA KODER FRÅN FIL
                                </IDSButton> :

                                ExportLink()

                        }

                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="12"><br/></IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        {(isSwedishSnomed == false && isSwedishICD10 == false) &&
                            <ConceptTable
                                data={fields}
                                update={update}
                                remove={remove}
                                move={move}
                                swap={swap}
                                methods={methods}
                                append={append}
                                appendNewRow={appendNewRow}
                                conceptHierarchyAsString={conceptHierarchyAsString}
                                setConceptHierarchyAsString={setConceptHierarchyAsString}
                                editDisabled={isLocked(props.editDisabled, methods)}
                                errorMessageList={props.errorMessageList}
                                handleErrorMessageList={props.handleErrorMessageList}
                                warnMessageList={props.warnMessageList}
                                handleWarnMessageList={props.handleWarnMessageList}
                                infoMessageList={props.infoMessageList}
                                handleInfoMessageList={props.handleInfoMessageList}
                                showImportDialog={showImportDialog}
                            />

                        }
                        {(isSwedishSnomed == true) &&
                            <ConceptSNOMED
                                data={undefined}
                                update={update}
                                remove={remove}
                                move={move}
                                swap={swap}
                                methods={methods}
                                append={append}
                                appendNewRow={appendNewRow}
                                conceptHierarchyAsString={conceptHierarchyAsString}
                                setConceptHierarchyAsString={setConceptHierarchyAsString}
                                editDisabled={props.editDisabled}
                                errorMessageList={props.errorMessageList}
                                handleErrorMessageList={props.handleErrorMessageList}
                                showImportDialog={showImportDialog}
                            />
                        }
                        {(isSwedishICD10 == true) &&
                            <ConcepICD10
                                data={undefined}
                                url={methods.getValues("url")}
                                version={methods.getValues("version")}
                                update={update}
                                remove={remove}
                                move={move}
                                swap={swap}
                                replace={replace}
                                methods={methods}
                                append={append}
                                appendNewRow={appendNewRow}
                                conceptHierarchyAsString={conceptHierarchyAsString}
                                setConceptHierarchyAsString={setConceptHierarchyAsString}
                                editDisabled={props.editDisabled}
                                errorMessageList={props.errorMessageList}
                                handleErrorMessageList={props.handleErrorMessageList}
                                showImportDialog={showImportDialog}
                            />
                        }
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>

    );
}
