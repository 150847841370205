import React from 'react';

import {valueset} from "../../../model/defaultvalues/Constant";
import VSDefineDataCodeSystemTabsForUpdate from "./update/VSDefineDataCodeSystemTabsForUpdate";;


let renderCount = 0;


const VSDefineDataCodeSystem = (props: any) => {

    renderCount++;

    const handleChange = (e: any, concept: any, system: string, version: string, index: number, tabKey: number, id:string) => {
        if (e.target.checked) {
            concept.selected = e.target.checked;
            concept.codeSystemConceptIndex = index;
            props.addConcept(concept, system, version, e, tabKey, id);
        } else {
            props.removeConceptToValueSet(concept, system, version);
        }
    };

    return (

        <div>

                <VSDefineDataCodeSystemTabsForUpdate artifactArray={props.artifactArray}
                                                     conceptsToAdd={props.conceptsToAdd}
                                                     setConceptsToAdd={props.setConceptsToAdd}
                                                     artifactType={valueset}
                                                     addConcept={props.addConcept}
                                                     removeConceptToValueSet={props.removeConceptToValueSet}
                                                     removeCodeSystemFromValueSet={props.removeCodeSystemFromValueSet}
                                                     handleChange={handleChange}
                                                     codeSystemsWithAllCodesSelectableCodes={props.codeSystemsWithAllCodesSelectableCodes}
                                                     setCodeSystemsWithAllCodesSelectableCodes={props.setCodeSystemsWithAllCodesSelectableCodes}/>

        </div>
    );
};

export default VSDefineDataCodeSystem;
