import React, {useCallback, useEffect, useState} from 'react';

import GroupBase from "./GroupBase";
import {useFormContext} from "react-hook-form";
import {ConceptMapTableRowObj} from "../model/ConceptMapTableRowObj";
import {codesystem} from "../../../../model/defaultvalues/Constant";
import {getLatestRelatedArtifactsViaUrl} from "../common/ConceptMapUtil";


let renderCount = 0;


const GroupUpdate = (props: any) => {

        const methods = useFormContext();
        const [targetTableData, setTargetTableData] = useState<ConceptMapTableRowObj[]>([]);

        const resetAsyncForm = useCallback(async () => {
            await getLatestRelatedArtifactsViaUrl(methods.getValues("group[0].target"),codesystem, setTargetTableData);
        }, [methods.reset]);

        useEffect(() => {
            resetAsyncForm()
        }, [resetAsyncForm])

        renderCount++;

        return (
            <GroupBase
                initUpdate={true}
                initTabSource={false}
                initTabTarget={true}
                artifactArray={props.artifactArray}
                addConcept={props.addConcept}
                removeConceptToValueSet={props.removeConceptToValueSet}
                removeCodeSystemFromValueSet={props.removeCodeSystemFromValueSet}
                sourceTableData={props.sourceTableData}
                setSourceTableData={props.setSourceTableData}
                setSourceUrl={props.setSourceUrl}
                setTargetUrl={props.setTargetUrl}
                targetTableData={targetTableData}
                disableTargetTabChoice={false}
             /*   tabkey={1}*/
                sourceTableInstanceRef={props.sourceTableInstanceRef}
                tabValue={1}
                methods={props.methods}
            />
        );
    }
;

export default GroupUpdate;
