import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {
    codeDuplicateCaseSensitiveError,
    codeMinError,
    codeSpecialCharError,
    codeWhiteSpaceInfo
} from "../meta/MetaWarnInfoConstant";
import {contentTabName} from "../../common/page/TabName";
import {
    getFieldNameInArrayHierarchy,
    hasRowCellsAllEmptyValues
} from "../../common/rhookform/RHookFormArrayHierarchyFunction";
import {codeHasDuplicateValidation} from "./CodeMultiLevelValidation";


let renderCount = 0;
const arrayname = "concept"


export const errorValidateCode = (value: string, prevValue: string, rowindex: string, errorMessageList: [], handleErrorMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {
    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        handleEmptyRowErrorMessages(rowindex, codeWhiteSpaceInfo, handleErrorMessageList, methods);
        handleEmptyRowErrorMessages(rowindex, codeMinError, handleErrorMessageList, methods);
        /* handleEmptyRowErrorMessages(rowindex, codeSwedishCharError, handleErrorMessageList, methods);*/
        handleEmptyRowErrorMessages(rowindex, codeSpecialCharError, handleErrorMessageList, methods);
        handleEmptyRowErrorMessages(rowindex, codeDuplicateCaseSensitiveError, handleErrorMessageList, methods);
    } else {

        let validationResults = []

        let minValidationResult = codeMinErrorValidation(value, rowindex, errorMessageList, handleErrorMessageList, methods);
        let minValidation = {"label": codeMinError, "message": minValidationResult}
        validationResults.push(minValidation);

        /*      let swedishCharsValidationResult = codeHasSwedishCharactersValidation(value, rowindex, errorMessageList, handleErrorMessageList, methods);
               let swedishCharsValidation = {"label": codeSwedishCharError, "message": swedishCharsValidationResult}
               validationResults.push(swedishCharsValidation);
       */

        if (minValidationResult == false
            /*    &&  swedishCharsValidationResult == false &&*/) {
            let specialCharactersValidationResult = codeHasSpecialCharactersValidation(value, rowindex, errorMessageList, handleErrorMessageList, methods);
            let specialCharactersValidation = {
                "label": codeSpecialCharError,
                "message": specialCharactersValidationResult
            }
            validationResults.push(specialCharactersValidation);
        }

        let codeDuplicateValidationResult = codeHasDuplicateValidation(value, prevValue, rowindex, true, handleErrorMessageList, methods);
        let codeDuplicateValidation = {
            "label": codeDuplicateCaseSensitiveError,
            "message": codeDuplicateValidationResult
        }
        validationResults.push(codeDuplicateValidation);


        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }
        return finalErrorValidationResultHandling(validationMessage, rowindex, methods);

    }
}

const finalErrorValidationResultHandling = (validationMessage: string, rowindex: string, methods: any) => {
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (validationMessage !== "") {
        rowConceptToValidate.codeError = validationMessage;
        return validationMessage;
    } else {
        rowConceptToValidate.codeError = undefined;
        return false;
    }
}

const handleEmptyRowErrorMessages = (rowindex: string, warningType: string, handleWarningMessageList: any, methods: any) => {
    handleWarningMessageList(null, warningType, rowindex);
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (rowConceptToValidate != undefined) {
        rowConceptToValidate.codeError = undefined;
      //  rowConceptToValidate.codeWarn = undefined;
    }
}

export const codeMinErrorValidationOnly = (value: string, rowindex: string, errorMessageList: [], handleErrorMessageList: any, methods: any) => {
    let minValidationResult = codeMinErrorValidation(value, rowindex, errorMessageList, handleErrorMessageList, methods);
    return finalErrorValidationResultHandling(minValidationResult, rowindex, methods);
}

const codeMinErrorValidation = (value: string, rowindex: string, errorMessageList: [], handleErrorMessageList: any, methods: any) => {
    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
        let message = "Kod ofullständig. Ange minst 1 tecken."
        const name = getFieldNameInArrayHierarchy(rowindex, arrayname, "code");
        let validationResult = value !== undefined && ((typeof value == "string" && value.trim().length == 0 && !hasRowCellsAllEmptyValues(name, methods)) && message);
        let validationmessage = collectValidationMessage(message, codeMinError, validationResult, handleErrorMessageList, contentTabName, rowindex);
        return validationmessage;
    } else {
        return false;
    }
}

//(UNDER UTREDNING - tas med referensgruppen, se TT_00148)
const codeHasSpecialCharactersValidation = (value: string, rowindex: string, errorMessageList: [], handleErrorMessageList: any, methods: any) => {
    /*    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
            let message = "Kod får ej innehålla specialtecken. Ta bort alla specialtecken."
            let validationResult = value !== undefined && (hasSpecialCharacters(value) && message);
            let validationmessage = collectValidationMessage(message, codeSpecialCharError, validationResult, handleErrorMessageList, errorMessageList, rowindex);
            return validationmessage;
        } else {
            return false;
        }*/
    return false;
}


