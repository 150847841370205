import React, {useCallback, useEffect, useState} from "react";
import Client from "fhir-kit-client";
import {fhirUrl} from "../../../../model/defaultvalues/Constant";
import VSTabsForCodeSystem from "../VSTabsForCodeSystem";
import VSIncludeCodeSummery from "../VSIncludeCodeSummery";
import {IDSButton} from "@inera/ids-react";
import {isCodeSystemWithoutCodesInMetaDataObject} from "../../../common/CommonFunctions";

const fhirClient = new Client({baseUrl: fhirUrl});
let renderCount = 0;

const VSDefineDataCodeSystemForUpdate = (props) => {

    const [tabList, setTabList] = useState([]);
    renderCount++;

    const getAllConceptsAvailableFromCodeSystemArtefact = async (key, system) => {
        let concept = undefined;

        if (isCodeSystemWithoutCodesInMetaDataObject(system)) {
            concept = props.conceptsToAdd[key].concept;
        } else {
            const result = await fhirClient.read({
                resourceType: "CodeSystem",
                id: props.artifactArray[key].resource.id
            });
            concept = result.concept;
        }
        return concept;
    }

    const collectAllCodesAndTabData = async () => {
        if (props.artifactArray !== undefined) {

            let artifactDataArrayForTabs = [];
            let conceptsInSummary = [];
            let selectedCodeSystems = [];
            for (let i = 0; i < props.artifactArray.length; i++) {

                const key = i;
                const id = props.artifactArray[i].resource.id;
                const title = props.artifactArray[i].resource.title;
                const version = props.artifactArray[i].resource.version;
                const system = props.artifactArray[i].resource.url;
                const status = props.artifactArray[i].resource.status;
                const concept = await getAllConceptsAvailableFromCodeSystemArtefact(key, system);


                /*   let id = getNextTabListId();*/
                await artifactDataArrayForTabs.push({
                    key: key,
                    id: key,
                    title: title,
                    version: version,
                    system: system,
                    loadSelectedCodeSystemForValueSet: true
                });

                var allCodesInCodeSystem = false;
                var selectedCodesOnly = [];
                setSelectedCodesInCodeSystem(concept, allCodesInCodeSystem, selectedCodesOnly, system);

                if (selectedCodesOnly.length != 0) {
                    conceptsInSummary.push({
                        version: version,
                        system: system,
                        concept: selectedCodesOnly
                    });
                }
                var codeSystemWithSelectedAndAllCodes = {
                    allCodesInCodeSystem: allCodesInCodeSystem,
                    concept: concept,
                    resourceType: "CodeSystem",
                    url: system,
                    id: id,
                    status: status
                }
                if (version !== undefined) {
                    codeSystemWithSelectedAndAllCodes.version = version;
                }
                await selectedCodeSystems.push(codeSystemWithSelectedAndAllCodes);
            }

            props.setCodeSystemsWithAllCodesSelectableCodes(selectedCodeSystems);

            setTabList(artifactDataArrayForTabs);

            if (conceptsInSummary.length != 0) {
                props.setConceptsToAdd(conceptsInSummary);
            }

        }
    }


    const resetAsyncForm = useCallback(async () => {
        collectAllCodesAndTabData();
    }, []);


    useEffect(() => {
        collectAllCodesAndTabData();
    }, [props.artifactArray])


    const setSelectedCodesInCodeSystem = (concept, allCodesInCodeSystem, codeSystemWithSelectedCodesOnly, system) => {

        for (let i = 0; i < props.conceptsToAdd.length; i++) {

            if (props.conceptsToAdd[i].system === system && props.conceptsToAdd[i].concept === undefined) {
                allCodesInCodeSystem = true;
            } else if (props.conceptsToAdd[i].system === system && concept !== undefined) {

                for (let j = 0; j < props.conceptsToAdd[i].concept.length; j++) {

                    var code = props.conceptsToAdd[i].concept[j].code;

                    for (let k = 0; k < concept.length; k++) {
                        if (concept[k].code === code) {
                            concept[k].selected = true;
                            codeSystemWithSelectedCodesOnly.push(concept[k]);
                        }
                    }

                }
            }
        }

    };


    const removeCodeSystem = (tabIndex) => {
        const isNan = Number.isNaN(tabIndex);
        if (isNan == false && typeof tabIndex === 'number') {
            try {
                const id = props.codeSystemsWithAllCodesSelectableCodes[tabIndex].id;
                const system = props.codeSystemsWithAllCodesSelectableCodes[tabIndex].url;
                const version = props.codeSystemsWithAllCodesSelectableCodes[tabIndex].version;
                props.codeSystemsWithAllCodesSelectableCodes.splice(tabIndex, 1); // 2nd parameter means remove one item only
                var newArray = [...props.codeSystemsWithAllCodesSelectableCodes]; // make a separate copy of the array
                props.setCodeSystemsWithAllCodesSelectableCodes(newArray);

                props.removeCodeSystemFromValueSet(tabIndex, id, system, version);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error("tabIndex: " + JSON.stringify(tabIndex) + " isNaN");
        }

    };

    const testMethod = () => {
        let localTablist = tabList;
        let localSelectedCodeSystemForValueSet = props.codeSystemsWithAllCodesSelectableCodes;
        debugger;
        console.log("testMethod");
    }

    return (
        <div>
            {/*            <IDSButton size='m' secondary={true} onClick={() => {
                testMethod();
            }}>
                TESTKNAPP
            </IDSButton>*/}
            <VSTabsForCodeSystem
                artifactArray={props.artifactArray}
                conceptsToAdd={props.conceptsToAdd}
                setConceptsToAdd={props.setConceptsToAdd}
                codeSystemsWithAllCodesSelectableCodes={props.codeSystemsWithAllCodesSelectableCodes}
                setCodeSystemsWithAllCodesSelectableCodes={props.setCodeSystemsWithAllCodesSelectableCodes}
                addConcept={props.addConcept}
                artifactType={props.artifactType}
                removeConceptToValueSet={props.removeConceptToValueSet}
                removeCodeSystemFromValueSet={removeCodeSystem}
                tabList={tabList}
                setTabList={setTabList}
                type={props.type}
                handleChange={props.handleChange}
            />

            <VSIncludeCodeSummery
                codeSystemsWithAllCodesSelectableCodes={props.codeSystemsWithAllCodesSelectableCodes}
                conceptsToAdd={props.conceptsToAdd}
                setConceptsToAdd={props.setConceptsToAdd}
                removeConceptToValueSet={props.removeConceptToValueSet}
                handleChange={props.handleChange}/>
        </div>
    );
};

export default VSDefineDataCodeSystemForUpdate;
