import React from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSButton, IDSButtonGroup, IDSCol, IDSContainer, IDSDialog, IDSLink, IDSRow} from "@inera/ids-react";

const StopEditDialog = (props) => {

    const getHeadline = () => {
        return "Ändringar har inte sparats"
    }
    return (
        <div>
            <IDSDialog overlay={true} persistent={true} dismissible="true" show={props.showDialog}
                       headline={getHeadline()}
                       onClosed={() => {
                           props.setShowDialog(false);
                       }}
            >
                <a href="javascript:void(0)" trigger="true" ref={props.dialogRef} style={{color: '#FFF'}}></a>
                <p style={{color: '#FFF'}}>..................................................................................................................................</p>

                <div style={{width: "100%"}}>
                    <IDSContainer gutterless={true} style={{width: "100%"}}>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                <div className="container">
                                    Om du har gjort redigeringar och avslutar redigeringen utan att spara kommer de ändringar du har gjort försvinna.
                                    Om du vill spara ändringar tryck på Avbryt för att komma tillbaks till redigeringsläget.
                                    Om du vill fortsätta utan att spara, klicka på sluta redigera.
                                    <br/> <br/>
                                </div>
                            </IDSCol>
                        </IDSRow>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                <div>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <IDSButtonGroup>

                                            <IDSButton  type="button" secondary={true} size="s" onClick={() => props.changeEditDisabled(true)}>
                                                SLUTA REDIGERA UTAN ATT SPARA
                                            </IDSButton>

                                            <IDSButton  type="button" secondary toggle active size="s" onClick={() => props.setShowDialog(false)}>
                                                AVBRYT
                                            </IDSButton>

                                        </IDSButtonGroup>
                                    </div>
                                </div>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                </div>
            </IDSDialog>
        </div>
    );
};
export default StopEditDialog;