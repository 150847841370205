export const appendConcepts = (concepts: [], arrayToAppendTo: [], append: any, update: any) => {
    if (hasOneEmptyRow(arrayToAppendTo)) {
        for (let i = 0; i < concepts.length; i++) {
            let concept = concepts[i];
            if (i == 0) {
                update(i, concept)
            } else {
                appendConcept(concept, arrayToAppendTo, append);
            }
        }
    } else {
        for (let concept of concepts) {
            appendConcept(concept, arrayToAppendTo, append);
        }
    }
}

const appendConcept = (concept: any, arrayToAppendTo: [], append: any) => {
    let isCodeAlreadyInCollection = (!arrayToAppendTo.find(obj => {
        // @ts-ignore
        return obj.code === concept.code
    }) === undefined);

    if (!isCodeAlreadyInCollection) {
        // @ts-ignore
        append(concept);

    }
}

const hasOneEmptyRow = (arrayToAppendTo: []) => {

    // @ts-ignore
    if (arrayToAppendTo.length == 1) {
        // @ts-ignore
        const row = arrayToAppendTo[0];
        // @ts-ignore
        let emptyCode = (row.code === undefined) || (row.code === "");
        // @ts-ignore
        let emptyDisplay = (row.display === undefined) || (row.display === "");
        // @ts-ignore
        let emptyDefinition = (row.definition === undefined) || (row.definition === "");

        if (emptyCode && emptyDisplay && emptyDefinition) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
