import {FC, useState} from "react";

interface TextExpandableProps {
    text: string;
}

const TextExpandable: FC<TextExpandableProps> = ({text}) => {
    const [showFull, setShowFull] = useState(false);
    const limit: number = 96;

    const getTextEnd = (test: any) => {
        return (test.length > limit) ? "..." : "";
    };

    return (
        <div>
            {(text === undefined || text.trim().length == 0) ? undefined :
                <p>
                    <span onClick={() => setShowFull(!showFull)}>{showFull ? text : text.slice(0, limit) + getTextEnd(text)}</span>
                </p>
            }
        </div>
    );
}

export default TextExpandable;
