import React, {useMemo} from 'react';
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_ColumnDef,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {ConceptMapTableRowObj} from "../../model/ConceptMapTableRowObj";
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsTwoColumnStyle,
} from "../../../../../styles/TTStyles";
import ImportExport from "@mui/icons-material/ImportExport";
import MoreVertIcon from "@mui/icons-material/MoreVert";


let renderCount = 0;

const CMTableTarget = (props: any) => {

    renderCount++;

    const columns = useMemo<MRT_ColumnDef<ConceptMapTableRowObj>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'targetdisplay',
                header: 'Mål',
            },
        ],
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ConceptMapTableRowObj>> & {
        columns: MRT_ColumnDef<ConceptMapTableRowObj>[];
    } = {
        columns,
        enableRowDragging: true,
        enableFullScreenToggle: false,
        onDraggingRowChange: props.setDraggingRow,
        state: props.draggingRow,
    };


    return (
        <MaterialReactTable
            {...commonTableSourceProps}
            autoResetPageIndex={false}
            columns={columns}
            data={props.targetTableData}
            enableRowOrdering
            enableSorting={true}
            getRowId={(originalRow) => `table-target-${originalRow.target}`}
            localization={MRT_Localization_SV}
            enableTopToolbar={false}
            enableSelectAll={false}

            displayColumnDefOptions={{
                'mrt-row-drag': {
                    header: '',
                    size: 10,
                },
            }}

            icons={{
                MoreVertIcon: () => <ImportExport/>,
                MoreHorizIcon: () => <MoreVertIcon/>,
            }}

            muiTableHeadRowProps={{
                sx: muiTableHeadRowPropsStyle
            }}

            muiTableProps={{
                sx: muiTablePropsTwoColumnStyle
            }}

            muiTableBodyProps={{
                sx: muiTableBodyPropsStyle
            }}


            muiTablePaperProps={{
                elevation: 0, //change the mui box shadow
                onDragEnter: () => props.setHoveredTable('table-target'),
            }}

            muiTableBodyRowProps={({isDetailPanel, row, table}) => ({

                onClick: () =>{
                    // @ts-ignore
                    props.setTargetRowSelection((prev) => ({
                        [row.id]: !prev[row.id],
                    }))
                    props.setTargetRowSelectionData(row.original);
                },


                selected: props.targetRowSelection[row.id],
                sx: {
                    outline: undefined,
                    cursor: 'pointer',
                },
            })}

            state={{rowSelection: props.targetRowSelection}}

            muiTableBodyRowDragHandleProps={({row, table}) => ({
                onDragEnd: () => {
                    if (props.hoveredTable === 'table-source') {

                        if(props.sourceTableData[props.lastTableSourceRowDroppedOn].target !== undefined && props.sourceTableData[props.lastTableSourceRowDroppedOn].target.length > 0){
                            props.sourceTableData[props.lastTableSourceRowDroppedOn].target[0].code = props.draggingRow.original.target;
                            props.sourceTableData[props.lastTableSourceRowDroppedOn].target[0].display = props.draggingRow.original.targetdisplay;
                        }else {
                            props.sourceTableData[props.lastTableSourceRowDroppedOn].target = [{
                                code: props.draggingRow.original.target,
                                display: props.draggingRow.original.targetdisplay}]
                        }

                        var array = [...props.sourceTableData]; // make a separate copy of the array
                        props.setSourceTableData(array);

                    }
                    props.setHoveredTable(null);
                    props.setDraggingRow(null);
                },
            })}

        />

    );
};

export default CMTableTarget;
