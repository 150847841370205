import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {displayDuplicateWarning, displayMinWarning} from "../meta/MetaWarnInfoConstant";
import {contentTabName, publishTabName} from "../../common/page/TabName";
import {getFieldNameInArrayHierarchy, hasRowCellsAllEmptyValues} from "../../common/rhookform/RHookFormArrayHierarchyFunction";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";


let renderCount = 0;
const arrayname = "concept"

export const warnValidateDisplay = (value: string, prevValue: string, rowindex: string, warnMessageList: [], handleWarningMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {

    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        handleEmptyRowWarningMessages(rowindex, displayMinWarning, handleWarningMessageList, methods);
        handleEmptyRowWarningMessages(rowindex, displayDuplicateWarning, handleWarningMessageList, methods);
    } else {

        let validationResults = []

        let minValidationResult = displayMinWarningValidation(value, rowindex, warnMessageList, handleWarningMessageList, methods);
        let minValidation = {"label": displayMinWarning, "message": minValidationResult}
        validationResults.push(minValidation);

        if (minValidationResult == false) {
            let specialCharactersValidationResult = displayHasDuplicateValidation(value, prevValue, rowindex, handleWarningMessageList, methods);
            let specialCharactersValidation = {
                "label": displayDuplicateWarning,
                "message": specialCharactersValidationResult
            }
            validationResults.push(specialCharactersValidation);
        }

        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }
        return finalWarningValidationResultHandling(validationMessage, rowindex, methods);

    }
}

const finalWarningValidationResultHandling = (validationMessage: string, rowindex: string, methods: any) => {
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (validationMessage !== "") {
        rowConceptToValidate.displayWarning = validationMessage;
        return validationMessage;
    } else {
        rowConceptToValidate.displayWarning = undefined;
        return false;
    }
}

const handleEmptyRowWarningMessages = (rowindex: string, warningType: string, handleWarningMessageList: any, methods: any) => {
    handleWarningMessageList(null, warningType, rowindex);
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (rowConceptToValidate != undefined) {
        rowConceptToValidate.displayWarning = undefined;
    }
}


const displayMinWarningValidation = (value: string, rowindex: string, warnMessageList: [], handleWarningMessageList: any, methods: any) => {
    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
        let message = "Klartext ofullständig. Ange minst 2 tecken."
        const name = getFieldNameInArrayHierarchy(rowindex, arrayname, "display");
        let validationResult = value !== undefined && ((typeof value == "string" && value.trim().length < 2 && !hasRowCellsAllEmptyValues(name, methods)) && message);
        let validationmessage = collectValidationMessage(message, displayMinWarning, validationResult, handleWarningMessageList, contentTabName, rowindex);
        return validationmessage;
    } else {
        return false;
    }
}

const displayHasDuplicateValidation = (value: string, prevValue: string, rowindex: string, handleValidationMessageList: any,
                                       methods: any) => {
    const concepts = methods.getValues(arrayname);
    var areEqual = false;
    if (value !== undefined) {
        for (let i = 0; i < concepts.length; i++) {
            //Only compare with rows that is not itself
            if (Number(rowindex) != i) {
                let concept = concepts[i];
                if (concept.display !== undefined) {
                    if ((prevValue !== undefined && prevValue.trim() !== "") &&
                        prevValue.toUpperCase() === concept.display.toUpperCase() && concept.displayWarning != undefined) {
                        concept.displayWarning = undefined;
                        collectValidationMessage("", displayDuplicateWarning, false, handleValidationMessageList, contentTabName, i.toString());
                        return areEqual;
                    }
                    if (value.trim() != "" && concept.display.trim() !== "" &&
                        value.toUpperCase() === concept.display.toUpperCase()) {
                        let message = "Samma klartext finns mer än en gång."
                        let validationmessage = collectValidationMessage(message, displayDuplicateWarning, message, handleValidationMessageList, contentTabName, rowindex);
                        areEqual = true;
                        return message;
                    }
                }
            }

        }
    }
    //remove validationMessage if any
    if (areEqual == false) {
        collectValidationMessage("", displayDuplicateWarning, false, handleValidationMessageList, contentTabName, rowindex);
    }
    return false
}


export const validateDisplayAfterSubmit = (concepts: ICodeSystemConcept[],  warnMessageList: [], handleWarnMessageList : any, methods: any) => {
    for (let i = 0; i < concepts.length; i++) {
        let concept = concepts[i];
        const name = getFieldNameInArrayHierarchy(i.toString(), "concept", "display");
        // @ts-ignore
        warnValidateDisplay(concept.display, undefined, i.toString(), warnMessageList, handleWarnMessageList, methods, hasRowCellsAllEmptyValues(name, methods));
    }
}