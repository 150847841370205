export const getHierarchyAsString = (index: string, arrayname: string) => {
    let indexArray: string[] = getIndexArray(index);
    return getHierarchy(indexArray, arrayname);
}

export const getFieldNameInArrayHierarchy = (index: string, arrayname: string, fieldname: string) => {
    return getHierarchyAsString(index, arrayname) + "." + fieldname;
};

export const getFieldNameInArrayHierarchyForForm = (fieldNameInArrayHierarchy: string) => {
    return  fieldNameInArrayHierarchy.replace('[','.').replace(']','.').replace('..','.');
};


export const getNameInArrayHierarchyAsDotsOnly = (name: string) => {

    const nameInArrayHierarchyAsDots = name.replace(/[\[\]']+/g, '.')
    const nameInArrayHierarchyAsDotsAndWithoutDoubleDots = nameInArrayHierarchyAsDots.replace("..", ".");
    return nameInArrayHierarchyAsDotsAndWithoutDoubleDots;
};

export const hasRowCellsAllEmptyValues = (fieldNameInArrayHierarchy: string, methods: any) => {
    const fieldValue = methods.getValues(fieldNameInArrayHierarchy);

    if (fieldValue === undefined || fieldValue === "") {

        const indexEndOfRowSpec: number = fieldNameInArrayHierarchy.lastIndexOf(".");
        const rowInArrayHierarchy = fieldNameInArrayHierarchy.substring(0, indexEndOfRowSpec);
        const rowObj = methods.getValues(rowInArrayHierarchy);

        let countColumns: number = 0;
        let countEmptyProperties: number = 0;
        for (const key in rowObj) {
            if (key !== "codeError" && key !== "codeWarn" && key !== "displayWarning" && key !== "definitionInformation") {
                countColumns++;
                if (rowObj[key] === undefined || rowObj[key].trim() === "") {
                    countEmptyProperties++;
                }
            }
        }
        const allCellsEmptyValues = countColumns == countEmptyProperties ? true : false;

        return allCellsEmptyValues;
    } else {
        return false;
    }
}

export const hasRowCellCodeEmptyValue = (fieldNameInArrayHierarchy: string, methods: any) => {
    //  debugger;
    let emptyCode = false;

    const indexEndOfRowSpec: number = fieldNameInArrayHierarchy.lastIndexOf(".");
    const rowInArrayHierarchy = fieldNameInArrayHierarchy.substring(0, indexEndOfRowSpec);
    const columnName = fieldNameInArrayHierarchy.substring(indexEndOfRowSpec + 1);
    const rowObj = methods.getValues(rowInArrayHierarchy);

    if (columnName !== "code") {
        if (rowObj["code"] === undefined || rowObj["code"] === "") {
            emptyCode = true;
        }
    }
    return emptyCode;
}

function countProperties(obj: any) {
    var count = 0;

    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            ++count;
    }

    return count;
}

const getIndexArray = (index: string) => {

    let indexArray: string[] = []
    if (index.includes(".")) {
        indexArray = index.split(".")
    } else {
        indexArray.push(index)
    }
    return indexArray;
}

const getHierarchy = (indexArray: string[], arrayname: string) => {
    let conceptHierarchy = "";
    for (let i of indexArray) {
        if (conceptHierarchy === "") {
            conceptHierarchy = conceptHierarchy.concat(arrayname + "[" + i + "]");
        } else {
            conceptHierarchy = conceptHierarchy.concat("." + arrayname + "[" + i + "]");
        }
    }
    return conceptHierarchy;
}