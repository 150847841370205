import {IRHookSelectOption} from "../../common/rhookform/IRHookSelectOption";
import React from "react";
import {LAST_UPDATED} from "../../../constants";

export const publicationStatusOptions: IRHookSelectOption[] = [
    {label: "Utkast", value: "draft"},
    {label: "Aktiv", value: "active"},
    {label: "Avställd", value: "retired"}
];

export const sortOptions: IRHookSelectOption[] = [
    {label: "Titel", value: "title"},
    {label: "Förvaltare", value: "publisher"},
    {label: "Version", value: "version"},
    {label: "Senast uppdaterad", value: LAST_UPDATED},
];
